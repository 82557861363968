import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData){
            this.search.campanha = { 
            id_campanha: 1,
            descricao: 'Campanha Regional da Gripe 2025' 
            }
            this.table.items = [
                {
                    'cpf': '000.000.000-00',
                    'nome': 'João da Silva Souza',
                    'matricula': null,
                    'setor': null,
                    'data_aplicacao': '2025-01-01',
                    'usuario_aplicador': 'Vacinador SESI',
                    'numero_lote': '01',
                    'fabricante': 'Fabricante 01',
                    'validade_lote': '2026-01-01',
                    'nome_fantasia': 'Empresa Tutorial',
                    'razao_social': 'Razão Social Empresa Tutorial',
                    'cnpj': '00.000.000/0000-00',
                    'vacina': 'Quadrivalente',
                }
            ]
            this.loading.campaign = false

            this.paginationData.totalLines = 1
            this.paginationData.fromLine = 1
            this.paginationData.toLine = 1

            this.loadingData = false
        }
    }
  };