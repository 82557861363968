<template>
  <div class="content-wrapper">

    <SucessoModal
        item-identifier="Negócios"
        :modal-show="modal.modalSuccess.modalShow"
        :type-modal="modal.modalSuccess.typeModal"
        :case-modal="modal.modalSuccess.caseModal"
        @confirmed="redirectToList"
    />

    <ConfirmModal
        item-identifier="Negócios"
        :modal-show="modal.modalConfirm.modalShow"
        :type-modal="modal.modalConfirm.typeModal"
        @confirmed="redirectToList"
        @canceled="closeModalConfirm"
    />
    <PageHeader
        screen-name="Editar Negócio"
        :link-items="linkItems"
    />
    <main class="card p-2">
      <validation-observer ref="business">
        <b-form>
          <div class="pt-1 container_edit_name_group">
            <b-row>
              <b-col
                  lg="12"
                  md="12"
                  sm="12"
              >

                <b-form-group
                    label="Nome do Negócio *"
                    label-for="name_group"
                    label-class="font_size_label"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Nome do Negócio"
                      rules="required|min:3|max:50"
                  >
                    <b-form-input
                        id="name_business"
                        v-model="form.name_business"
                        class="form-control"
                        maxlength="50"
                        minlength="3"
                        placeholder="Nome do Negócio"
                    />
                    <small
                        v-if="errors[0]"
                        class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

        </b-form>
      </validation-observer>
      <div v-if="groupBusiness">
        <CompaniesLinked
            :companiesProp="groupBusiness.grupo_negocio_empresa"
            :edit="true"
            @companiesLinked="setCompaniesLinked"
            @companiesNotLinked="setCompaniesNotLinked"
        />
      </div>
      <b-row>
        <b-col class="mt-2">
          <b-button
              class="ml-auto"
              variant="primary-button"
              @click="editBusiness()"
          >
            <span class="align-middle">Salvar Alterações</span>
          </b-button>
          <b-button
              class="btn-outline-danger ml-2"
              variant="btn-outline-danger"
              @click="cancelBusiness()"
          >
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BContainer,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import CompaniesLinked from '@/views/custom-pages/GrupoEmpresa/Components/CompaniesLinked.vue'
import {getCompanyBusiness, getCompanyBusinessId, getIdCompanyGroup} from '@/@core/utils/store/getStore/index'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import {setCompanyBusinessId} from "@core/utils/store/setStore";

export default {
  title: 'Editar grupo empresa',

  components: {
    ConfirmModal,
    SucessoModal,
    CompaniesLinked,
    BFormCheckbox,
    BContainer,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      linkItems: [
        {
          name: 'Listagem do grupo',
          routeName: 'view-company-group',
        },
        {
          name: 'Editar negócio',
          active: true,
        },
      ],
      form: {
        name_business: '',
      },
      companies: [],
      companiesLinked: [],
      companiesNotLinked: [],
      modal: {
        modalSuccess: {
          modalShow: false,
          typeModal: 'success',
          caseModal: 'criacao',
        },
        modalConfirm: {
          modalShow: false,
          typeModal: 'danger',
        },
      },
      idGroupBusiness: null,
      groupBusiness: null,
      idGroup: null,
    }
  },
  async mounted() {
    this.companies = getCompanyBusiness()
    this.idGroupBusiness = getCompanyBusinessId()
    this.idGroup = getIdCompanyGroup()
    const hasIdEntityRouteParamIdBusiness = this.$helpers.verifyIfHasIdEntityRouteParam(getCompanyBusinessId(), this.$route.params.idGroupBusiness)
    if(hasIdEntityRouteParamIdBusiness) {
      setCompanyBusinessId(this.$route.params.idGroupBusiness)
      this.idGroupBusiness = getCompanyBusinessId()
    }
    await this.loadGroupBusiness()
  },
  methods: {
    setCompaniesLinked(companiesLinked) {
      this.companiesLinked = companiesLinked.map(company => company.id_empresa)
    },

    setCompaniesNotLinked(companiesNotLinked) {
      this.companiesNotLinked = companiesNotLinked.map(company => company.id_empresa);
    },

    async loadGroupBusiness() {
      const params = {
        id_grupo_negocio: this.idGroupBusiness,
      }
      await this.$http.get(this.$api.getAndPutAndDeleteGrupoNegocio(this.idGroupBusiness), {params: params}).then((resp) => {
        this.groupBusiness = resp.data
        this.form.name_business = resp.data.descricao
      })
    },

    async editBusiness() {
      const isValid = await this.$refs.business.validate()
      this.formatParams()
      const params = this.formatParams()
      if(isValid) {
        this.$http.put(this.$api.getAndPutAndDeleteGrupoNegocio(this.idGroupBusiness), params).then(() => {
          this.modal.modalSuccess.modalShow = true
        }).catch(({ response }) => {
          const { data, status } = response;
          if(status == 422) {
            this.openModalErrorSave(data.errors.descricao)
          } else {
            this.openModalErrorSave(data.errors)
          }
        })
      } else {
        this.openModalErrorSave("O campo Nome do negócio é obrigatório!")
      }

    },

    formatParams(){
      return {
        descricao: this.form.name_business,
        id_grupo_negocio: this.idGroupBusiness,
        ids_empresa_linkadas: this.companiesLinked,
        ids_empresa_nao_linkadas: this.companiesNotLinked,
        id_grupo: this.idGroup
      }
    },

    cancelBusiness() {
      this.modal.modalConfirm.modalShow = true
    },

    redirectToList() {
      this.modal.modalSuccess.modalShow = false
      this.$router.push({ name: 'view-company-group' })
    },

    closeModalConfirm() {
      this.modal.modalConfirm.modalShow = false
    },

    openModalErrorSave(errorText) {
      this.$swal({
        title: 'Ocorreu um erro.',
        icon: 'warning',
        iconColor: "red",
        html: `<div>${errorText}</div>`,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: "btn btn-custom-blue"
        },
        buttonsStyling: false
      })
    },
  },
}

</script>

<style>
.container_edit_name_group {
  background-color: #FFFFFF;
}
</style>