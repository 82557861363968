<template>
  <div>
    <section v-if="registerCompanyUser">
      <LinkFirstCompany />
    </section>
    <section v-else>
      <RegisterEmpresa />
    </section>
  </div>
</template>

<script>
import { actions, subjects } from "@/libs/acl/rules";
import RegisterEmpresa from "@/views/custom-pages/empresa/Register-empresa.vue";
import LinkFirstCompany from "@/views/custom-pages/empresa/Link-first-company.vue";

export default {
  components: {
    RegisterEmpresa,
    LinkFirstCompany
  },
  data() {
    return {
      registerCompanyUser: this.$can(actions.INSERIR, subjects.EMPRESAS_AE),
      componentName: "EmpresaIndex"
    };
  }
};
</script>