<template>
  <b-modal @shown="onModalShown" id="add-new-lote" no-close-on-esc no-close-on-backdrop hide-header hide-footer centered
    v-model="showModal">
    <div class="modal-spinner" v-if="loading">
      <b-spinner />
    </div>

    <div v-if="!loading" class="d-flex flex-column my-2">
      <h2>Novo Lote</h2>
      <validation-observer ref="itemRules">
        <b-form>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Lote*" label-for="lote-input">
                <validation-provider #default="{ errors }" name="lote"
                  :rules="{ required: true, uniqueLote: { lotes: lotes, disabled: editing } }">
                  <b-form-input :disabled="editing" id="lote-input" v-model="item.lote" placeholder="N° do Lote"
                    maxLength="15" />
                  <small v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Código Externo" label-for="codigo-externo-input">
                <validation-provider #default="{ errors }" name="codigoExterno" rules="max:7">
                  <b-form-input id="codigo-externo-input" v-model="item.codigoExterno"
                    placeholder="Cód. sistema externo" :disabled="disabled.codigoExterno" />
                  <small v-if="errors[0]" class="text-danger">
                    Máximo de 7 caracteres.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <b-form-group label="Fabricante*" label-for="fabricante-input">
                <validation-provider #default="{ errors }" name="fabricante" rules="required">
                  <v-select id="fabricante-input" v-model="item.fabricante" :options="fabricanteSelect"
                    label="descricao" placeholder="Selecione o fabricante" :clearable="true"
                    :disabled="disabled.fabricante">
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="12" md="12" sm="12">
              <b-form-group label="Vacina*" label-for="vacina-input">
                <validation-provider #default="{ errors }" name="vacina" rules="required">
                  <v-select id="vacina-input" v-model="item.vacina" :options="vacinaSelect" label="nome_tecnico_vacina"
                    placeholder="Selecione uma vacina" :clearable="true" :disabled="disabled.vacina">
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Tipo de frasco*" label-for="dose-input">
                <validation-provider #default="{ errors }" name="tipo_dose" rules="required">
                  <v-select id="dose-input" v-model="item.frasco" :options="frascoSelect" label="descricao"
                    placeholder="Selecione um frasco" :clearable="true" :disabled="disabled.frasco">
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                    Este campo é de preenchimento obrigatório.
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <b-form-group label="Validade*" label-for="validade-input">
                <validation-provider #default="{ errors }" name="validade"
                  :rules="{ required: true, isPastDate: true }">
                  <b-input-group>
                    <b-form-input class="form-control" id="validade-input" v-mask="'##/##/####'"
                      v-model="input.validade" placeholder="00/00/0000" autocomplete="off" @input="setInput"
                      :disabled="disabled.validade" />
                    <b-input-group-append>
                      <b-form-datepicker class="form-control d-flex align-items-center" locale="pt-BR"
                        v-model="item.validade" button-only right :hide-header="true"
                        label-help="Selecione a data de validade" button-variant="custom-blue" clearable
                        @input="setPicker" :disabled="disabled.validade" />
                    </b-input-group-append>
                  </b-input-group>
                  <small v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <section v-if="!loading" class="d-flex justify-content-center mb-1">
      <b-button id="save-lote" class="mr-2 mb-2-sm" variant="custom-blue" @click.prevent="confirmAction"
        :disabled="saving">
        <feather-icon icon="CheckIcon" class="mr-50" />
        <span class="align-middle">{{ editing ? 'Editar Lote' : 'Inserir Lote' }}</span>
        <output v-if="saving" class="spinner-border spinner-border-sm ml-1"></output>
      </b-button>
      <b-button id="cancel-lote" :disabled="saving" variant="outline-danger" @click.prevent="cancelAction">
        <feather-icon icon="XIcon" class="mr-50" />
        <span class="align-middle">Descartar</span>
      </b-button>
    </section>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import VueCleave from "vue-cleave-component";
import { required } from "@validations";
import moment from "moment";
import vSelect from "vue-select";

extend("isPastDate", {
  message: "A data não pode ser inferior à data atual.",
  validate(value) {
    if (!value) return false;

    const [day, month, year] = value.split("/");
    const inputDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return inputDate >= currentDate;
  },
});

extend("uniqueLote", {
  message: "O número do lote informado já existe.",
  params: ["lotes", "disabled"],
  validate(value, { lotes, disabled }) {
    if (disabled) return true;
    if (!value) return false;
    return !lotes.some(lote => lote.numero_lote === value.toUpperCase());
  },
});

export default {
  components: {
    BModal,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    VBTooltip,
    BSpinner,
    BCard,
    vSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    VueCleave,
    BAlert,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    cleanModal: {
      type: Boolean,
      default: false,
    },
    itemEdit: {
      default: null,
    },
    vacinaSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    fabricanteSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    frascoSelect: {
      type: Array,
      default() {
        return [];
      },
    },
    lotes: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      required,
      saving: false,
      loading: false,
      item: {
        index: null,
        vacina: null,
        codigoExterno: null,
        fabricante: null,
        frasco: null,
        lote: "",
        validade: "",
      },
      input: {
        validade: "",
      },
      disabled: {
        vacina: false,
        fabricante: false,
        frasco: false,
        validade: false,
        codigoExterno: false,
      },
      editing: false,
    };
  },
  methods: {
    onModalShown() {
      if (!this.editing) {
        this.emptyForm();
      }
    },
    confirmAction() {
      this.$refs.itemRules.validate().then((success) => {
        if (success) {
          let formattedItem = {
            id_vacina: this.item.vacina.id_vacina,
            id_fabricante: this.item.fabricante.id_fabricante,
            id_frasco: this.item.frasco.id_frasco,
            numero_lote: this.item.lote,
            codigo_externo: this.item.codigoExterno,
            validade: this.item.validade,
          };
          if (!this.editing) {
            this.$emit("salvar", formattedItem);
          } else {
            formattedItem.id_lote = this.item.index;
            this.$emit("editar", formattedItem);
          }
        }
      });
    },
    cancelAction(modalEvent) {
      modalEvent.preventDefault();
      this.$emit("canceled", modalEvent);
      this.$refs.itemRules.reset();
    },
    setPicker() {
      this.input.validade = moment(this.item.validade).format("DD/MM/YYYY");
    },
    setInput() {
      this.item.validade = moment(this.input.validade, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
    },

    fillForm(itemEdit) {
      this.editing = true;
      this.item.index = itemEdit.id_lote;
      this.item.lote = itemEdit.numero_lote;
      this.item.vacina = itemEdit.vacina.nome_tecnico;
      this.item.codigoExterno = itemEdit.codigo_externo;
      this.item.fabricante = itemEdit.fabricante;
      this.item.frasco = itemEdit.frasco;
      this.item.codigoExterno = itemEdit.codigo_externo;
      this.item.validade = itemEdit.validade;
      this.setPicker();
    },

    emptyForm() {
      this.item.vacina = "";
      this.item.fabricante = "";
      this.item.frasco = "";
      this.item.lote = "";
      this.item.validade = "";
      this.input.validade = "";
      this.item.codigoExterno = null;
      this.disabled.vacina = false;
      this.disabled.fabricante = false;
      this.disabled.frasco = false;
      this.disabled.validade = false;
      this.disabled.codigoExterno = false;
      this.editing = false;
    },
  },
  watch: {
    itemEdit(val) {
      if (!val) {
        this.emptyForm();
        return;
      }

      if (val.index != null) {
        this.fillForm(val);
      }
    },
    cleanModal(value) {
      if (value) {
        this.emptyForm();
      }
    },
  },
};
</script>

<style scoped>
.bloco {
  height: 200px;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.panel-enter-active,
.panel-leave-active {
  transition: all 0.5s ease;
}

.panel-enter-from,
.panel-leave-to {
  opacity: 0;
  height: 0;
  transform: translateX(30px);
}

.modal-spinner {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
