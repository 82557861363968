<template>
  <div class="content-wrapper">

    <SucessoModal
      item-identifier="Negócios"
      :modal-show="modal.modalSuccess.modalShow"
      :type-modal="modal.modalSuccess.typeModal"
      :case-modal="modal.modalSuccess.caseModal"
      @confirmed="redirectToList"
    />

    <ConfirmModal
      item-identifier="Negócios"
      :modal-show="modal.modalConfirm.modalShow"
      :type-modal="modal.modalConfirm.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalConfirm"
    />
    <PageHeader
      screen-name="Criar Negócio"
      :link-items="linkItems"
    />
    <main class="card p-2">
      <validation-observer ref="business">
        <b-form>
          <div class="pt-1 card container_create_name_group">
            <b-row>
              <b-col
                lg="12"
                md="12"
                sm="12"
              >

                <b-form-group
                  label="Nome do Negócio *"
                  label-for="name_group"
                  label-class="font_size_label"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nome do Negócio"
                    rules="required|min:3|max:50"
                  >
                    <b-form-input
                      id="name_business"
                      v-model="form.name_business"
                      class="form-control"
                      maxlength="50"
                      minlength="3"
                      placeholder="Nome do Negócio"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

        </b-form>
      </validation-observer>
      <CompaniesLinked
        :companies="companies"
        @companiesLinked="setCompaniesLinked"
      />
      <b-row>
        <b-col class="mt-2">
          <b-button
            class="ml-auto"
            variant="primary-button"
            @click="saveBusiness()"
          >
            <span class="align-middle">Salvar Alterações</span>
          </b-button>
          <b-button
            class="btn-outline-danger ml-2"
            variant="btn-outline-danger"
            @click="cancelBusiness()"
          >
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
      </b-row>
    </main>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BContainer,
  BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import CompaniesLinked from '@/views/custom-pages/GrupoEmpresa/Components/CompaniesLinked.vue'
import { getCompanyBusiness } from '@/@core/utils/store/getStore/index'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'

export default {
  title: 'Editar grupo empresa',

  components: {
    ConfirmModal,
    SucessoModal,
    CompaniesLinked,
    BFormCheckbox,
    BContainer,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    PageHeader,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      linkItems: [
        {
          name: 'Listagem de grupos',
          routeName: 'group-company-list',
        },
        {
          name: 'Criar Negócio',
          active: true,
        },
      ],
      form: {
        name_business: '',
      },
      companies: [],
      companiesLinked: [],
      modal: {
        modalSuccess: {
          modalShow: false,
          typeModal: 'success',
          caseModal: 'criacao',
        },
        modalConfirm: {
          modalShow: false,
          typeModal: 'danger',
        },
      },
    }
  },
  mounted() {
    this.companies = getCompanyBusiness()
  },
  methods: {
    setCompaniesLinked(companiesLinked) {
      this.companiesLinked = companiesLinked.map(company => company.id_empresa)
    },

    async saveBusiness() {
      const isValid = await this.$refs.business.validate()
      const params = {
        descricao: this.form.name_business,
        ids_empresa: this.companiesLinked,
        id_grupo: this.$route.query.id_grupo,
        ids_empresa_linkadas: this.companiesLinked
      }

      if (isValid) {
        this.$http.post(this.$api.grupoNegocio(), params).then((response) => {
          this.modal.modalSuccess.modalShow = true
        }).catch(({ response }) => {
          const { data, status } = response;
          if(status == 422) {
            this.openModalErrorSave(data.errors.descricao)
          } else {
            this.openModalErrorSave(data.errors)
          }
        })
      }
    },

    cancelBusiness() {
      this.modal.modalConfirm.modalShow = true
    },

    redirectToList() {
      this.modal.modalSuccess.modalShow = false
      this.$router.push({ name: 'view-company-group' })
    },

    closeModalConfirm() {
      this.modal.modalConfirm.modalShow = false
    },

    openModalErrorSave(errorText) {
      this.$swal({
        title: 'Ocorreu um erro.',
        icon: 'warning',
        iconColor: "red",
        html: `<div>${errorText}</div>`,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: "btn btn-custom-blue"
        },
        buttonsStyling: false
      })
    },
  },
}

</script>

<style>
.container_create_name_group {
  background-color: #FFFFFF;
}
</style>
