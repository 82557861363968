import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData){
          this.tabela.items = [
            {
              "criacao": "2025-01-01 00:00:00",
              "id_tipo_relatorio_usuario": 1,
              "id_tipo_relatorio": 7,
              "descricao": "Colaboradores Cadastrados",
              "id_anexo": null,
              "situacao": "Sucesso",
              "filtro": "{\"CNPJ\": \"00000000000000\", \"Empresa\": [\"Empresa Tutorial\"]}",
              "anexo": null
            }
          ];
          this.showTable = true;
          this.startPagination(
            {
              total: 1,
              from: 1,
              to: 1,
              current_page: 1
            }
          )
        }
    }
  };