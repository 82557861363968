import Helper from '@/helpers'

const versionRoute = Helper.getVersionApi

const API_VERSION = 'v1'

export default {
  departamento: () => versionRoute(API_VERSION, '/departamento'),
  departamentoParameter: idDepartamento => versionRoute(API_VERSION, `/departamento/${idDepartamento}`),
  departamentoTipoEmpresa: () => versionRoute(API_VERSION, '/departamento/tipo-empresa'),

  regiao: () => versionRoute(API_VERSION, '/regiao'),
  regiaoParameter: idRegiao => versionRoute(API_VERSION, `/regiao/${idRegiao}`),
  changeDevideByRegion: () => versionRoute(API_VERSION, '/regiao/departamento/divisao-por-regiao'),
  unidade: () => versionRoute(API_VERSION, '/unidade'),
  unidadeParameter: idUnidade => versionRoute(API_VERSION, `/unidade/${idUnidade}`),
  unidadeRegiao: idRegiao => versionRoute(API_VERSION, `/unidade/regiao/${idRegiao}`),
  unidadeCampanhaParameter: idCampanha => versionRoute(API_VERSION, `/unidade/campanha/${idCampanha}`),
  getUnidadePorDepartamentoAtualECidade: idCidade => versionRoute(API_VERSION, `/unidade/departamento/cidade/${idCidade}`),
  unidadeResponsavel: idUnidade => versionRoute(API_VERSION, `/unidade/${idUnidade}/responsavel`),
  getUnidadePorIdEmpesaEndereco: idEmpresaEndereco => versionRoute(API_VERSION, `/empresa-endereco/${idEmpresaEndereco}`),

  listOrSavePontoAtendimento: () => versionRoute(API_VERSION, '/ponto-atendimento'),
  pontoAtendimentoParameter: idPontoAtendimento => versionRoute(API_VERSION, `/ponto-atendimento/${idPontoAtendimento}`),
  getServicePointTypes: () => versionRoute(API_VERSION, '/tipo-ponto-atendimento'),

  getEstados: () => versionRoute(API_VERSION, '/estado'),
  getCidadesDoEstado: uf => versionRoute(API_VERSION, `/estado/${uf}`),
  cidadesBuscarPorCep: cep => versionRoute(API_VERSION, `/cidade/cep/${cep}`),

  login: () => versionRoute(API_VERSION, '/auth/login'),
  logout: () => versionRoute(API_VERSION, '/auth/logout'),
  recuperarSenha: () => versionRoute(API_VERSION, '/password/forgot'),
  redefinirSenha: () => versionRoute(API_VERSION, '/password/reset'),
  resendEmail: () => versionRoute(API_VERSION, '/auth/reenviar-email'),

  getPerfis: () => versionRoute(API_VERSION, '/usuario/perfil'),
  getPerfil: idPerfil => versionRoute(API_VERSION, `/usuario/perfil/${idPerfil}`),

  saveAndGetDoenca: () => versionRoute(API_VERSION, '/doenca'),
  doencaWithParameter: idDoenca => versionRoute(API_VERSION, `/doenca/${idDoenca}`),

  saveAndGetVacina: () => versionRoute(API_VERSION, '/vacina'),
  getVaccinesOfDisease: idDoenca => versionRoute(API_VERSION, `/vacina/doenca/${idDoenca}`),
  vaccineParameter: idVacina => versionRoute(API_VERSION, `/vacina/${idVacina}`),
  getTiposAplicacao: () => versionRoute(API_VERSION, '/tipo-aplicacao'),

  fabricante: () => versionRoute(API_VERSION, '/fabricante'),
  frasco: () => versionRoute(API_VERSION, '/frasco'),
  notaFiscal: () => versionRoute(API_VERSION, '/nota-fiscal'),
  notaFiscalParameter: idNotaFiscal => versionRoute(API_VERSION, `/nota-fiscal/${idNotaFiscal}`),
  lote: () => versionRoute(API_VERSION, '/lote'),
  listarLote: () => versionRoute(API_VERSION, '/lote/listar'),
  loteParameter: idLote => versionRoute(API_VERSION, `/lote/${idLote}`),
  estoque: () => versionRoute(API_VERSION, '/estoque'),

  tipoMovimentacao: () => versionRoute(API_VERSION, '/tipo-movimentacao'),
  movimentacao: () => versionRoute(API_VERSION, '/movimentacao'),
  movimentacaoParameter: idMovimentacao => versionRoute(API_VERSION, `/movimentacao/${idMovimentacao}`),
  movimentacaoAceiteParameter: idMovimentacao => versionRoute(API_VERSION, `/movimentacao/${idMovimentacao}/aceitar`),
  movimentacaoRecusaParameter: idMovimentacao => versionRoute(API_VERSION, `/movimentacao/${idMovimentacao}/recusar`),
  movimentacaoCancelaParameter: idMovimentacao => versionRoute(API_VERSION, `/movimentacao/${idMovimentacao}/cancelar`),

  usuario: () => versionRoute(API_VERSION, '/usuario'),
  usuarioParameter: idUsuario => versionRoute(API_VERSION, `/usuario/${idUsuario}`),
  usuarioEmpresaParameter: idEmpresa => versionRoute(API_VERSION, `/usuario/empresa/${idEmpresa}`),
  usuarioParameterEmpresa: (idUsuario, idEmpresa) => versionRoute(API_VERSION, `/usuario/${idUsuario}/empresa/${idEmpresa}`),
  getUsuariosDoDepartamento: idDepartamento => versionRoute(API_VERSION, `/usuario/departamento/${idDepartamento}`),

  saveAndGetNotificacao: () => versionRoute(API_VERSION, '/notificacao'),
  getNotificacoesVigentes: () => versionRoute(API_VERSION, '/notificacao/vigente'),
  notificacaoParameter: idNotificacao => versionRoute(API_VERSION, `/notificacao/${idNotificacao}`),
  viewNotificacaoNaoVisualizada: idNotificacaoVisualizada => versionRoute(
    API_VERSION,
    `/notificacao/visualizar/${idNotificacaoVisualizada}`,
  ),

  ajuda: () => versionRoute(API_VERSION, '/ajuda'),
  ajudaIdAjuda: idAjuda => versionRoute(API_VERSION, `/ajuda/${idAjuda}`),
  ajudaSendEmail: () => versionRoute(API_VERSION, '/ajuda/email'),
  ajudaCategorias: () => versionRoute(API_VERSION, '/ajuda/categoria'),

  saveAndGetEmpresa: () => versionRoute(API_VERSION, '/empresa'),
  getShowAndEditEmpresa: idEmpresa => versionRoute(API_VERSION, `/empresa/${idEmpresa}`),
  getCompanyByCnpjWithExternalApi: () => versionRoute(API_VERSION, '/empresa/consulta-cnpj/'),
  empresaUnidadeParameter: idUnidade => versionRoute(API_VERSION, `/empresa/unidade/${idUnidade}`),
  getPessoaFisicaPorCpf: cpf => versionRoute(API_VERSION, `/pessoa-fisica/${cpf}`),
  getTipoEmpresa: () => versionRoute(API_VERSION, '/empresa/tipo-empresa'),
  getTipoPreco: () => versionRoute(API_VERSION, '/campanha/tipo-preco'),
  empresaVerificaVinculo: () => versionRoute(API_VERSION, '/empresa/verifica-vinculo'),
  getEmpresaFornecedorByCnpj: cnpj => versionRoute(API_VERSION,`/empresa/fornecedor/${cnpj}`),
  getEmpresaEnderecoByIdEmpresa: idEmpresa => versionRoute(API_VERSION, `/empresa-endereco/empresa/${idEmpresa}`),
  getEmpresaEnderecoComAdesao: (idEmpresa, idCampanha, idUnidade) => versionRoute(API_VERSION, `/empresa-endereco/empresa/${idEmpresa}/campanha/${idCampanha}/unidade/${idUnidade}`),

  importColaborador: () => versionRoute(API_VERSION, '/integracao/pessoa'),
  importColaboradorParameter: idPlanilha => versionRoute(API_VERSION, `/integracao/pessoa/planilha/${idPlanilha}`),
  importColaboradorDadosComplementares: () => versionRoute(API_VERSION, '/integracao/pessoa/dados-complementares'),

  colaborador: () => versionRoute(API_VERSION, '/colaborador'),
  getEmpresaCategoria: () => versionRoute(API_VERSION, '/empresa/categoria'),

  campanha: () => versionRoute(API_VERSION, '/campanha'),
  getShowAndEditCampanha: idCampanha => versionRoute(API_VERSION, `/campanha/${idCampanha}`),
  qtdDosesCampanhaVacinaUnidade: (idCampanha, idUnidade) => versionRoute(
    API_VERSION,
    `/estimativa/campanha/${idCampanha}/unidade/${idUnidade}`,
  ),
  empresaAdesao: (idUnidade, vacinas) => {
    const rota = versionRoute(
      API_VERSION,
      `/empresa/adesao/unidade/${idUnidade}?`,
    )
    const parametros = vacinas
      .map(vacina => `idsVacina[]=${vacina.id_vacina}`)
      .join('&')

    return rota + parametros
  },
  campanhaId: id => versionRoute(API_VERSION, `/campanha/${id}`),
  campanhaParameterDistribuicaoDoses: idCampanha => versionRoute(API_VERSION, `/campanha/${idCampanha}/dose`),

  campanhaVisualizacaoListagem: (idCampanha, idVacina) => versionRoute(
    API_VERSION,
    `/campanha/${idCampanha}/vacina/${idVacina}/regiao-doses`,
  ),
  campanhaVacinasUnidadesVisualizacao: (idCampanha, idVacina, idRegiao) => versionRoute(
    API_VERSION,
    `/campanha/${idCampanha}/vacina/${idVacina}/regiao/${idRegiao}/unidade-doses`,
  ),

  adminRegiaoVacinasCampanhaId: idCampanha => versionRoute(API_VERSION, `/campanha/vacinas/${idCampanha}`),
  adminRegiaoUnidadesCampanhaVacina: (idCampanha, idVacina) => versionRoute(
    API_VERSION,
    `/unidade/campanha/${idCampanha}/vacina/${idVacina}`,
  ),
  adminRegiaoTipoPrecoCampanhaVacina: (idCampanha, idVacina) => versionRoute(
    API_VERSION,
    `/campanha/${idCampanha}/preco/vacina/${idVacina}`,
  ),
  campanhaEmpresaParameter: idEmpresa => versionRoute(API_VERSION, `/campanha/empresa/${idEmpresa}`),
  campanhaEmpresa: () => versionRoute(API_VERSION, '/campanha/empresa'),

  campanhaCronograma: idCampanha => versionRoute(API_VERSION, `/campanha/${idCampanha}/cronograma`),
  campanhaUf: uf => versionRoute(API_VERSION, `/campanha/uf/${uf}`),

  getIncentiveDisease: idDoenca => versionRoute(API_VERSION, `/incentivo/doenca/${idDoenca}`),

  adesaoCampanhaEmpresaParameter: (idCampanha, idEmpresa) => versionRoute(
    API_VERSION,
    `/adesao/campanha/${idCampanha}/empresa/${idEmpresa}`,
  ),
  adesaoCampanhaEmpresaEnderecoParameter: (idCampanha, idEmpresa, idEndereco) => versionRoute(
    API_VERSION,
    `/adesao/campanha/${idCampanha}/empresa/${idEmpresa}/empresaEndereco/${idEndereco}`,
  ),
  pedido: () => versionRoute(API_VERSION, '/pedido'),
  pedidoId: idPedido => versionRoute(API_VERSION, `/pedido/${idPedido}`),

  vacinacao: () => versionRoute(API_VERSION, '/vacinacao'),
  vacinacaoCancelar: () => versionRoute(API_VERSION, '/vacinacao/cancelar'),
  dadosVacinacao: () => versionRoute(API_VERSION, '/vacinacao/dados'),
  logs: () => versionRoute(API_VERSION, '/logs/laravel'),

  campanhaTipoPreco: (idCampanha, vacinas, idEmpresaEndereco = null) => {
    const rota = versionRoute(
        API_VERSION,
        `/campanha/${idCampanha}/tipo-preco?`,
    )
    const parametros = vacinas
        .map(idVacina => `idsVacina[]=${idVacina}`)
        .join('&')

    const parametroEndereco = idEmpresaEndereco !== null ? `&id_empresa_endereco=${idEmpresaEndereco}` : ''

    return rota + parametros + parametroEndereco
  },

  adesao: () => versionRoute(API_VERSION, '/adesao'),
  adesaoPrecificacoes: () => versionRoute(API_VERSION, '/adesao/precificacoes'),
  getContract: idCampaign => versionRoute(API_VERSION, `/termo/adesao/campanha/${idCampaign}`),
  getEmpresasAdesaoPorCampanha: campanhaId => versionRoute(API_VERSION, `/empresa/adesao/campanha/${campanhaId}`),
  pedidoAdesaoPrecificacoes: idPedido => versionRoute(API_VERSION, `/pedido/${idPedido}/precificacao`),
  estimativaCampanhaVacinaUnidade: (idCampanha, idUnidade) => versionRoute(
    API_VERSION,
    `/estimativa/campanha/${idCampanha}/unidade/${idUnidade}`,
  ),
  saveAdhesionContract: () => versionRoute(API_VERSION, '/campanha/termo'),
  downloadAdhesionContract: idCampanha => versionRoute(API_VERSION, `/termo/campanha/${idCampanha}`),
  acceptTermCompany: () => versionRoute(API_VERSION, '/empresa/aceite/termo'),
  getMessageTermsCompany: () => versionRoute(API_VERSION, '/empresa/mensagem/termo'),
  getMessageTermCompany: idEmpresa => versionRoute(API_VERSION, `/empresa/${idEmpresa}/mensagem/termo`),
  getUnitsByEmpresaId: idEmpresa => versionRoute(API_VERSION, `/unidade/empresa/${idEmpresa}`),
  salvarAgendamento: () => versionRoute(API_VERSION, '/agendamento'),
  recuperaAgendamentoPeloId: idAgendamento => versionRoute(
    API_VERSION,
    `/agendamento/recupera-agendamento/${idAgendamento}`,
  ),
  editarAgendamento: idAgendamento => versionRoute(API_VERSION, `/agendamento/${idAgendamento}`),
  getAndPostAgendamento: () => versionRoute(API_VERSION, '/agendamento'),
  cancelAdhesion: () => versionRoute(API_VERSION, '/adesao/cancelar'),
  CampanhaCalendarioRedirecionamento: uf => versionRoute(
    API_VERSION,
    `/suporte/campanha-calendario-redirecionamento/${uf}`,
  ),
  reportSmd: () => versionRoute(API_VERSION, '/relatorio-smd'),
  reportSmdMonth: () => versionRoute(API_VERSION, '/relatorio-smd/meses'),
  reportSmdYears: () => versionRoute(API_VERSION, '/relatorio-smd/anos'),

  limpezaCacheRegras: () => versionRoute(API_VERSION, '/suporte/limpeza-cache-regras'),

  getJobsComFalha: () => versionRoute(API_VERSION, '/suporte/jobs-falhos'),
  jobComFalhaReprocessar: idFailedJob => versionRoute(
    API_VERSION,
    `/suporte/jobs-falhos/${idFailedJob}/reprocessar`,
  ),
  jobComFalhaExcluir: idFailedJob => versionRoute(API_VERSION, `/suporte/jobs-falhos/${idFailedJob}/excluir`),

  departamentoApi: () => versionRoute(API_VERSION, '/departamento-api/'),
  departamentoApiId: id => versionRoute(API_VERSION, `/departamento-api/${id}`),
  departamentoApiStatus: id => versionRoute(API_VERSION, `/departamento-api/status/${id}`),
  endpoint: () => versionRoute(API_VERSION, '/endpoint/'),
  tipoDepartamento: () => versionRoute(API_VERSION, '/tipo-departamento/'),

  consultaUnidadeIndicador: idUnidade => versionRoute(API_VERSION, `/consulta-unidade-indicadores/${idUnidade}`),
  consultaUnidadePontoAtendimento: idUnidade => versionRoute(
    API_VERSION,
    `/consulta-unidade-ponto-atendimento/${idUnidade}`,
  ),
  consultaUnidadeVacinador: idUnidade => versionRoute(API_VERSION, `/consulta-unidade-vacinador/${idUnidade}`),

  consultaDepartamentoPontoAtendimento: () => versionRoute(API_VERSION, '/consulta-departamento-ponto-atendimento'),
  consultaDepartamentoIndicadores: () => versionRoute(API_VERSION, '/consulta-departamento-indicadores'),
  consultaDepartamentoUnidadeIndicadores: () => versionRoute(API_VERSION, '/consulta-unidade-indicadores'),
  consultaDepartamentoEstoqueVacina: () => versionRoute(API_VERSION, '/consulta-departamento-estoque-vacina'),

  consultaIndicadoresAcessosSistema: () => versionRoute(API_VERSION, '/consulta-acesso-sistema'),
  consultaIndicadoresAcompanhamentoDoses: () => versionRoute(API_VERSION, '/consulta-acompanhamento-doses'),
  consultaIndicadoresTotaisCampanha: () => versionRoute(API_VERSION, '/consulta-total-campanha'),
  consultaIndicadoresDosesDepartamento: () => versionRoute(API_VERSION, '/consulta-doses-departamento'),

  usuarioAdmin: () => versionRoute(API_VERSION, '/usuario-administrativo'),
  usuarioAdminId: id => versionRoute(API_VERSION, `/usuario-administrativo/id/${id}`),
  usuarioAdminAtivarInativar: id => versionRoute(
    API_VERSION,
    `/usuario-administrativo/ativar-inativar/id/${id}`,
  ),

  baseNacionalTiposAtuacao: () => versionRoute(API_VERSION, '/usuario/tipo-atuacao'),

  usuarioEmpresa: () => versionRoute(API_VERSION, '/usuario-empresa'),
  usuarioEmpresaId: id => versionRoute(API_VERSION, `/usuario-empresa/id/${id}`),
  usuarioEmpresaAtivarInativar: id => versionRoute(API_VERSION, `/usuario-empresa/ativar-inativar/id/${id}`),

  usuarioEmpresaSemVinculo: () => versionRoute(API_VERSION, '/usuario-empresa/sem-vinculo'),
  usuarioEmpresaIdSemVinculo: id => versionRoute(API_VERSION, `/usuario-empresa/sem-vinculo/${id}`),

  usuarioVacinasParaEmpresas: () => versionRoute(API_VERSION, '/usuario-empresa/vacinas-para-empresas'),

  empresasVinculadasUsuario: () => versionRoute(API_VERSION, '/usuario-empresa/vinculos'),
  myRules: () => versionRoute(API_VERSION, '/auth/minhas-regras'),
  minhaConta: () => versionRoute(API_VERSION, '/usuario/minha-conta'),
  vacinadores: () => versionRoute(API_VERSION, '/usuario-administrativo/vacinadores'),

  getAndPostGrupo: () => versionRoute(API_VERSION, '/grupo'),
  getGruposCampanhaNacional: () => versionRoute(API_VERSION, '/grupo/campanha/campanha-nacional'),
  getAndPutAndDeleteGrupo: idGrupo => versionRoute(API_VERSION, `/grupo/${idGrupo}`),
  grupoDocumentoId: idGrupoDocumento => versionRoute(API_VERSION, `/grupo/documento/${idGrupoDocumento}`),
  getDepartmentNational: () => versionRoute(API_VERSION, '/departamentos-nacionais'),
  getDepartmentNationalWithCampanha: () => versionRoute(API_VERSION, '/departamentos-nacionais-campanha'),
  getNationalCompanies: () => versionRoute(API_VERSION, '/empresa/nacionais'),
  getDocumentFileById: idAnexo => versionRoute(API_VERSION, `/grupo/documento/anexo/${idAnexo}`),

  nacionalidade: () => versionRoute(API_VERSION, `/nacionalidade`),
  
  getTipoDocumentos: () => versionRoute(API_VERSION, `/tipo-documento`),

  campanhaNacional: () => versionRoute(API_VERSION, '/campanha-nacional'),
  campanhaNacionalId: idCampanha => versionRoute(API_VERSION, `/campanha-nacional/${idCampanha}`),
  saveGrupoDocumento: () => versionRoute(API_VERSION, '/campanha-nacional/documento'),
  getAnexo: idAnexo => versionRoute(API_VERSION, `/anexo/${idAnexo}`),
  gruposAdesaoNacional: () => versionRoute(API_VERSION, '/grupo/adesao/adesao-nacional'),
  departamentosGrupoOperador: () => versionRoute(API_VERSION, '/grupo/grupo-operador/departamentos'),
  adesaoNacional: () => versionRoute(API_VERSION, '/adesao-nacional'),
  adesaoNacionalCancelar: () => versionRoute(API_VERSION, '/adesao-nacional/cancelar'),
  adesaoNacionalGrupos: () => versionRoute(API_VERSION, '/adesao-nacional/grupo-operador'),
  adesaoNacionalGrupoOperadorCampanha: () => versionRoute(API_VERSION, '/adesao-nacional/grupo-operador/adesoes'),
  grupoOperadorCampanha: () => versionRoute(API_VERSION, '/grupo/grupo-operador/operadores-campanha'),

  estimativaCampanhaNacionalGroup: (idCampanha, idGrupo) => versionRoute(
    API_VERSION,
    `/estimativa/campanha/${idCampanha}/grupo/${idGrupo}`,
  ),
  enderecosEmpresasGroup: () => versionRoute(API_VERSION, '/adesao-nacional/grupo-empresa-endereco'),

  campanhasAdesaoNacional: () => versionRoute(API_VERSION, '/campanha-nacional/adesao-nacional'),

  // Dashboard - Admin Empresa
  grupoOperadorUf: () => versionRoute(API_VERSION, '/grupo-operador-uf'),
  getDashboardDataAdminEmpresa: () => versionRoute(API_VERSION, '/dashboard/admin-empresa'),
  suportePessoaFisica: () => versionRoute(API_VERSION, '/suporte/pessoa-fisica'),

  // Dashboard - Usuários SESI
  getDashboardFilterOptions: (perfil) => versionRoute(API_VERSION, `/dashboard/filtro/${perfil}`),
  getDashboardData: (perfil) => versionRoute(API_VERSION, `/dashboard/dados/${perfil}`),
  getVaccinatorDashboardTableData: () => versionRoute(API_VERSION, `/dashboard/table/vacinador`),

  tipoRelatorio: () => versionRoute(API_VERSION, '/tipo-relatorio'),
  tipoRelatorioUsuario: () => versionRoute(API_VERSION, '/tipo-relatorio-usuario'),
  tipoRelatorioUsuarioExportar: (idTipoRelatorioUsuario) => versionRoute(API_VERSION, `/tipo-relatorio-usuario/${idTipoRelatorioUsuario}`),

  cidade: () => versionRoute(API_VERSION, '/cidade'),
  cidadeUnidade: (idsUnidades) => {
    const rota = versionRoute(API_VERSION, `/cidade/unidade?`)
    const parametros = idsUnidades
      .map(idUnidade => `ids_unidades[]=${idUnidade}`)
      .join('&')

    return rota + parametros
  },
  cidadeRegiao: (idsRegioes) => {
    const rota = versionRoute(API_VERSION, `/cidade/regiao?`)
    const parametros = idsRegioes
      .map(idRegiao => `ids_regioes[]=${idRegiao}`)
      .join('&')

    return rota + parametros
  },
  cidadeEstado: (uf_estados) => {
    const rota = versionRoute(API_VERSION, `/cidade/estado?`)
    const parametros = uf_estados
      .map(uf_estado => `uf[]=${uf_estado}`)
      .join('&')

    return rota + parametros
  },
  empresaRelatorioExportacao: () => versionRoute(API_VERSION, '/empresa/relatorio-exportacao'),
  validarRemocaoGrupoCampanha: () => versionRoute(API_VERSION, '/campanha-nacional/grupo/validar-remocao'),
  regiaoEstado: (uf_estados) => {
    const rota = versionRoute(API_VERSION, `/regiao/estado?`)
    const parametros = uf_estados
      .map(uf_estado => `uf[]=${uf_estado}`)
      .join('&')

    return rota + parametros
  },
  estadoRelatorio: () => versionRoute(API_VERSION, '/estado-relatorio'),
  campanhaEmpresaResumoDoseAplicada: () => versionRoute(API_VERSION, '/campanha/empresa/resumo-aplicacao-doses'),

  importarVacinados: () => versionRoute(API_VERSION, '/vacinacao/importacao'),
  listarImportacaoDeVacinados: (filtros) => {
      const params = new URLSearchParams(filtros).toString();
    return versionRoute(API_VERSION, `/vacinacao/importacao?${params}`)
  },
  deletarImportacaoDeVacinados: id => versionRoute(API_VERSION, `/vacinacao/importacao?id=${id}`),
  downloadImportacaoVacinados: (id) => versionRoute(API_VERSION, `/vacinacao/importacao/download?id=${id}`),
  empresaCampanha: () => versionRoute(API_VERSION, '/empresa/campanha'),
  usuarioSituacaoTutorial: () => versionRoute(API_VERSION, '/usuario/situacao-tutorial'),

  grupoNegocio: () => versionRoute(API_VERSION, '/grupo-negocios'),
  getAndPutAndDeleteGrupoNegocio: (idGrupoNegocio) => versionRoute(API_VERSION, `/grupo-negocios/${idGrupoNegocio}`),
  grupoEmpresa: () => versionRoute(API_VERSION, '/grupo-negocios/listar-empresas'),
}
