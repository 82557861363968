<template>
  <div class="content-wrapper">
    <PageHeader screenName="Lotes" :linkItems="linkItems" />

    <!-- Modal de ativação/inativação de lote -->
    <AtivaDesativaLoteModal :modalShow="ativaDesativaLoteModal.showModal" :typeModal="ativaDesativaLoteModal.typeModal"
      itemIdentifier="lote" :infoText="{
        success: 'Essa ação dará permissão de uso ao lote.',
        warning: 'O lote não terá mais permissão de uso.',
      }" @confirmed="changeStateLote" @canceled="cancelarAtivaDesativaLote" />

    <!-- Modal de exclusão de lote -->
    <ConfirmDeleteModalLote :modal-show="deleteModal.showModal" @confirmDelete="actionDeleteLote"
      @cancelDelete="closeModalDelete" />

    <!-- Modal de confirmação de inclusão/edição de lote -->
    <ConfirmModal itemIdentifier="O lote" :modalShow="modalFeedback.showModal" :typeModal="modalFeedback.typeModal"
      :action="modalFeedback.action" @confirmed="modalFeedback.confirmed" @canceled="modalFeedback.canceled" />

    <!-- Modal de inclusão/edição de lote -->
    <AddLoteModal :showModal="modalLote.showModal" :itemEdit="modalLote.item" :cleanModal="modalLote.cleanModal"
      :vacinaSelect="vacinaSelect" :fabricanteSelect="fabricanteSelect" :frascoSelect="frascoSelect" :lotes="loteSelect"
      @canceled="openModalCancel" @salvar="openModalConfirm" @editar="openModalEdit" />

    <main class="card hidden_overflow_tabela">
      <b-row class="p-2">
        <b-col lg="4" md="6" sm="12">
          <b-button v-if="canChange || canInsert" @click.prevent="openModalLote()" :disabled="!canChange && !canInsert"
            variant="primary-button">
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Novo lote</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- Lote -->
      <b-row class="pt-1 px-2">
        <b-col lg="2" md="6" sm="12">
          <b-form-group label="Lote" label-for="lote-input" label-class="font_size_label_estoque">
            <b-form-input v-model="search.lote" id="lote-input" placeholder="N° do lote" />
          </b-form-group>
        </b-col>

        <!-- Vacina -->
        <b-col lg="3" md="6" sm="12">
          <b-form-group label="Vacina" label-for="vacina-input" label-class="font_size_label_estoque">
            <v-select id="vacina-input" v-model="search.vacina" :options="vacinaSelect" label="nome_tecnico_vacina"
              placeholder="Selecione uma vacina" :clearable="true">
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Fabricante -->
        <b-col lg="3" md="6" sm="12">
          <b-form-group label="Fabricante" label-for="fabricante-input" label-class="font_size_label_estoque">
            <v-select id="fabricante-input" v-model="search.fabricante" :options="fabricanteSelect" label="descricao"
              placeholder="Selecione um fabricante" :clearable="true">
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Status -->
        <b-col lg="2" md="6" sm="12">
          <b-form-group label="Status" label-for="status-input" label-class="font_size_label_estoque">
            <v-select id="status-input" :clearable="true" v-model="search.status" variant="custom"
              placeholder="Selecione um ou mais" :options="statusSelect">
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Lotes vencidos -->
        <b-col lg="2" md="6" sm="12">
          <b-form-group label="Lotes Vencidos" label-for="vencidos-input" label-class="font_size_label_estoque">
            <v-select id="vencidos-input" :clearable="true" v-model="search.vencidos" variant="custom"
              placeholder="Selecione um ou mais" :options="vencidosSelect">
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class="justify-content-center">
        <b-button @click="() => {
          filterData();
        }" variant="primary-button">
          <feather-icon icon="SearchIcon" class="mr-50" />
          <span class="align-middle">Pesquisar</span>
        </b-button>

        <b-button type="reset" class="ml-2 cor_botao" @click="cleanSearch" variant="outline-primary">
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Limpar</span>
        </b-button>
      </b-row>
      <div v-if="loadingData" class="d-flex justify-content-center my-3">
        <output class="spinner-border text-custom-blue" v-if="loadingData">
          <span class="sr-only">Loading...</span>
        </output>
      </div>
      <hr>
      <div>
        <section class="filters pb-1 px-2" v-if="!loadingData">
          <b-row align-h="between">
            <b-col lg="6" md="6" sm="6">
              <div class="d-flex justify-center-center align-items-center">
                <span class="mr-1">Mostrar</span>
                <v-select id="tableSize" v-model="paginationData.defaultSize" @input="updateTableSize($event)"
                  :options="table.optionsSize" :clearable="false" style="width: 90px;" />
              </div>
            </b-col>
            <b-col lg="6" md="6" sm=6>
              <ButtonDownloadSpreadsheet type="Lote" :parameters="paramsLote" :size="size"
                :isEmpty="table.items.length === 0" tooltip-text="Exportação de planilha de lote" />
            </b-col>
          </b-row>
        </section>
        <section class="list-estoque" v-if="!loadingData">
          <b-table id="tableLote" responsive :stickyColumn="true" :busy.sync="filtering" :fields="table.fields"
            :items="table.items" @sort-changed="ordenarColuna" no-local-sorting :no-sort-reset="true">
            <template #cell(id_lote)="row">
              <span class=" comprimento_maximo_celulas font-weight-bold">
                {{ row.item.numero_lote }}
              </span>
            </template>
            <template #cell(id_vacina)="row">
              <span class="comprimento_maximo_celulas font-weight-bold"
                v-b-tooltip.hover.rightbottom="{ variant: 'light' }" :title="row.item.vacina.nome_tecnico">
                {{ row.item.vacina.nome_tecnico }}
              </span>
            </template>
            <template #cell(validade)="row">
              <span class="font-weight-bold">
                {{ formatDate(row.item.validade, 'DD/MM/YYYY') }}
              </span>
            </template>
            <template #cell(id_fabricante)="row">
              <span class="font-weight-bold">
                {{ row.item.fabricante.descricao }}
              </span>
            </template>
            <template #cell(id_frasco)="row">
              <span class="font-weight-bold comprimento_maximo_celulas rounded-pill custom"
                :class="row.item.frasco.quantidade == 1 ? 'mono' : 'multi'">
                {{ row.item.frasco.descricao }}
              </span>
            </template>
            <template #cell(status)="row">
              <TableSwitch :disabled="!canChange" :checked="row.item.ativo" :description="String(row.item.id_lote)"
                :itemId="row.item.id_lote" @stateModified="openModalAtivaDesativaLote" />
            </template>
            <template #cell(acoes)="row">
              <button id="lote-show" class="button-icon text-custom-blue mr-1" aria-label="Exibir lote"
                v-b-tooltip.hover.html title="<b>Exibir lote</b>" @click.prevent="redirectToShow(row.item)"
                @keydown.enter.prevent="redirectToShow(row.item)" @keydown.space.prevent="redirectToShow(row.item)">
                <feather-icon icon="EyeIcon" size="18" aria-hidden="true">
                </feather-icon>
              </button>

              <button v-if="canChange && row.item.nota_fiscal_itens[0] === null" id="lote-edit"
                class="button-icon text-custom-blue mr-1" aria-label="Editar lote" v-b-tooltip.hover.html
                title="<b>Editar lote</b>" @click.prevent="openModalAddLote(row.item, row.index)"
                @keydown.enter.prevent="openModalAddLote(row.item, row.index)"
                @keydown.space.prevent="openModalAddLote(row.item, row.index)">
                <feather-icon icon="EditIcon" size="18" aria-hidden="true">
                </feather-icon>
              </button>

              <div v-else-if="canChange && row.item.nota_fiscal_itens[0] !== null" v-b-tooltip.hover.html
                title="<b>Este lote possui aplicações registradas e não pode ser editado.</b>" class="d-inline-block">
                <button id="lote-edit" class="button-icon text-muted mr-1"
                  aria-label="Este lote possui aplicações registradas e não pode ser editado." disabled>
                  <feather-icon icon="EditIcon" size="18" aria-hidden="true">
                  </feather-icon>
                </button>
              </div>

              <button v-if="canChange && row.item.nota_fiscal_itens[0] === null" id="lote-destroy"
                class="button-icon text-custom-blue" aria-label="Deletar lote" v-b-tooltip.hover.html
                title="<b>Deletar lote</b>" @click.prevent="modalDeleteLote(row.item)"
                @keydown.enter.prevent="modalDeleteLote(row.item)" @keydown.space.prevent="modalDeleteLote(row.item)">
                <feather-icon icon="TrashIcon" size="18" aria-hidden="true">
                </feather-icon>
              </button>

              <div v-else-if="canChange && row.item.nota_fiscal_itens[0] !== null" v-b-tooltip.hover.html
                title="<b>Este lote possui aplicações registradas e não pode ser excluído. Considere inativá-lo.</b>"
                class="d-inline-block">
                <button id="lote-destroy" class="button-icon text-muted mr-1"
                  aria-label="Este lote possui aplicações registradas e não pode ser excluído. Considere inativá-lo."
                  disabled>
                  <feather-icon icon="TrashIcon" size="18" aria-hidden="true">
                  </feather-icon>
                </button>
              </div>
            </template>
          </b-table>
          <div class="pb-2 px-1">
            <span v-if="without" class="ml-1">Nenhum lote encontrado</span>
            <b-col sm="12" class="mt-2">
              <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage" />
            </b-col>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue';
import {
  successCheckCircleMessageConfirmAction,
  triangleWarnMessage,
} from '@/libs/sweetalerts';
import { actions, subjects } from "@/libs/acl/rules";
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ButtonDownloadSpreadsheet from "@/views/components/button-download-spreadsheet/ButtonDownloadSpreadsheet.vue";
import AtivaDesativaLoteModal from '@/views/components/custom/modals/ConfirmModal.vue';
import ConfirmDeleteModalLote from '@/views/custom-pages/GerenciarEstoqueLote/Lote/components/ConfirmDeleteModalLote.vue'
import ConfirmModal from '@/views/custom-pages/GerenciarEstoqueLote/Lote/components/ConfirmModalLote.vue'
import CustomAlert from "@/views/components/custom/timed-alert/CustomAlert.vue";
import AddLoteModal from '@/views/components/custom/modals/estoque/Add-novo-item-lote.vue';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'

export default {
  title: 'Lista de lote',

  components: {
    ButtonDownloadSpreadsheet,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    InfograficoEstoqueCard,
    InfoCard,
    AtivaDesativaLoteModal,
    ConfirmDeleteModalLote,
    CustomAlert,
    AddLoteModal,
    ConfirmModal,
    SucessoModal
  },

  directives: {
    'b-tooltip': VBTooltip
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Estoque',
          routeName: 'estoque-list',
        },
        {
          name: 'Lote',
          active: true
        }
      ],
      canChange: this.$can(actions.GERENCIAR, subjects.ESTOQUE_LOTE),
      canInsert: this.$can(actions.INSERIR, subjects.ESTOQUE_LOTE),
      alert: {
        errorMessage: '',
        show: false
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false
      },
      filtering: false,
      loadingData: true,
      without: false,
      searchTerm: '',
      vencidosSelect: ['Sim', 'Não'],
      statusSelect: ['Ativo', 'Inativo'],
      table: {
        fields: [
          { key: "id_lote", label: "LOTE", sortable: true, class: 'mw-150' },
          { key: "id_vacina", label: "VACINA", sortable: true, class: 'mw-150' },
          { key: "validade", label: "VALIDADE", sortable: true, class: 'mw-150' },
          { key: "id_fabricante", label: "FABRICANTE", sortable: true, class: 'mw-150' },
          { key: "id_frasco", label: "DOSE", sortable: true, class: 'mw-150' },
          { key: "status", label: "STATUS.", sortable: false, class: 'mw-100' },
          { key: "acoes", label: "AÇÕES", sortable: false, class: 'mw-200' }
        ],
        items: [],
        optionsSize: [10, 20, 50],
        columnOrder: 'validade',
        order: 'desc'
      },
      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      ativaDesativaLoteModal: {
        showModal: false,
        typeModal: 'success',
      },
      deleteModal: {
        showModal: false,
        idLote: null,
        situationLote: null,
      },
      modalLote: {
        showModal: false,
        cleanModal: false,
        item: null
      },
      modalFeedback: {
        showModal: false,
        typeModal: '',
        confirmed: false,
        canceled: false,
        action: ''
      },
      desativarLote: null,
      search: {
        vacina: '',
        fabricante: '',
        status: '',
        vencidos: '',
        lote: '',
      },
      fabricanteSelect: [],
      vacinaSelect: [],
      frascoSelect: [],
      loteSelect: [],
      data: {},
      size: 0
    }
  },
  mounted() {
    this.setSearchFilter();
    this.loadDataLote();
    this.loadAllInfo();
  },

  methods: {
    setSearchFilter() {
      Object.assign(this.search, this.getEstoqueLote)
    },

    async loadAllInfo() {
      const parameters = {
        ativo: true
      }

      const loteParameters = {
        tamanho: 10000
      }

      await this.$http.get(this.$api.saveAndGetVacina(), { params: parameters }).then(({ data }) => {
        if (data.data.length) {
          this.vacinaSelect = data.data.sort((a, b) => a.nome_tecnico_vacina.localeCompare(b.nome_tecnico_vacina))
        }
      });
      await this.$http.get(this.$api.fabricante(), { params: parameters }).then(({ data }) => {
        this.fabricanteSelect = data;
      });
      await this.$http.get(this.$api.frasco(), { params: parameters }).then(({ data }) => {
        this.frascoSelect = data;
      });
      await this.$http.get(this.$api.listarLote(), { params: loteParameters }).then(({ data }) => {
        this.loteSelect = data.data;
      });
    },

    loadDataLote() {
      const params = this.paramsLote;
      this.filtering = true;

      this.$http.get(this.$api.listarLote(), { params })
        .then(({ data }) => {
          this.table.items = data.data;
          this.without = data.data.length === 0;
          this.loadingData = false;
          this.size = data.total;
          this.filtering = false;
          this.startPagination(data);
        });
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    cleanSearch() {
      this.search.vacina = ''
      this.search.fabricante = ''
      this.search.status = []
      this.search.vencidos = []
      this.search.lote = ''

      this.loadDataLote();
    },

    openModalAtivaDesativaLote(value) {
      this.ativaDesativaLoteModal.typeModal = 'warning';

      if (value.state) {
        this.ativaDesativaLoteModal.typeModal = 'success';
      }

      this.$helpers.changeStateActiveTableRow(this.table.items, value, 'id_lote');

      this.desativarLote = value;

      this.ativaDesativaLoteModal.showModal = true;
    },

    modalDeleteLote({ id_lote }) {
      this.deleteModal.showModal = true
      this.deleteModal.idLote = id_lote
    },

    closeModalDelete() {
      this.deleteModal.showModal = false
      this.deleteModal.idLote = null
    },

    actionDeleteLote() {
      this.$http.delete(this.$api.loteParameter(this.deleteModal.idLote))
        .then(() => {
          this.closeModalDelete()
          this.modalSuccessFeedback()
        })
        .catch(error => {
          this.closeModalDelete()
          this.loadingTable = false
          this.handleError(error.response)
        })
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        triangleWarnMessage(
          response.data.errors,
        )

        return
      }

      triangleWarnMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    modalSuccessFeedback(type) {
      let title = '';

      switch (type) {
        case 'salvar':
          title = 'Lote inserido com sucesso!';
          break;
        case 'editar':
          title = 'Lote editado com sucesso!';
          break;
        case false:
          title = 'Lote inativado com sucesso!';
          break;
        case true:
          title = 'Lote ativado com sucesso!';
          break;
        default:
          title = 'Lote excluído com sucesso!';
          break;
      }

      successCheckCircleMessageConfirmAction(null, title).then(() => {
        this.paginationData.currentPage = 1;
        this.loadDataLote();
      });
    },

    openModalLote() {
      this.modalLote.showModal = true;
      this.modalLote.cleanModal = true;
      this.modalLote.item = null;
    },

    openModalAddLote(lote, index) {
      if (lote) {
        lote.index = index;
        this.modalLote.item = lote;
      }
      this.modalLote.showModal = true;
    },

    openModalCancel() {
      this.modalFeedback.showModal = true;
      this.modalFeedback.typeModal = 'warning';
      this.modalFeedback.action = 'descartar';
      this.modalFeedback.confirmed = this.confirmCloseAddLoteModal;
      this.modalFeedback.canceled = this.closeCancelModal;
    },

    openModalConfirm(data) {
      this.modalFeedback.showModal = true;
      this.modalFeedback.typeModal = 'success';
      this.modalFeedback.action = 'salvar';

      this.modalFeedback.confirmed = () => {
        this.$http.post(this.$api.lote(), data)
          .then(() => {
            this.modalFeedback.showModal = false;
            this.modalLote.showModal = false;
            this.modalSuccessFeedback(this.modalFeedback.action);
          })
          .catch(({ response: { data } }) => {
            this.handleError(data);
          });
      };

      this.modalFeedback.canceled = this.closeCancelModal;
    },

    openModalEdit(data) {
      this.modalFeedback.showModal = true;
      this.modalFeedback.typeModal = 'warning';
      this.modalFeedback.action = 'editar';

      this.modalFeedback.confirmed = () => {
        this.$http.put(this.$api.loteParameter(data.id_lote), data)
          .then(() => {
            this.modalFeedback.showModal = false;
            this.modalLote.showModal = false;
            this.modalSuccessFeedback(this.modalFeedback.action);
          })
          .catch(({ response: { data } }) => {
            this.handleError(data);
          })
      };

      this.modalFeedback.canceled = this.closeCancelModal;
    },

    confirmCloseAddLoteModal() {
      this.modalFeedback.showModal = false;
      this.modalLote.showModal = false;
    },

    closeCancelModal() {
      this.modalFeedback.showModal = false;
    },

    changeStateLote() {
      this.$http.patch(this.$api.loteParameter(this.desativarLote.itemId)).then(() => {
        this.ativaDesativaLoteModal.showModal = false;
        this.alert.show = false;
        this.modalSuccessFeedback(this.desativarLote.state);
      }).catch(({ response: { data } }) => {
        this.ativaDesativaLoteModal.showModal = false;
        this.$helpers.backStateActiveTableRow(this.table.items, this.desativarLote, 'id_lote');
        this.alert.errorMessage = data.errors;
        this.alert.show = !!data.errors;;
      });
    },

    cancelarAtivaDesativaLote() {
      this.$helpers.backStateActiveTableRow(this.table.items, this.desativarLote, 'id_lote');
      this.ativaDesativaLoteModal.showModal = false;
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? 'desc' : 'asc';

      this.filtering = true;

      this.loadDataLote();
    },

    redirectToShow(item) {
      this.$router.push(
        {
          name: 'lote-show',
          params: {
            idLote: item.id_lote,
          }
        }
      );
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
      this.loadDataLote();
    },

    updateTableSize(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }
      this.filtering = true;

      this.paginationData.currentPage = 1;
      this.loadDataLote();
    },

    filterData() {
      if (this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
        this.without = false;

        clearTimeout(this.timeID);
        this.timeID = setTimeout(() => {
          this.filtering = true;
          this.loadDataLote();
        }, 500);
      }
    },

    formatDate(string, format) {
      return moment(string).format(format);
    },
  },

  computed: {
    getEstoqueLote() {
      return this.$store.getters['filtrosState/getEstoqueLote'];
    },

    paramsLote() {
      return {
        vencido: !this.search.vencidos
          ? null
          : { 'Sim': true, 'Não': false }[this.search.vencidos] ?? '',
        id_vacina: this.search.vacina ? [this.search.vacina.id_vacina] : '',
        id_fabricante: this.search.fabricante ? this.search.fabricante.id_fabricante : '',
        ativo: !this.search.status
          ? true
          : { 'Ativo': true, 'Inativo': false }[this.search.status] ?? true,
        numero_lote: this.search.lote,
        termoPesquisa: this.searchTerm,
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order
      };
    },
  },

  watch: {
    search: {
      handler(newValue) {
        this.$store.commit('filtrosState/setEstoqueLote', newValue);
      },
      deep: true
    }
  }
}

</script>

<style>
.font_size_label_estoque {
  font-size: 15px;
  margin-top: -5px;
}

.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}

.mono {
  background-color: #f3eeff;
  color: #8c65e2;
}

.button-icon {
  background: none;
  border: none;
  padding: 0;
  align-items: center;
}

.button-icon:focus {
  outline: none;
}

.multi {
  background-color: #e3f6f4;
  color: #21a696;
}

.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}

.altura_max_scrollbar {
  max-height: 100px;
  padding-right: 15px;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.hidden_overflow_tabela {
  overflow: hidden;
}
</style>