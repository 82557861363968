<template>
  <div class="d-flex align-items-center justify-content-end">
    <b-button variant="outline-primary" class="cor_botao" :disabled="isDisabled" @click="downloadSpreadsheet()"
      v-b-tooltip.hover.bottom="tooltipText">
      <button id="download-import" class="button-icon text-custom-blue btn-icon" @keydown.enter="downloadSpreadsheet()"
        @keydown.space="downloadSpreadsheet()" aria-label="Download spreadsheet">
        <feather-icon icon="DownloadIcon" size="18" aria-hidden="true" />
      </button>
    </b-button>

    <b-modal id="add-new-item" no-close-on-esc no-close-on-backdrop hide-header hide-footer centered
      v-model="showModal">
      <div v-if="showModal" class="d-flex justify-content-center my-3">
        <output class="spinner-border text-custom-blue">
          <span class="sr-only">Loading...</span>
        </output>
      </div>
      <h2 class="text-center">Estamos processando</h2>
    </b-modal>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import moment from "moment/moment";

export default {
  name: "ButtonDownloadSpreadsheet",
  components: { BButton },
  props: {
    type: {
      type: String,
      required: true
    },
    parameters: {
      type: Object,
      required: true
    },
    isEmpty: {
      type: Boolean,
      default: true
    },
    loadingData: {
      type: Boolean,
      default: true
    },
    tooltipText: {
      type: String
    },
    size: {
      type: Number
    }
  },
  data() {
    return {
      showModal: false,
      activeButtonSheet: false,
      endpoint: null,
      filename: null,
    }
  },
  beforeMount() {
    switch (this.type) {
      case 'EstoqueFisico':
        this.endpoint = this.$api.lote()
        this.filename = 'estoque-fisico.xlsx'
        break
      case 'Entrada':
        this.endpoint = this.$api.notaFiscal()
        this.filename = 'entrada-vacinas.xlsx'
        break
      case 'Transferencia':
        this.endpoint = this.$api.movimentacao()
        this.filename = 'transferencia.xlsx'
        break
      case 'AjusteEstoque':
        this.endpoint = this.$api.movimentacao()
        this.filename = 'ajuste-estoque.xlsx'
        break
      case 'Lote':
        this.endpoint = this.$api.listarLote()
        this.filename = 'lote.xlsx'
        break
      default:
        this.endpoint = ''
    }
  },
  methods: {
    async downloadSpreadsheet() {
      this.showModal = true

      this.parameters.tamanho = this.size

      const consult = await this.$http.get(this.endpoint, { params: this.parameters })
        .finally(() => {
          this.showModal = false
        });

      if (consult.status === 200) {
        this.geraDownload(consult.data.data);
      }
    },
    geraDownload(data) {
      let items;
      switch (this.type) {
        case 'EstoqueFisico':
          items = this.formataDadosEstoqueFisico(data);
          break
        case 'Entrada':
          items = this.formataDadosEntrada(data);
          break
        case 'Transferencia':
          items = this.formataDadosTransferencia(data);
          break
        case 'AjusteEstoque':
          items = this.formataDadosAjusteEstoque(data);
          break
        case 'Lote':
          items = this.formataDadosLote(data);
          break
        default:
          items = []
      }
      this.$helpers.downloadArchiveXlsx(items, this.filename)
    },

    formataDadosEstoqueFisico(dados) {
      return dados.map(item => ({
        'LOTE': item.numero_lote,
        'VACINA': item.nome_tecnico_vacina,
        'VALIDADE': this.formataData(item.validade),
        'FABRICANTE': item.descricao_fabricante,
        'DOSE': item.descricao_frasco,
        'QTD DOSES': item.saldo,
        'UNIDADE OPERACIONAL': item.descricao_unidade,
      }));
    },
    formataDadosEntrada(dados) {
      return dados.map(item => ({
        'Nº Nota Fiscal': item.numero,
        'Data da Emissão': this.formataData(item.emissao),
        'Data do Lançamento': this.formataData(item.lancamento),
        'Fornecedor': item.fornecedor,
        'Lote': item.numero_lote,
        'Unidade Operacional': item.unidade,
        'QTD Doses': item.quantidade,
      }));
    },

    formataDadosTransferencia(dados) {
      return dados.map(item => ({
        'Transferência': '#' + item.codigo,
        'Nota Fiscal': item.numero_nota_fiscal,
        'Status': item.situacao,
        'Lote': item.itens[0].lote_unidade_origem.lote.numero_lote,
        'Última Atualização': item.ultima_alteracao ? this.formataData(item.ultima_alteracao) : "-",
        'QTD Doses': item.itens[0].quantidade,
        'Origem': item.descricao_unidade_origem,
        'Destino': item.descricao_unidade_destino,
      }));
    },
    formataDadosAjusteEstoque(dados) {
      return dados.map(item => ({
        'Ajuste': '#' + item.codigo,
        'Tipo': item.tipo_movimentacao,
        'Data do Ajuste': this.formataData(item.ultima_alteracao),
        'Lote': item.itens[0].lote_unidade_origem.lote.numero_lote,
        'Validade': this.formataData(item.itens[0].lote_unidade_origem.lote.validade),
        'QTD Doses': item.itens[0].quantidade,
        'Unidade Operacional': item.descricao_unidade_origem,
      }));
    },

    formataDadosLote(dados) {
      return dados.map(item => ({
        'LOTE': item.numero_lote,
        'VACINA': item.vacina.nome_tecnico,
        'VALIDADE': this.formataData(item.validade),
        'FABRICANTE': item.fabricante.descricao,
        'DOSE': item.frasco.descricao,
        'STATUS': item.ativo ? 'Ativo' : 'Inativo',
      }));
    },

    formataData(data) {
      return moment(data).format('DD/MM/YYYY');
    }
  },
  computed: {
    isDisabled() {
      return this.isEmpty || this.showModal
    }
  }
}
</script>
<style scoped lang="scss">
.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}

.button-icon {
  background: none;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

.button-icon:focus {
  outline: none;
}

button.disabled {
  cursor: not-allowed;
  background-color: transparent;
  border-color: #6e6b7b !important;

  * {
    cursor: not-allowed;
    color: #6e6b7b !important;
  }
}
</style>