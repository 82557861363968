<template>
  <tour :steps="tourSteps">
  <div class="content-wrapper">
    <PageHeader :screenName="empresa.nome" :linkItems="linkItems" />
    <ErrorModal
      :modalShow="modalError.showModal"
      :caseModal="modalError.caseModal"
      @confirmed="redirecionarLista"
    />
    <div id="empresa">
      <b-card no-body>
        <b-overlay :show="loadingShow" rounded="lg" opacity="0.6" class="w-100">
          <EmpresaHeader :empresa="empresa" @confirmed="resolveRoute" />
          <main class="p-2">
            <alertaTodasAbas :situacao="situacaoCadastro" :show="situacaoCadastro == 'Incompleto'"/>
            <section>
              <b-row>
                <b-col lg="12">
                  <p class="label_titulo">Dados principais</p>
                </b-col>
              </b-row>
              <b-row v-if="cnpj">
                <!-- SECTION CNPJ -->
                <b-col lg="12">
                  <b-form-group
                    label="CNPJ"
                    label-for="cnpj"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="cnpj">
                        {{
                          this.form.dadosPrincipais.cnpj
                            | VMask("##.###.###/####-##")
                        }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group
                    label="Nome fantasia"
                    label-for="nome-fantasia"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="nome-fantasia" class="label_empresa">
                        {{ this.form.dadosPrincipais.nome_fantasia }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="8">
                  <b-form-group
                    label="Razão Social"
                    label-for="razao-social"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="razao-social">
                        {{ this.form.dadosPrincipais.razao_social }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else>
                <!-- SECTION CPF -->
                <b-col lg="12">
                  <b-form-group
                    label="CPF"
                    label-for="cpf"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="cpf" class="label_empresa">
                        {{
                          this.form.dadosPrincipais.cpf | VMask("###.###.###-##")
                        }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    label="Nome"
                    label-for="nome"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="nome" class="label_empresa">
                        {{ this.form.dadosPrincipais.nome }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    label="Inscrição estadual"
                    label-for="inscricao-estadual"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="inscricao-estadual">
                        {{
                          this.form.dadosPrincipais.inscricao_estadual
                            | VMask("###.###.###")
                        }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    label="Data de Nascimento"
                    label-for="data-nascimento"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="data-nascimento">
                        {{ this.form.dadosPrincipais.data_nascimento }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    label="Sexo"
                    label-for="genero"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="genero">
                        {{ this.form.dadosPrincipais.genero }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    label="Nome da mãe"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="nome_mae">
                        {{ this.form.dadosPrincipais.nome_mae }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="3">
                  <b-form-group
                    label="Nacionalidade"
                    label-for="descricao_nacionalidade"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="descricao_nacionalidade">
                        {{ this.form.dadosPrincipais.descricao_nacionalidade }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
              </b-row>
            </section>
            <section class="pt-1">
              <b-row>
                <b-col lg="12">
                  <p class="label_titulo">Dados complementares</p>
                </b-col>
                <b-col lg="4">
                  <b-form-group
                    label="CNAE Principal"
                    label-for="cnae-principal"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div
                        v-if="this.form.dadosComplementares.cnae != '-'"
                        id="cnae-principal"
                        class="label_empresa"
                      >
                        {{
                          this.form.dadosComplementares.cnae | VMask("####-##/##")
                        }}
                      </div>
                      <div v-else id="cnae-principal" class="label_empresa">
                        {{ this.form.dadosComplementares.cnae }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="4" v-if="cnpj">
                  <b-form-group
                    label="FPAS"
                    label-for="fpas"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="fpas">
                        {{ this.form.dadosComplementares.fpas }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="4">
                  <b-form-group
                    label="Número de trabalhadores"
                    label-for="numero-trabalhadores"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="numero-trabalhadores">
                        {{
                          this.form.dadosComplementares.numero_de_trabalhadores
                        }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-form-group
                    label="Tipo de empresa"
                    label-for="tipo-empresa"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="tipo-empresa">
                        {{ this.form.dadosComplementares.tipo_empresa_descricao }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
                <b-col 
                  lg="4"
                  md="12"
                >
                  <b-form-group label="Empresa de Base Nacional"
                  label-for="base_nacional"
                  label-class="label_sub_titulo"
                  >
                  {{ form.dadosComplementares.base_nacional ? 'Sim' : 'Não'}}
                  </b-form-group>
                </b-col>
              </b-row>
                <b-row>
                <b-col 
                  lg="4"
                  md="12"
                >
                  <b-form-group label="Grupo"
                  label-for="grupo"
                  label-class="label_sub_titulo"
                  >
                  {{ form.dadosComplementares.descricao_grupo}}
                  </b-form-group>
                </b-col>
                <b-col 
                  lg="4"
                  md="12"
                >
                  <b-form-group label="Operador"
                  label-for="operador"
                  label-class="label_sub_titulo"
                  >
                  {{ form.dadosComplementares.descricao_operador}}
                  </b-form-group>
                </b-col>
              </b-row>
            </section>
            <section class="pt-1" v-if="cnpj">
              <b-row>
                <b-col lg="12">
                  <p class="label_titulo">Faturamento</p>
                </b-col>
                <b-col lg="4">
                  <b-form-group
                    label="CNPJ de Faturamento"
                    label-for="cnpj-faturamento"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="cnpj-faturamento" class="label_empresa">
                        {{
                          this.form.faturamento.cnpj_faturamento
                            | VMask("##.###.###/####-##")
                        }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
              </b-row>
            </section>
            <section class="pt-1">
              <p class="label_titulo">Endereços</p>
              <b-row
                v-for="endereco in form.enderecos"
                class="mb-2"
                :key="endereco.id_empresa"
              >
                <b-col>
                  <p class="label_sub_titulo">{{ endereco.descricao }}</p>
                </b-col>
                <b-col lg="4">
                  <b-form-group
                    label="CEP"
                    label-for="cep"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div
                        v-if="endereco.cep != '-'"
                        id="cep"
                        class="label_empresa"
                      >
                        {{ endereco.cep | VMask("#####-###") }}
                      </div>
                      <div v-else id="cep" class="label_empresa">
                        {{ endereco.cep }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
  
                <b-col lg="8">
                  <b-form-group
                    label="Rua/Avenida"
                    label-for="rua-avenida"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="rua-avenida" class="label_empresa">
                        {{ endereco.logradouro }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
  
                <b-col lg="4">
                  <b-form-group
                    label="Número"
                    label-for="numero"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="numero" class="label_empresa">
                        {{ endereco.numero }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
  
                <b-col lg="4">
                  <b-form-group
                    label="Ponto de Referência"
                    label-for="ponto-referencia"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="ponto-referencia" class="label_empresa">
                        {{ endereco.ponto_referencia }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
  
                <b-col lg="4">
                  <b-form-group
                    label="Bairro"
                    label-for="bairro"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="bairro" class="label_empresa">
                        {{ endereco.bairro }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
  
                <b-col lg="4">
                  <b-form-group
                    label="Cidade"
                    label-for="cidade"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="cidade" class="label_empresa">
                        {{ endereco.cidadeSelecionadaNome }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
  
                <b-col lg="8">
                  <b-form-group
                    label="Estado"
                    label-for="estado"
                    label-class="label_sub_titulo"
                  >
                    <vue-perfect-scrollbar
                      class="altura_max_input_scrollbar"
                      :settings="settings"
                    >
                      <div id="estado" class="label_empresa">
                        {{ endereco.estadoSelecionadoNome }}
                      </div>
                    </vue-perfect-scrollbar>
                  </b-form-group>
                </b-col>
              </b-row>
            </section>
            <section v-if="canEdit" class="buttons mt-3">
              <b-button
                id="redireciona-edicao"
                @click.prevent="redirectToEdit()"
                variant="custom-blue"
              >
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Editar</span>
              </b-button>
            </section>
          </main>
        </b-overlay>
      </b-card>
    </div>
  </div>
</tour>
</template>
<script>
import { actions, subjects } from "@/libs/acl/rules";
import {
  BCard,
  BTab,
  BTabs,
  BButton,
  BFormGroup,
  BCol,
  BRow,
  BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import EmpresaHeader from "@/views/components/custom/page-header/Header-empresa.vue";
import ErrorModal from "@/views/components/custom/modals/ErroIdInexistenteModal.vue";
import alertaTodasAbas from "@/views/components/custom/timed-alert/alertaAbasIncompletasFechavel.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import moment from "moment";
import { getIdEmpresa } from "@core/utils/store/getStore";
import { setIdEmpresa } from "@core/utils/store/setStore";
import tourMixin from "@/mixins/tourMixin";
import companyShowMixin from "../mixins/companyShowMixin";

export default {
  title: "Detalhes empresa",

  components: {
    BCard,
    BTab,
    BTabs,
    BButton,
    BFormGroup,
    BCol,
    BRow,
    EmpresaHeader,
    VuePerfectScrollbar,
    ErrorModal,
    alertaTodasAbas,
    BOverlay,
    PageHeader,
  },
  mixins: [tourMixin, companyShowMixin],
  data() {
    return {
      modalError: {
        showModal: false,
        caseModal: "idInexistente",
      },
      linkItems: [
        {
          name: "Empresas",
          routeName: "empresa-list",
        },
        {
          name: "Ver empresas",
          routeName: "empresa-list",
        },
        {
          name: "Carregando dados...",
          active: true,
        },
      ],
      canEdit: false,
      loadingShow: true,
      cnpj: true,
      situacaoCadastro: 'Completo',
      form: {
        dadosPrincipais: {
          cnpj: "-",
          nome_fantasia: "-",
          razao_social: "-",
          cpf: "-",
          nome: "-",
          inscricao_estadual: "-",
          data_nascimento: "-",
          genero: "-",
          nome_mae: "-",
          id_nacionalidade: "-",
          descricao_nacionalidade: "-",
        },
        dadosComplementares: {
          cnae: "-",
          fpas: "-",
          numero_de_trabalhadores: "-",
          tipo_empresa_descricao: "-",
          base_nacional: '-',
          descricao_grupo: '-',
          descricao_operador: '-',
        },
        faturamento: {
          cnpj_faturamento: "-",
        },
        enderecos: [],
      },
      empresa: {
        id_empresa: "",
        nome: "",
        gerenciarColaboradores: false,
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false,
      },
    };
  },

  mounted() {
    if(this.loadData) {
      this.mountedInit()
    }
  },

  methods: {
    mountedInit() {
      localStorage.removeItem('idEmpresaList')
    if(this.$route.params.idEmpresa) {
      localStorage.setItem('idEmpresaList', this.$route.params.idEmpresa);
      setIdEmpresa(this.$route.params.idEmpresa)
    }else {
      localStorage.setItem('idEmpresaList', getIdEmpresa());
    }

    this.$http
      .get(this.$api.getShowAndEditEmpresa(getIdEmpresa()), {
        params: {
          enderecoVisualizacao: true,
        },
      })
      .then(({ data }) => {
        this.setDadosParaVisualizar(data);
        this.linkItems[2].name = this.empresa.nome;
      });

    this.canEdit = this.verifyPermissions(actions.ATUALIZAR);
    },
    redirectToEdit() {
      this.$router.push({
        name: "empresa-edit",
        params: {
          idEmpresa: this.empresa.id_empresa,
        },
      });
    },

    openModalError() {
      this.modalError.showModal = true;
    },

    setDadosParaVisualizar(dados) {
      this.situacaoCadastro = dados['situacao_cadastro'];
      this.preenchimentoDadosPrincipais(dados);
      this.preenchimentoDadosComplementares(dados);
      this.preenchimentoFaturamento(dados);
      this.preenchimentoEndereco(dados.enderecos);
      this.loadingShow = false;
    },

    preenchimentoDadosPrincipais(dadosPrincipais) {
      this.cnpj = dadosPrincipais["cnpj"] ? true : false;
      this.form.dadosPrincipais.cnpj = dadosPrincipais["cnpj"]
        ? dadosPrincipais["cnpj"]
        : "-";
      this.form.dadosPrincipais.nome_fantasia = dadosPrincipais["nome_fantasia"]
        ? dadosPrincipais["nome_fantasia"]
        : "-";
      this.form.dadosPrincipais.razao_social = dadosPrincipais["razao_social"]
        ? dadosPrincipais["razao_social"]
        : "-";
      this.form.dadosPrincipais.cpf = dadosPrincipais["cpf"]
        ? dadosPrincipais["cpf"]
        : "-";
      this.form.dadosPrincipais.nome = dadosPrincipais["nome"]
        ? dadosPrincipais["nome"]
        : "-";
      this.form.dadosPrincipais.genero = dadosPrincipais["genero"]
        ? dadosPrincipais["genero"]
        : "-";
      this.form.dadosPrincipais.inscricao_estadual = dadosPrincipais[
        "inscricao_estadual"
      ]
        ? dadosPrincipais["inscricao_estadual"]
        : "-";
      this.form.dadosPrincipais.data_nascimento = dadosPrincipais[
        "data_nascimento"
      ]
        ? moment(dadosPrincipais["data_nascimento"]).format("DD/MM/YYYY")
        : "-";

      this.form.dadosPrincipais.nome_mae = dadosPrincipais["nome_mae"]
        ? dadosPrincipais["nome_mae"]
        : "-";
      this.form.dadosPrincipais.id_nacionalidade = dadosPrincipais["id_nacionalidade"]
        ? dadosPrincipais["id_nacionalidade"]
        : "-";
      this.form.dadosPrincipais.descricao_nacionalidade = dadosPrincipais["descricao_nacionalidade"]
        ? dadosPrincipais["descricao_nacionalidade"]
        : "-";

      this.empresa.nome = dadosPrincipais["cnpj"]
        ? dadosPrincipais["nome_fantasia"]
        : dadosPrincipais["nome"];
      this.empresa.gerenciarColaboradores =
        dadosPrincipais["pode_gerenciar_colaboradores"];
      this.empresa.id_empresa = getIdEmpresa();
    },

    preenchimentoDadosComplementares(dadosComplementares){
      this.form.dadosComplementares.cnae = dadosComplementares['cnae'] ? dadosComplementares['cnae'] : "-"
      this.form.dadosComplementares.fpas = dadosComplementares['fpas'] ? dadosComplementares['fpas'] : "-"
      this.form.dadosComplementares.base_nacional = dadosComplementares['base_nacional']
      this.form.dadosComplementares.descricao_grupo = dadosComplementares['descricao_grupo'] ? dadosComplementares['descricao_grupo'] : "-"
      this.form.dadosComplementares.descricao_operador = dadosComplementares['descricao_operador'] ? dadosComplementares['descricao_operador'] : "-"

      this.form.dadosComplementares.numero_de_trabalhadores = dadosComplementares['numero_de_trabalhadores'] ? dadosComplementares['numero_de_trabalhadores'] : "-"
      this.form.dadosComplementares.tipo_empresa_descricao = dadosComplementares['tipo_empresa_descricao'] ? dadosComplementares['tipo_empresa_descricao'] : "-"
    },

    preenchimentoFaturamento(faturamento) {
      this.form.faturamento.cnpj_faturamento = faturamento["cnpj_faturamento"]
        ? faturamento["cnpj_faturamento"]
        : "-";
    },

    preenchimentoEndereco(enderecos) {
      if (enderecos.length > 0) {
        enderecos.forEach((endereco) => {
          const enderecoObj = new Object();
          enderecoObj.descricao = endereco.descricao ? endereco.descricao : "-";
          enderecoObj.cep = endereco.cep ? endereco.cep : "-";
          enderecoObj.numero = endereco.numero ? endereco.numero : "-";
          enderecoObj.ponto_referencia = endereco.ponto_referencia
            ? endereco.ponto_referencia
            : "-";
          enderecoObj.cidadeSelecionadaNome = endereco.cidadeNome
            ? endereco.cidadeNome
            : "-";
          enderecoObj.estadoSelecionadoNome = endereco.estadoUF
            ? endereco.estadoUF
            : "-";
          enderecoObj.logradouro = endereco.logradouro
            ? endereco.logradouro
            : "-";
          enderecoObj.bairro = endereco.bairro ? endereco.bairro : "-";
          this.form.enderecos.push(enderecoObj);
        });
      }
    },

    redirecionarLista() {
      this.$router.push({ name: "empresa-list" });
    },

    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa.id_empresa,
          },
        });
      }
    },

    verifyPermissions(action) {
      const permissionUO = this.$can(actions[action], subjects.EMPRESAS_UO);
      const permissionDR = this.$can(actions[action], subjects.EMPRESAS_DR);
      const permissionAR = this.$can(actions[action], subjects.EMPRESAS_AR);

      return [permissionUO, permissionDR, permissionAR].includes(true);
    },
  },
};
</script>
<style>
.label_titulo {
  font-size: 18px;
  font-weight: 700;
}
.label_sub_titulo {
  font-size: 14px;
  font-weight: 600;
}
.label_empresa {
  font-size: 14px;
}
.altura_max_input_scrollbar {
  max-height: 32px;
  word-break: break-all;
  padding-right: 15px;
}
@media (max-width: 767px) {
  .centralizar_responsivo {
    text-align: center;
    justify-content: center;
  }
}
</style>
