<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <b-avatar rounded size="42" variant="custom-blue" class="mr-1">
          <feather-icon icon="BookOpenIcon" size="20" />
        </b-avatar>
        <div>
          <h4 class="mb-0">
            {{ title }}
          </h4>
        </div>
      </div>
     
      <b-button
            @click="startTopic"
            variant="primary"
            size="sm"
            class="mr-2"

          >
            Assistir
            <feather-icon
              icon="PlayIcon"
            />
        </b-button>
    </div>

  <b-list-group class="mt-2">
    <b-list-group-item 
      v-for="(tutorial, index) in tutorials"
      :key="index"
      class="d-flex justify-content-between align-items-center"
    >

    <span class="font-weight-bold">{{ tutorial.title }}</span>

    <b-button
            variant="primary"
            size="sm"
            @click="() => startTutorial(tutorial)"
          >
            Assistir
            <feather-icon
              icon="PlayIcon"
            />
        </b-button>
    </b-list-group-item>
  </b-list-group>

    <!--/ collapse -->
  </div>
</template>

<script>
import { BAvatar, BLink, BBadge, BListGroup, BListGroupItem, BButton } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { setTourMode, setTourSteps, setTourWatchingAllTutorials, setTourWatchingTopic } from "@/@core/utils/store/setStore";

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
    BLink,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton
  },
  props: {
    tutorials: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    }
  },
  methods: {
    startTutorial(tutorial, withoutContinuity = true) {
      const steps = withoutContinuity ? this.buildStepsWithoutContinuity(tutorial.steps) : tutorial.steps;

      steps[0].prevAction = false;

      setTourMode(true);
      setTourSteps(steps);

      this.$router.push({
          name: tutorial.redirectTo, 
      });
    },
    buildStepsWithoutContinuity(steps) {
      const stepsWithoutContinuity = [];

      steps.forEach((step, index) => {
          if (steps.length - 1 === index) {
            stepsWithoutContinuity.push(
              {
                ...step,
                actionOnSequence: () => {
                    this.$router.push({
                        name: 'pages-faq', 
                    });                   
                }
              }
            )
          } else if(index == 0) {
            const stepClone = {...step}
            delete stepClone.actionOnPrevStep;
            
            stepsWithoutContinuity.push(stepClone);
          } else {
            stepsWithoutContinuity.push(step);
          }
      })

      return stepsWithoutContinuity;
    },
    startTopic() {
      setTourWatchingTopic(true);
      setTourWatchingAllTutorials(true);
      this.startTutorial(this.tutorials[0], false);
    }
  },
};
</script>

<style scoped>
</style>