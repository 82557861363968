<template>
    <div class="content-wrapper">
        <ConfirmModal 
            itemIdentifier="A nota fiscal"
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="modal.confirmed"
            @canceled="modal.canceled"
        />

        <SucessoModal
            :modalShow="modalSucesso.showModal"
            :typeModal="modalSucesso.typeModal"
            :caseModal="modalSucesso.caseModal"
            @confirmed="redirectToList"
        />

        <AddItemModal
        :showModal="modalItem.showModal"
        :itemEdit="modalItem.item"
        :cleanModal="cleanModal"
        :vacinaSelect="vacinaSelect"
        :frascoSelect="frascoSelect"
        :loteSelect="loteSelect"
        :lotes="table.items"
        @confirmed="addItemToList"
        @canceled="openModalCancelItem"
        />

        <PageHeader screenName="Nova entrada" :linkItems="linkItems"/>
        <main class="card">
            <div class="pt-1 px-2">
                <b-alert class="p-1" variant="danger" :show="form.errorApi.error ? true : false">
                    {{form.errorApi.error}}
                </b-alert>
            </div>
            <validation-observer ref="entradaRules">
                <b-form>
                    <b-row class="pt-1 px-2">
                        <b-col lg="4" md="6" sm="12">
                             <b-form-group 
                             label="Escolha a Campanha"
                             label-for="campanha-input"
                             label-class="font_size_label_estoque"
                             >
                             <validation-provider
                                #default="{ errors }"
                                name="campanha"
                                rules="required"
                                >
                                 <v-select 
                                 id="campanha-input"
                                 v-model="form.campanha"
                                 :options="campanhaSelect"
                                 label="campanha_descricao"
                                 placeholder="Selecione uma campanha"
                                 :clearable="true"
                                 @input="searchVacinas"
                                 >
                                 <span slot="no-options">Nenhuma opção selecionável.</span>
                                 </v-select>
                                    <small v-if="errors[0]" class="text-danger">
                                            Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.campanha" class="text-danger">
                                        {{ form.errorApi.campanha }}
                                    </small>
                                </validation-provider>
                             </b-form-group>
                        </b-col>
                        <b-col lg="4" md="6" sm="12">
                             <b-form-group 
                             label="Escolha a Unidade Operacional"
                             label-for="unidade-operacional-input"
                             label-class="font_size_label_estoque"
                             >
                             <validation-provider
                                #default="{ errors }"
                                name="unidade"
                                rules="required"
                                >
                                 <v-select 
                                 id="unidade-operacional-input"
                                 v-model="form.unidade"
                                 :options="unidadeSelect"
                                 label="descricao_unidade"
                                 placeholder="Selecione uma unidade"
                                 :clearable="true"
                                 :disabled="disabled.unit"
                                 >
                                 <span slot="no-options">Nenhuma opção selecionável.</span>
                                 </v-select>
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                <small v-if="form.errorApi.unidade" class="text-danger">
                                    {{ form.errorApi.unidade }}
                                </small>
                                </validation-provider>
                             </b-form-group>
                        </b-col>
                     </b-row>
                     <b-row class="px-2">    
                        <b-col lg="3" md="6" sm="12">
                             <b-form-group 
                             label="Número da nota fiscal*"
                             label-for="nota-fiscal-input"
                             label-class="font_size_label_estoque">
                             <validation-provider
                                #default="{ errors }"
                                name="nota_fiscal"
                                rules="required"
                                >
                                 <b-form-input
                                     v-model="form.nota_fiscal"
                                     id="nota-fiscal-input"
                                     placeholder="Digite o número da nota fiscal"
                                     v-mask="('N'.repeat(15))"
                                     @input="() =>{
                                        form.nota_fiscal = form.nota_fiscal.toUpperCase()
                                     }"
                                 />
                                 <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.nota_fiscal" class="text-danger">
                                        {{ form.errorApi.nota_fiscal }}
                                    </small>
                                </validation-provider>
                             </b-form-group>
                        </b-col>
                        <b-col lg="3" md="6" sm="12">
                             <b-form-group 
                                 label="Emissão da nota*"
                                 label-for="emissao-input"
                                 label-class="font_size_label_estoque"
                             >
                             <validation-provider
                                #default="{ errors }"
                                name="emissao"
                                rules="required"
                                >
                                 <b-input-group>
                                     <b-form-input
                                         class="form-control"
                                         id="emissao-input"
                                         v-mask="'##/##/####'"
                                         v-model="input.emissao"
                                         placeholder="00/00/0000"
                                         autocomplete="off"
                                         @input="setEmissaoInput"
                                     />
                                     <b-input-group-append>
                                         <b-form-datepicker
                                             class="form-control d-flex align-items-center"
                                             locale="pt-BR"
                                             v-model="form.emissao"
                                             button-only
                                             right
                                             :hide-header="true"
                                             label-help="Selecione a data de emissão"
                                             button-variant="custom-blue"
                                             clearable
                                             @input="setEmissaoPicker"
                                         />
                                     </b-input-group-append>
                                 </b-input-group>
                                 <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                <small v-if="form.errorApi.emissao" class="text-danger">
                                        {{ form.errorApi.emissao }}
                                    </small>
                                </validation-provider>
                             </b-form-group>
                        </b-col>
                        <b-col lg="3" md="6" sm="12">
                             <b-form-group 
                             label="CNPJ do Fornecedor*"
                             label-for="fornecedor-input"
                             label-class="font_size_label_estoque"
                             >
                             <validation-provider
                                #default="{ errors }"
                                name="cnpj"
                                rules="required"
                                >
                                 <b-form-input
                                     v-model="form.cnpj"
                                     id="fornecedor-input"
                                     placeholder="00.000.000-0000/00"
                                     v-mask="'##.###.###/####-##'"
                                     @input="searchFornecedor"
                                 />
                                 <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                    </small>
                                    <small v-if="form.errorApi.cnpj" class="text-danger">
                                        {{ form.errorApi.cnpj }}
                                    </small>
                                </validation-provider>
                             </b-form-group>
                        </b-col>
                        <b-col lg="3" md="6" sm="12">
                             <b-form-group 
                             label="Razão Social do Fornecedor*"
                             label-for="fornecedor-input"
                             label-class="font_size_label_estoque"
                             >
                             <validation-provider
                                #default="{ errors }"
                                name="razao_social"
                                rules="required"
                                >
                                 <b-form-input
                                     v-model="form.razao_social"
                                     id="fornecedor-input"
                                     placeholder="Digite a Razão Social"
                                     :disabled="disabled.empresa"
                                 />
                                <small v-if="errors[0]" class="text-danger">
                                        Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errorApi.razao_social" class="text-danger">
                                    {{ form.errorApi.razao_social }}
                                </small>
                                </validation-provider>
                             </b-form-group>
                        </b-col>
                        </b-row>
                        <b-row class="px-2">
                         <b-col lg="8" md="8" sm="12">
                             <b-form-group 
                             label="Observações"
                             label-class="font_size_label_estoque"
                             >
                                <validation-provider
                                #default="{ errors }"
                                name="observacao"
                                >
                                     <b-form-textarea
                                         id="observacao-input"
                                         v-model="form.observacao"
                                         :state="form.observacao.length <= 500 || form.observacao.length == 0 ? null : false"
                                         rows="3"
                                         class="p-2"
                                         placeholder="Observações..."
                                        @keydown="onKeyDown"
                                         no-resize
                                     />
                                <small v-if="errors[0]" class="text-danger">
                                    Este campo é de preenchimento obrigatório.
                                </small>
                                <small v-if="form.errorApi.observacao" class="text-danger">
                                    {{ form.errorApi.observacao }}
                                </small>
                                </validation-provider>
                             </b-form-group>
                         </b-col>
                        </b-row>
                </b-form>
            </validation-observer>
            <div>    
                <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                </div>
                <section class="list-items-entrada" v-if="!loadingData">
                    <b-table
                        id="table-items-entrada"
                        responsive
                        :stickyColumn="true"
                        :busy.sync="filtering"
                        :fields="table.fields"
                        :items="table.items"
                        no-local-sorting
                        :no-sort-reset="true"
                        thead-th-class="bg-custom-blue"
                    >
                        <template #cell(vacina)="row">
                            <span class=" comprimento_maximo_celulas font-weight-bold"
                            v-b-tooltip.hover.righttop="{ variant: 'light' }"
                            :title="row.item.vacina.nome_tecnico"
                            >
                                {{ row.item.vacina.nome_tecnico }}
                            </span>
                        </template>
                        <template #cell(fabricante)="row">
                            <span class="font-weight-bold">
                                {{ row.item.fabricante }}
                            </span>
                        </template>
                        <template #cell(lote)="row">
                            <span class="font-weight-bold">
                                {{ row.item.lote }}
                            </span>
                        </template>
                        <template #cell(dose)="row">
                            <span class="custom rounded-pill font-weight-bold" :class="row.item.frasco.quantidade == 1 ? 'mono' : 'multi'">
                                {{ row.item.frasco.descricao }}
                            </span>
                        </template>
                        <template #cell(validade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ formatDate(row.item.validade, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(quantidade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ row.item.quantidade_conversao }}
                            </span>
                        </template>
                        <template #cell(valor_unitario)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                R${{ formatDotDecimal(row.item.valor_unitario_conversao) }}
                            </span>
                        </template>
                        <template #cell(valor_total)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                R${{ formatDotDecimal(row.item.valor_total) }}
                            </span>
                        </template>
                        <template #cell(actions)="row">
                            <feather-icon
                                id="entrada-show"
                                role="button"
                                class="text-custom-blue mr-1"
                                icon="EditIcon"
                                size="18"
                                @click.prevent="openModalAddItem(row.item, row.index)"
                            />
                            <feather-icon
                                id="entrada-show"
                                role="button"
                                class="text-custom-blue"
                                icon="Trash2Icon"
                                size="18"
                                @click.prevent="removeItemOfList(row.index)"
                            />
                        </template>
                    </b-table>
                    <div class="px-1">
                    <span v-if="!table.items.length" class="ml-1">Nenhum item inserido<br></span>
                    <small v-if="form.errorApi.lotes" class="text-danger pl-1">
                        {{ form.errorApi.lotes }}
                    <br></small>
                    <small v-if="form.errorApi.fabricante.length" class="text-danger pl-1 d-flex flex-column">
                        <span 
                        v-for="(errorValidate, index) in form.errorApi.fabricante"
                        :key="index"
                        >
                        {{ errorValidate }}
                        </span>
                    </small>
                    <small v-if="form.errorApi.validade.length" class="text-danger pl-1 d-flex flex-column">
                        <span 
                        v-for="(errorValidate, index) in form.errorApi.validade"
                        :key="index"
                        >
                        {{ errorValidate }}
                        </span>
                    </small>
                    <small v-if="form.errorApi.quantidade.length" class="text-danger pl-1 d-flex flex-column">
                        <span 
                        v-for="(errorValidate, index) in form.errorApi.quantidade"
                        :key="index"
                        >
                        {{ errorValidate }}
                        </span>
                    </small>
                    <small v-if="form.errorApi.valor_unitario.length" class="text-danger pl-1 d-flex flex-column">
                        <span 
                        v-for="(errorValidate, index) in form.errorApi.valor_unitario"
                        :key="index"
                        >
                        {{ errorValidate }}
                        </span>
                    </small>
                    </div>
                    <div class="pb-2 px-1">
                        <b-row class="p-2">
                            <b-col lg="4" md="6" sm="7">
                                <b-button 
                                @click.prevent="openModalItem()" 
                                variant="outline-custom-blue"
                              
                                >
                                <feather-icon
                                    icon="PlusIcon"
                                    class="mr-50"  
                                />
                                <span class="align-middle">Selecionar Lote</span>
                                </b-button>
                            </b-col>
                            <b-col lg="8" md="6" sm="7" class="border-top d-flex justify-content-end">
                                <div class="mr-2 text-center">
                                    <p class="mb-0 mt-1">
                                        Qt. Doses
                                    </p>
                                    <span class="font-weight-bold mb-0">
                                        {{ info.quantidade }}
                                    </span>
                                </div>
                                <div class="text-center">
                                    <p class="mb-0 mt-1">
                                        Valor Total R$
                                    </p>
                                    <span class="font-weight-bold mb-0">
                                        R${{ formatDotDecimal((info.valor_total).toFixed(2)) }}
                                    </span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </section>
                <section class="m-2">
                <b-button id="save-entrada" 
                    class="mr-2 mb-1 mb-sm-0" 
                    variant="custom-blue"
                    @click.prevent="openModalConfirm"
                    :disabled="saving"
                >
                    <feather-icon icon="CheckIcon" class="mr-50"/>
                    <span class="align-middle">Cadastrar nota fiscal</span>
                    <span v-if="saving" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
                </b-button>
                <b-button id="cancel-entrada" 
                    :disabled="saving"
                    variant="outline-danger"
                    @click.prevent="openModalCancel"
                >
                    <feather-icon icon="XIcon" class="mr-50"/>
                    <span class="align-middle">Descartar</span>
                </b-button>
            </section>
                
            </div>
        </main>
    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, 
BButton, BTable, VBTooltip, BSpinner, BCard, BFormTextarea,
BFormDatepicker, BInputGroup, BInputGroupAppend, BAlert } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import AddItemModal from '@/views/components/custom/modals/estoque/Add-novo-item-entrada.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ConfirmModal from '@/views/components/custom/modals/estoque/ConfirmModalEstoque.vue'
import handleErrorsService from './Services/HandleErrors'
import {
    errorMessageAction
} from '@/libs/sweetalerts'

export default {
    title: 'Cadastro de entrada',
    
    components: {
        BForm, BCol, BRow, BFormGroup, BFormInput,
        BButton, BTable, TableSwitch, BCard,
        vSelect, CustomPagination, PageHeader,
         VuePerfectScrollbar, BSpinner,
         BFormDatepicker, BInputGroup, BInputGroupAppend, InfoCard, BFormTextarea,
         AddItemModal, ValidationProvider, ValidationObserver,
         SucessoModal, ConfirmModal, BAlert
    },

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            linkItems: [
                {
                    name: 'Estoque',
                    routeName: 'estoque-list'
                },
                {
                    name: 'Entrada de vacinas',
                   routeName: 'entrada-list'
                },
                {
                    name: 'Nova entrada',
                    active: true
                }
            ],
            modalItem: {
            showModal: false,
            item: null
            },
            moneyMask: {
                decimal: ',',
                thousands: '.',
                precision: 2,
            },
            modal: {
                showModal: false,
                typeModal: 'danger',
                confirmed: this.redirectToList,
                canceled: this.closeModalConfirm
            },
            modalSucesso: {
                showModal: false,
                typeModal: 'success',
                caseModal: 'criacao'
            },
            cleanModal: false,
            alert: {
                errorMessage: '',
                show: false
            },
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            timeID: null,
            filtering: false,
            loadingData: false,
            without: false,
            searchTerm: '',
            table: {
                fields: [
                    {key: "vacina", label: "VACINA", sortable: false, class: 'mw-150'},
                    {key: "fabricante", label: "FABRICANTE", sortable: false, class: 'mw-100'},
                    {key: "lote", label: "LOTE", sortable: false, class: 'mw-100'},
                    {key: "dose", label: "DOSE", sortable: false, class: 'mw-100'},
                    {key: "validade", label: "VALIDADE", sortable: false, class: 'mw-100'},
                    {key: "quantidade", label: "QT", sortable: false, class: 'mw-100'},
                    {key: "valor_unitario", label: "VALOR UN.", sortable: false, class: 'mw-100'},
                    {key: "valor_total", label: "VALOR TOTAL", sortable: false, class: 'mw-100'},
                    {key: "actions", label: "AÇÕES", sortable: false, class: 'mw-150 text-center col-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'lote',
                order: 'desc'
            },
            form: {
                campanha: '',
                unidade: '',
                nota_fiscal: '',
                emissao: '',
                cnpj: '',
                razao_social: '',
                observacao: '',
                errorApi: {
                    campanha: '',
                    unidade: '',
                    nota_fiscal: '',
                    emissao: '',
                    cnpj: '',
                    razao_social: '',
                    observacao: '',
                    lotes: '',
                    quantidade: [],
                    validade: [],
                    valor_unitario: [],
                    fabricante: [],
                },
            },
            input: {
                emissao: '',
            },
            info: {
                quantidade: 0,
                valor_total: 0,
            },
            disabled: {
                empresa: false,
                unit: true
            },
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            campanhaSelect: [],
            vacinaSelect: [],
            loteSelect: [],
            frascoSelect: [],
            unidadeSelect: [],
            saving: false,
            tamanho: 0
        }
    },
    mounted() {
        this.loadAllInfo();
    },

    methods: {
        validationForm() {
            this.modal.showModal = false;
            this.$refs.entradaRules.validate().then(success => {
                if (success) {
                    this.saving = true;
                    this.saveInfo();
                }
            })
        },
        saveInfo(){
            const parameters = this.prepareDataToSave();

            this.$http.post(this.$api.notaFiscal(), parameters)
            .then(() => {
                this.openModalSucesso();

                this.cleanForm();
                this.saving = false;
            }).catch(({ response: { data } }) => {
                handleErrorsService.handleErrosEntrada(data, this.form.errorApi, this.table.items.length);
                this.saving = false;
            })
        },
        prepareDataToSave(){
            let lotes = [];

            this.table.items.map(lote => {
                lotes.push({
                    numero_lote: lote.lote,
                    id_vacina: lote.vacina.id_vacina,
                    fabricante: lote.fabricante,
                    id_frasco: lote.frasco.id_frasco,
                    fabricacao: moment().format('YYYY-MM-DD'),
                    validade: lote.validade,
                    quantidade: lote.quantidade,
                    codigo_externo: lote.codigo_externo,
                    valor_unitario: lote.valor_unitario,
                    observacao: lote.observacao
                });
            })

            return {
                id_campanha: this.form.campanha.id_campanha,
                id_unidade: this.form.unidade.id_unidade,
                nota_fiscal: this.form.nota_fiscal,
                emissao: this.form.emissao,
                cnpj: this.form.cnpj,
                razao_social: this.form.razao_social,
                nome_fantasia: this.form.razao_social,
                observacao: this.form.observacao,
                lotes: lotes
            };
        },
        async loadAllInfo(){
            const parameters = {
                ativo: true
            }
            await this.$http.get(this.$api.campanha(), { params: {situacao:["Em andamento"]} }).then(({ data }) => {
                if(data.length > 0){
                    this.campanhaSelect = data.sort((a, b) => a.campanha_descricao.localeCompare(b.campanha_descricao));
                }
            });
            await this.$http.get(this.$api.frasco(), { params: parameters }).then(({ data }) => {
                this.frascoSelect = data;
            });

            await this.$http.get(this.$api.listarLote(), { params: parameters }).then(({ data }) => {
                this.tamanho = data.total;
            });
        },
        async searchVacinas(){
            if(!this.form.campanha){
                this.vacinaSelect = [];
                this.unidadeSelect = [];
                this.form.unidade = null;
                this.disabled.unit = true;
                return;
            }
            
            await this.$http.get(this.$api.unidadeCampanhaParameter(this.form.campanha.id_campanha)).then(({ data }) => {
                this.unidadeSelect = data.sort((a, b) => a.descricao_unidade.localeCompare(b.descricao_unidade));
                if(this.unidadeSelect.length == 1){
                    this.form.unidade = this.unidadeSelect[0];
                }
                this.disabled.unit = false;
            });
            await this.$http.get(this.$api.getShowAndEditCampanha(this.form.campanha.id_campanha)).then(({ data }) => {
                this.vacinaSelect = data.vacina;
            });

        },

        async loadLote(){
            const loteParameters = {
                ativo: true,
                tamanho: this.tamanho
            }

            await this.$http.get(this.$api.listarLote(), { params: loteParameters }).then(({ data }) => {
                this.loteSelect = data.data;
            });
        },
            
        addItemToList(item) {
            if(this.table.items[item.index]){
                this.table.items[item.index].vacina = item.vacina;
                this.table.items[item.index].frasco = item.frasco;
                this.table.items[item.index].fabricante = item.fabricante;
                this.table.items[item.index].lote = item.lote;
                this.table.items[item.index].quantidade = item.quantidade;
                this.table.items[item.index].quantidade_conversao = item.quantidade_conversao;
                this.table.items[item.index].validade = item.validade;
                this.table.items[item.index].valor_total = item.valor_total;
                this.table.items[item.index].valor_unitario = item.valor_unitario;
                this.table.items[item.index].valor_unitario_conversao = item.valor_unitario_conversao;
                this.table.items[item.index].codigo_externo = item.codigo_externo;
                this.table.items[item.index].observacao = item.observacao;
            }else{
                this.table.items.push(item);
            }
            this.updateInfoValues();
            this.modalItem.showModal = false;
            this.modalItem.item = null;
        },
        removeItemOfList(index){
            this.loadingData = true;
            this.form.errorApi.quantidade.splice(index, 1)
            this.form.errorApi.validade.splice(index, 1)
            this.form.errorApi.valor_unitario.splice(index, 1)
            this.table.items.splice(index, 1);
            this.updateInfoValues()
            setTimeout(() => this.loadingData = false, 500)
        },
        updateInfoValues(){
            let valorTotal = 0, quantidadeTotal = 0;
            this.table.items.map(lote => {
                valorTotal += parseFloat(lote.valor_total);
                quantidadeTotal += parseFloat(lote.quantidade_conversao);
            })

            this.info.valor_total = valorTotal;
            this.info.quantidade = quantidadeTotal;
        },
        searchFornecedor(){
            if(this.form.cnpj.length < 18){
                this.disabled.empresa = false;
                return;
            }

            this.$http.get(this.$api.getEmpresaFornecedorByCnpj(this.form.cnpj.replace(/\D/g, "")))
            .then(({ data }) => {
                if(data.razao_social != undefined) {
                    const empresa = data;
                    this.form.razao_social = empresa.razao_social;
                    this.disabled.empresa = true;
                }else{
                    this.disabled.empresa = false;
                }
            });
        },
        onKeyDown(evt){
        if (this.form.observacao.length >= 500) {
            this.form.observacao = this.form.observacao.substring(0,500);
            if (evt.keyCode >= 48 && evt.keyCode <= 90) {
            evt.preventDefault()
            return
            }
        }
        },
        formatDate(string, format){
            return moment(string).format(format);
        },
        redirectToList() {
           this.$router.push({name: 'entrada-list'});
       },
       openModalAddItem(item, index) {
        if(item){
            item.index = index;
            this.modalItem.item = item;
        }
        this.modalItem.showModal = true;
       },
       openModalItem() {
        this.loadLote();
        this.modalItem.showModal = true;
        this.cleanModal = false;
       },
       closeModalAddItem(){
        this.modalItem.showModal = false;
        this.modal.showModal = false;
        this.modalItem.item = null;
        this.cleanModal = true;
       },
       closeModalCancelItem(){
            this.modalItem.showModal = true;
            this.modal.showModal = false;
       },
       openModalCancel() {
            this.modal.showModal = true;
            this.modal.typeModal = 'danger';
            this.modal.confirmed = this.redirectToList;
            this.modal.canceled = this.closeModalConfirm;
        },
        openModalCancelItem() {
            if(!this.modalItem.item){
                this.modalItem.showModal = false;
                this.modal.showModal = true;
                this.modal.typeModal = 'warning';
            }else{
                this.modalItem.showModal = false;
                this.modalItem.item = null;
            }
            this.modal.confirmed = this.closeModalAddItem;
            this.modal.canceled = this.closeModalCancelItem;
        },
        openModalConfirm() {
            if(this.form.nota_fiscal && this.form.cnpj){
            this.$http.get(this.$api.notaFiscal(), {
                params: {
                    nota_fiscal: this.form.nota_fiscal,
                    cnpj: this.form.cnpj
                }
            }).then(({data}) => {
                if(!data.data.length){
                    this.modal.showModal = true;
                    this.modal.typeModal = 'success';
                    this.modal.confirmed = this.validationForm;
                    this.modal.canceled = this.closeModalConfirm;
                }else{
                    let message = 'Já existe uma nota fiscal de mesma numeração lançada para este fornecedor nesta unidade operacional. Após cadastrada, ela não poderá ser editada. Tem certeza que deseja continuar?'
                    errorMessageAction('Esta nota fiscal já está cadastrada', message, 'Sim, cadastrar').then(() => {
                        return this.validationForm()
                    })
                }
            });
            }else{
                this.modal.showModal = true;
                this.modal.typeModal = 'success';
                this.modal.confirmed = this.validationForm;
                this.modal.canceled = this.closeModalConfirm;
            }
        },
        closeModalConfirm() {
            this.modal.showModal = false;
        },
        setEmissaoPicker() {
            this.input.emissao = moment(this.form.emissao).format('DD/MM/YYYY');
        },
        setEmissaoInput() {
            this.form.emissao = moment(this.input.emissao, 'DD/MM/YYYY').format('YYYY-MM-DD');
        },
        openModalSucesso() {
            this.modalSucesso.showModal = true;
        },
        formatDotDecimal(value){
            let string = value.toString();
            return string.replace('.', ',')
        }
    }
    
}
</script>

<style>
.font_size_label_estoque{
    font-size: 15px;
    margin-top: -5px;
}
.custom{
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.mono{
    background-color: #f3eeff;
    color: #8c65e2;
}
.multi{
    background-color: #e3f6f4;
    color: #21a696;
}

.altura_max_scrollbar{
    max-height:100px;
    padding-right: 15px;
}
.comprimento_maximo_celulas{
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
#table-items-entrada thead th {
    border-bottom: 2px solid #2772c0!important;
    background-color: #2772c0!important;
    color: white;
}
</style>