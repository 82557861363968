<!-- =========================================================================================
    File Name: VxTour.vue
    Description: Tour Component
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <v-tour
    name="vuexyTour"
    :steps="steps"
    :callbacks="callbacks"
  >
    <template slot-scope="tour">
      <transition name="fade">
        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
        <v-step
          v-for="(step, index) of tour.steps"
          v-if="tour.currentStep === index"
          :key="index"
          :step="step"
          :previous-step="tour.previousStep"
          :next-step="tour.nextStep"
          :stop="tour.stop"
          :is-first="tour.isFirst"
          :is-last="tour.isLast"
          :labels="tour.labels"
          :highlight="step.highlight"
        >
          <div
            slot="actions"
            class="tour-actions d-flex justify-content-between"
          >
            <span             
              v-if="tour.currentStep && !hasPrevAction(tour.currentStep)"    
              @click="tour.previousStep"
              class="previous cursor-pointer"
              >
              <feather-icon
                icon="ChevronLeftIcon"
                size="16"
              />
            </span>

            <span             
              v-if="hasPrevAction(tour.currentStep)"    
              @click="() => actionOnPrevStep(tour.currentStep)"
              class="previous cursor-pointer"
              >
              <feather-icon
                icon="ChevronLeftIcon"
                size="16"
              />
            </span>

             <!-- Skip Button -->
             <b-button
              size="sm"
              variant="outline-primary"
              class="btn-tour btn-tour-skip mr-1"
              @click="stopAndRedirect"
            >
              <span class="mr-25 align-middle">Ver Depois</span>
            </b-button>

            <span
              class="step-indicator"
            >
              {{ getCurrentStepIndicator(tour.currentStep) }} / {{ getlastStepIndicator(tour.steps.length) }}
            </span>

            <!-- Next Button -->
            <b-button
              v-if="tour.currentStep != tour.steps.length - 1"
              size="sm"
              variant="primary"
              class="btn-tour btn-tour-next"
              :disabled="disabledNextBtn"
              @click="tour.nextStep"
            >
              <span class="mr-25 align-middle">Próximo</span>
            </b-button>


            <!-- Finish Button -->
            <b-button
              v-if="tour.currentStep == tour.steps.length - 1"
              size="sm"
              variant="primary"
              class="btn-tour btn-tour-finish"
              :disabled="disabledFinishBtn"
              @click="stop(tour.currentStep)"
            >
              <span class="mr-25 align-middle">{{ getLabelBtnFinish() }}</span>
            </b-button>
          </div>

        </v-step>
      </transition>
    </template>
  </v-tour>
</template>

<script>
import { getTourWatchingAllTutorials } from '@/@core/utils/store/getStore';
import { setTourMode, setTourSteps, setTourWatchingAllTutorials, setTourWatchingTopic } from '@/@core/utils/store/setStore';
import { BButton } from 'bootstrap-vue'
import { updateStatusTutorial } from "../functions/UpdateStatusFunctions";

export default {
  name: 'VxTour',
  components: {
    BButton,
  },
  props: {
    steps: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      callbacks: {
        onPreviousStep: this.prevStep,
        onNextStep: this.nextStep
      },
      disabledFinishBtn: true,
      disabledNextBtn: true,
    }
  },
  mounted() {
    this.enableButtons();
  },
  methods: {
    getCurrentStepIndicator(currentStep) {
      const currentStepIndicator = currentStep + 1;
      return currentStepIndicator.toString().padStart(2, '0');
    },
    getlastStepIndicator(lastStep) {
      return lastStep.toString().padStart(2, '0');
    },
    getLabelBtnFinish() {
      return getTourWatchingAllTutorials() ? 'Próximo' : 'Finalizar';
    },
    stop(currentStep) {
      this.$tours['vuexyTour'].stop();
      setTourMode(false);
      setTourSteps([]);
      this.actionOnSequence(currentStep);
    },
    stopAndRedirect() {
      this.$tours['vuexyTour'].stop();
      setTourMode(false);
      setTourSteps([]);
      setTourWatchingTopic(false);
      setTourWatchingAllTutorials(false);
      updateStatusTutorial('Pulou');
      this.redirectToHomeOrRegister();
    },
    nextStep(currentStep) {
      this.disableButtons();
      this.enableButtons();
      this.actionOnSequence(currentStep);
      this.$emit('nextStep');
    },
    prevStep() {
      this.disableButtons();
      this.enableButtons();
      this.$emit('prevStep');
    },
    actionOnSequence(currentStep) {
      if (this.$props.steps[currentStep].actionOnSequence) {
        this.$props.steps[currentStep].actionOnSequence();
      }
    },
    actionOnPrevStep(currentStep) {      
      if (this.$props.steps[currentStep].actionOnPrevStep) {
        this.$props.steps[currentStep].actionOnPrevStep();
      }
    },
    canActionPrevStep(currentStep) {
      const step = this.$props.steps[currentStep];
      return !('prevAction' in step) || step.prevAction;
    },
    hasPrevAction(currentStep) {
      return ('actionOnPrevStep' in this.$props.steps[currentStep]);
    },
    disableButtons() {
      this.disabledFinishBtn = true;
      this.disabledNextBtn = true;
    },
    enableButtons() {
      setTimeout(() => {
        this.disabledFinishBtn = false;
        this.disabledNextBtn = false;
      }, 500);
    },
    redirectToHomeOrRegister() {
        if(this.$route.name != 'home') {
          this.$router.push({ name: 'home' })
        }else{
          this.$router.push({ name: 'empresa-register' })
        }
    },
  }
}
</script>

<style scoped>
.previous {
    border-radius: 100%;
    background: #f0f0f0 !important;
    border-color: #f0f0f0 !important;
    color: #A2A5AD !important;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.step-indicator {
  color: #A2A5AD !important;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.btn-tour {
  font-weight: bold;
}

.v-tour .v-step {
    min-width: 400px !important;
}
</style>