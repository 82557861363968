<template>
  <div class="content-wrapper">
    <PageHeader screenName="Estoque físico" :linkItems="linkItems"/>
    <main class="card hidden_overflow_tabela">
      <b-row class="pt-2 px-2">
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label="Unidade Operacional"
              label-for="unidade-operacional-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="unidade-operacional-input"
                v-model="search.unidade"
                :options="unidadeSelect"
                label="descricao_unidade"
                placeholder="Selecione uma unidade"
                :clearable="true"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label="Data de validade"
              label-for="validade-input"
              label-class="font_size_label_estoque"

          >
            <b-input-group>
              <b-form-input
                  class="form-control"
                  id="validade-input"
                  v-model="input.validade"
                  v-mask="'##/##/####'"
                  placeholder="00/00/0000"
                  autocomplete="off"
                  @input="setInput"
              />
              <b-input-group-append>
                <b-form-datepicker
                    class="form-control d-flex align-items-center"
                    locale="pt-BR"
                    v-model="search.validade"
                    button-only
                    right
                    :hide-header="true"
                    label-help="Selecione a data de validade"
                    button-variant="custom-blue"
                    clearable
                    @input="setPicker"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label="Doença"
              label-for="doenca-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="doenca-input"
                v-model="search.doenca"
                :options="doencaSelect"
                label="nome_tecnico"
                placeholder="Selecione uma doença"
                :clearable="true"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label="Vacina"
              label-for="vacina-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="vacina-input"
                v-model="search.vacina"
                :options="vacinaSelect"
                label="nome_tecnico_vacina"
                placeholder="Selecione uma vacina"
                :clearable="true"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label="Fabricante"
              label-for="fabricante-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="fabricante-input"
                v-model="search.fabricante"
                :options="fabricanteSelect"
                label="descricao"
                placeholder="Selecione um fabricante"
                :clearable="true"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <b-form-group
              label="Lote"
              label-for="lote-input"
              label-class="font_size_label_estoque"
          >
            <b-form-input
                v-model="search.lote"
                id="lote-input"
                placeholder="N° do lote"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-button @click="() => {
                        filterData();
                        loadDataCard();
                    }" variant="primary-button">
          <feather-icon
              icon="SearchIcon"
              class="mr-50"
          />
          <span class="align-middle">Pesquisar</span>
        </b-button>

        <b-button type="reset" class="ml-2 cor_botao" @click="clearSearch" variant="outline-primary">
          <feather-icon
              icon="XIcon"
              class="mr-50"
          />
          <span class="align-middle">Limpar</span>
        </b-button>
      </b-row>
      <b-row class="mx-2 mt-2 d-flex align-items-center justify-content-center">
        <b-col
            xl="5"
            lg="6"
            md="8"
            sm="12"
        >
          <InfograficoEstoqueCard
              :data="info.geral"
              :vaccines="this.info.geral.vaccines"
              :quantity="this.info.geral.series"
              :disease="this.info.geral.disease"
          />
        </b-col>
        <b-col
            lg="6"
            md="11"
            sm="12"
        >
          <InfoCard :data="info.doses"/>
        </b-col>
      </b-row>
      <div v-if="loadingData" class="d-flex justify-content-center my-3">
        <div class="spinner-border text-custom-blue" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <hr>
      <div>
        <b-row class="filters pb-1 px-2" v-if="!loadingData">
          <b-col lg="6" md="6" sm=6>
            <div class="d-flex align-items-center">
              <span class="mr-1" for="tableSize">Mostrar</span>
              <v-select
                  id="tableSize"
                  v-model="paginationData.defaultSize"
                  @input="updateTableSize($event)"
                  :options="table.optionsSize"
                  :clearable="false"
                  style="width: 90px;"
              />
            </div>
          </b-col>

          <b-col lg="6" md="6" sm="6">
            <ButtonDownloadSpreadsheet
                type="EstoqueFisico"
                :parameters="paramsEstoque"
                :size="size"
                :isEmpty="table.items.length === 0 || (isDownloadable === false || search.unidade === '')"
                tooltip-text="Exportação de planilha de estoque físico"
            />
          </b-col>
        </b-row>
        <section class="list-estoque" v-if="!loadingData">
          <b-table
              id="tableEstoque"
              responsive
              :stickyColumn="true"
              :busy.sync="filtering"
              :fields="table.fields"
              :items="table.items"
              @sort-changed="ordenarColuna"
              no-local-sorting
              :no-sort-reset="true"
          >
            <template #cell(id_lote)="row">
                            <span class=" comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ row.item.numero_lote }}
                            </span>
            </template>
            <template #cell(id_vacina)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                                  v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
                                  :title="row.item.nome_tecnico_vacina"
                            >
                                {{ row.item.nome_tecnico_vacina }}
                            </span>
            </template>
            <template #cell(validade)="row">
                            <span class="font-weight-bold">
                                {{ formatDate(row.item.validade, 'DD/MM/YYYY') }}
                            </span>
            </template>
            <template #cell(id_fabricante)="row">
                            <span class="font-weight-bold">
                                {{ row.item.descricao_fabricante }}
                            </span>
            </template>
            <template #cell(id_frasco)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas rounded-pill custom"
                                  :class="row.item.quantidade_frasco == 1 ? 'mono' : 'multi'"
                            >
                                {{ row.item.descricao_frasco }}
                            </span>
            </template>
            <template #cell(saldo)="row">
                            <span class="font-weight-bold">
                                {{ row.item.saldo }}
                            </span>
            </template>
            <template #cell(descricao_unidade)="row">
                            <span class="font-weight-bold">
                                {{ row.item.descricao_unidade }}
                            </span>
            </template>
          </b-table>
          <div class="pb-2 px-1">
            <span v-if="without" class="ml-1">Nenhum lote encontrado</span>
            <b-col sm="12" class="mt-2">
              <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
            </b-col>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ButtonDownloadSpreadsheet from "@/views/components/button-download-spreadsheet/ButtonDownloadSpreadsheet.vue";

export default {
  title: 'Lista de estoques',

  components: {
    ButtonDownloadSpreadsheet,
    BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, TableSwitch, BCard,
    vSelect, CustomPagination, PageHeader,
    TimedAlert, VuePerfectScrollbar, BSpinner,
    BFormDatepicker, BInputGroup, BInputGroupAppend,
    InfograficoEstoqueCard, InfoCard, TableFilter
  },

  directives: {
    'b-tooltip': VBTooltip
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Estoque',
          active: true
        },
        {
          name: 'Estoque físico',
          active: true
        }
      ],
      alert: {
        errorMessage: '',
        show: false
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false
      },
      filtering: false,
      filter: false,
      loadingData: true,
      without: false,
      searchTerm: '',
      table: {
        fields: [
          {key: "id_lote", label: "LOTE", sortable: true, class: 'mw-150'},
          {key: "id_vacina", label: "VACINA", sortable: true, class: 'mw-150'},
          {key: "validade", label: "VALIDADE", sortable: true, class: 'mw-150'},
          {key: "id_fabricante", label: "FABRICANTE", sortable: true, class: 'mw-150'},
          {key: "id_frasco", label: "DOSE", sortable: true, class: 'mw-150'},
          {key: "saldo", label: "QUANT.", sortable: true, class: 'mw-100'},
          {key: "descricao_unidade", label: "U. OPERACIONAL", sortable: true, class: 'mw-200'}
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'id_lote',
        order: 'desc'
      },
      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      info: {
        doses: [
          {
            icon: 'DropletIcon',
            color: 'light-primary',
            title: 0,
            subtitle: 'Doses Aplicadas',
          },
          {
            icon: 'TruckIcon',
            color: 'light-warning',
            title: 0,
            subtitle: 'Doses Transferidas',
          },
          {
            icon: 'XIcon',
            color: 'light-danger',
            title: 0,
            subtitle: 'Doses Descartadas',
          },
        ],
        geral: {
          vaccines: [],
          series: [],
          disease: ''
        }
      },
      search: {
        unidade: '',
        doenca: '',
        vacina: '',
        fabricante: '',
        validade: '',
        lote: '',
      },
      input: {
        validade: '',
      },
      fabricanteSelect: [],
      unidadeSelect: [],
      doencaSelect: [],
      vacinaSelect: [],
      data: {},
      size: 0,
      isDownloadable: false,
      timer: null
    }
  },
  mounted() {
    this.setSearchFilter();
    this.loadDataEstoque();
    this.loadAllInfo();
    this.loadDataCard();
    this.loadCards();
    this.timer = setInterval(() => {
      this.loadDataCard()
      this.loadCards()
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    setSearchFilter() {
      Object.assign(this.search, this.getEstoqueFisico)
    },
    async loadAllInfo() {
      const parameters = {
        ativo: true
      }

      await this.$http.get(this.$api.saveAndGetDoenca(), {params: parameters}).then(({data}) => {
        if (data.length) {
          this.doencaSelect = data.sort((a, b) => a.nome_tecnico.localeCompare(b.nome_tecnico))
        }
      });
      await this.$http.get(this.$api.saveAndGetVacina(), {params: parameters}).then(({data}) => {
        if (data.data.length) {
          this.vacinaSelect = data.data.sort((a, b) => a.nome_tecnico_vacina.localeCompare(b.nome_tecnico_vacina))
        }
      });
      await this.$http.get(this.$api.unidade(), {
        params: {
          ativo: true,
          "colunaOrdenar": "descricao_unidade"
        }
      }).then(({data}) => {
        this.unidadeSelect = data;
      });
      await this.$http.get(this.$api.fabricante(), {params: parameters}).then(({data}) => {
        this.fabricanteSelect = data;
      });
    },
    async loadDataCard() {
      const params = {
        ativo: false,
        id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
        validade: this.search.validade,
        id_doenca: this.search.doenca ? this.search.doenca.id_doenca : '',
        id_vacina: this.search.vacina ? [this.search.vacina.id_vacina] : '',
        id_fabricante: this.search.fabricante ? this.search.fabricante.id_fabricante : '',
        lote: this.search.lote
      };
      await this.$http.get(this.$api.lote(), {params}).then(({data}) => {
        const info = data;

        let quantidades = []
        let doenca = '';

        info.map((item) => {
          doenca = item.descricao_doenca;
          quantidades[item.id_lote_unidade] = {
            saldo: item.saldo,
            id_vacina: item.id_vacina,
          };
        });

        let quantidadeVacinas = [];
        let vacinas = [];

        info.forEach(item => {
          quantidadeVacinas[item.id_vacina] = quantidades.filter(q => q.id_vacina == item.id_vacina)
          quantidadeVacinas[item.id_vacina] = quantidadeVacinas[item.id_vacina].map(q => q.saldo);
          quantidadeVacinas[item.id_vacina] = this.calcTotalDoses(quantidadeVacinas[item.id_vacina]);
          vacinas[item.id_vacina] = item.nome_tecnico_vacina;
        });

        this.info.geral.series = quantidadeVacinas.length ? quantidadeVacinas.filter(n => n) : [];
        this.info.geral.vaccines = vacinas ? vacinas.filter(n => n) : [];
        this.info.geral.disease = this.search.doenca ? doenca : '';
      });
    },
    async loadCards() {
      await this.$http.get(this.$api.tipoMovimentacao(), {
        params: {
          id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
        }
      }).then(({data}) => {
        data.map(item => {
          if (item.id_tipo_movimentacao == 1) {
            this.info.doses[1].title = item.total ?? 0;
          }

          if (item.id_tipo_movimentacao == 2) {
            this.info.doses[2].title = item.total ?? 0;
          }
        });
      })
      this.loadAppliedDoses();
    },
    async loadAppliedDoses() {
      const params = {
        id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
        id_doenca: this.search.doenca ? this.search.doenca.id_doenca : '',
        id_vacina: this.search.vacina ? this.search.vacina.id_vacina : '',
        id_fabricante: this.search.fabricante ? this.search.fabricante.id_fabricante : '',
        lote: this.search.lote,
      }
      await this.$http.get(this.$api.dadosVacinacao(), {params}).then(({data}) => {
        this.info.doses[0].title = data.aplicacoes_concluidas
      })
    },
    loadDataEstoque() {
      const params = this.paramsEstoque;
      this.filtering = true;

      this.$http.get(this.$api.lote(), {params})
          .then(({data}) => {
            this.table.items = data.data;
            this.without = data.data.length === 0;
            this.loadingData = false;
            this.filtering = false;
            this.size = data.total;
            this.startPagination(data);
          });

      this.loadCards()
    },
    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? 'desc' : 'asc';

      this.filtering = true;

      this.loadDataEstoque();
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
      this.loadDataEstoque();
    },

    updateTableSize(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }
      this.filtering = true;

      this.paginationData.currentPage = 1;
      this.loadDataEstoque();
    },
    filterData() {
      if (this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
        this.without = false;

        clearTimeout(this.timeID);
        this.timeID = setTimeout(() => {
          this.filtering = true;
          this.isDownloadable = true;
          this.loadDataEstoque();
        }, 500);
      }
    },
    clearSearch() {
      this.search.unidade = '';
      this.search.validade = '';
      this.search.vacina = '';
      this.search.doenca = '';
      this.search.fabricante = '';
      this.search.lote = '';
      this.input.validade = ''
      this.isDownloadable = false;
      this.loadDataEstoque();
      this.loadDataCard();
    },
    calcTotalDoses(array) {
      return parseInt(array.reduce((a, b) => a + b, 0))
    },
    formatString(string) {
      const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
      return formatted.toLowerCase();
    },
    formatDate(string, format) {
      return moment(string).format(format);
    },
    setPicker() {
      this.input.validade = moment(this.search.validade).format('DD/MM/YYYY');
    },
    setInput() {
      this.search.validade = moment(this.input.validade, 'DD/MM/YYYY').format('YYYY-MM-DD');
    },
  },
  computed: {
    getEstoqueFisico() {
      return this.$store.getters['filtrosState/getEstoqueEstoqueFisico'];
    },
    paramsEstoque() {
      return {
        id_unidade: this.search.unidade ? this.search.unidade.id_unidade : '',
        validade: this.getEstoqueFisico.validade,
        id_doenca: this.search.doenca ? this.search.doenca.id_doenca : '',
        id_vacina: this.search.vacina ? [this.search.vacina.id_vacina] : '',
        id_fabricante: this.search.fabricante ? this.search.fabricante.id_fabricante : '',
        numero_lote: this.search.lote,
        termoPesquisa: this.searchTerm,
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order
      };
    },
  },

  watch: {
    search: {
      handler(newValue) {
        this.$store.commit('filtrosState/setEstoqueEstoqueFisico', newValue);
      },
      deep: true
    }
  }
}
</script>

<style>
.font_size_label_estoque {
  font-size: 15px;
  margin-top: -5px;
}

.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}

.mono {
  background-color: #f3eeff;
  color: #8c65e2;
}

.multi {
  background-color: #e3f6f4;
  color: #21a696;
}

.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}

.altura_max_scrollbar {
  max-height: 100px;
  padding-right: 15px;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.hidden_overflow_tabela {
  overflow: hidden;
}
</style>