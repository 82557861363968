<template>
  <tour :steps="tourSteps">
  <div class="content-wrapper">
    <page-header screenName="Aderir Campanha" :linkItems="linkItems" />
    <div id="adesao">
    <section class="pb-3 px-3 pt-1 card">
      <b-alert show variant="warning" class="mb-3">
        <div class="alert-body">
          <span>
            <strong>Atenção! </strong>
            Algumas campanhas não estão disponíveis para determinados tipos de
            empresas. Caso não encontre a sua empresa na lista abaixo, entre em
            contato com nosso suporte.
          </span>
        </div>
      </b-alert>

      <b-row v-if="loading">
        <b-col cols="12" class="text-center">
          <b-spinner variant="custom" class="mb-1"></b-spinner>
        </b-col>
      </b-row>

      <validation-observer ref="joinCampaign">
        <b-form v-if="!loading" @submit.prevent="formSubmit">
          <TimedAlert
            variant="warning"
            message="Empresas apenas com cadastros completos"
            :show="true"
          />
          <b-row class="align-items-start">
            <b-col md="6" lg="4">
              <b-form-group
                label="Selecione uma Campanha vigente*"
                label-for="campanha"
                label-class="font_size_label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Campanha Vigente"
                  rules="required"
                >
                  <v-select
                    id="campaigns"
                    v-model="chooseCampaign"
                    :options="campaigns"
                    variant="custom"
                    item-text="campanha_descricao"
                    item-value="id_campanha"
                    placeholder="Selecione uma campanha"
                    label="campanha_descricao"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4">
              <b-form-group
                label="Escolha a Empresa*"
                label-for="uo"
                label-class="font_size_label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Empresa"
                  rules="required"
                >
                  <v-select
                    id="company"
                    v-model="chooseCompany"
                    :options="companiesUser"
                    variant="custom"
                    item-text="nome_empresa"
                    item-value="id_empresa"
                    placeholder="Selecione uma empresa"
                    :label="empresaFilter"
                    :disabled="companiesUser.length == 0"

                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                    <template #option="item">
                      <strong>{{item.documento_formatado}}</strong><br>
                      <span v-if="empresaFilter != 'doc_filter' && item[empresaFilter]">{{ item[empresaFilter] }}</span>
                      <span v-else>{{ item.nome_empresa }}</span>
                    </template>
                    <template #selected-option="item">
                      <span>
                        {{ empresaFilter != 'doc_filter' ? item[empresaFilter] : item.documento_formatado }}
                      </span>
                    </template>
                  </v-select>
                  <b-form-radio-group
                    id="radio-group-empresa"
                    v-model="empresaFilter"
                    name="radio-sub-component"
                    class="mt-10px radio-empresa"
                    size="sm"
                  >
                    <span class="mr-1">Filtrar por:</span>
                    <b-form-radio value="doc_filter">CNPJ/CPF</b-form-radio>
                    <b-form-radio value="razao_social">Razão Social</b-form-radio>
                    <b-form-radio value="nome_empresa">Nome Fantasia</b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4" v-if="choseAddress">
              <b-form-group 
              label="Selecione um endereço*"
              label-for="uo"
              label-class="font_size_label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="endereço"
                  rules="required"
                >
                  <v-select
                    id="enderecos"
                    v-model="companyAddress"
                    :options="companyAddresses"
                    variant="custom"
                    item-value="id_empresa"
                    placeholder="Selecione um endereço"
                   
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4">
              <b-button
                type="submit"
                variant="custom-blue"
                class="button-config mt-2"
                :disabled="chooseCompany == null"
              >
                Avançar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
    </div>
  </div>
  </tour>
</template>

<script>
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import { situacaoCampanha } from "@core/utils/enums";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BSpinner,
  BFormSelect,
  BAlert,
  BFormRadioGroup,
  BFormRadio
} from "bootstrap-vue";
import { getCampaignsByStatus } from "@/views/custom-pages/adesao/config";
import { AdesaoResetDependences } from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
import { situacaoEmpresa } from "@/enum/empresa";
import moment from "moment";
import {tipoCampanhaEnum} from "@/enum/tipoCampanha";
import tourMixin from "@/mixins/tourMixin";

export default {
  components: {
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BFormSelect,
    TimedAlert,
    BAlert,
    BFormRadioGroup,
    BFormRadio,
  },
  mixins: [tourMixin],
  mounted() {
    AdesaoResetDependences();
    this.getData();
  },

  data() {
    return {
      empresaFilter: "doc_filter",
      linkItems: [
        {
          name: "Adesão",
          routeName: "",
        },
        {
          name: "Aderir Campanha",
          active: true,
        },
      ],
      showMessage: false,

      user: this.$store.state.sessions.userData,

      required,

      loading: false,

      campaigns: [],
      companiesUser: [],

      chooseCampaign: null,
      chooseCompany: null,
      choseAddress: null,

      companyAddresses: [],
      companyAddress: null
    };
  },

  methods: {
    async getData() {
      await this.getCampaigns();
    },

    async getCampaigns() {
      this.loading = true;

      const params = {
        situacao: [situacaoCampanha.EM_ANDAMENTO],
        tipoCampanha: tipoCampanhaEnum.REGIONAL,
      }

      await getCampaignsByStatus(params).then((response) => {
        this.campaigns = response.data;
      });

      this.loading = false;
    },

    async getCompanyLoggedUser() {
      this.loading = true;
      this.companiesUser = [];
      this.chooseCompany = null;

      let year = moment(this.chooseCampaign.termino_campanha).format('YYYY')

      if(moment().year() == moment(this.chooseCampaign.inicio_campanha).format('YYYY')) {
        year = moment(this.chooseCampaign.inicio_campanha).format('YYYY')
      }

      const params = {
        anoColaboradores: year,
        situacaoEmpresa: this.chooseCampaign.permite_adesao_parcial ? ['Completo', 'Parcial'] : null,
      };
      let rota = this.$api.getEmpresasAdesaoPorCampanha(
        this.chooseCampaign.id_campanha
      );

      await this.$http.get(rota, { params }).then((response) => {
        this.companiesUser = response.data.map((empresa) => {
          return {
            ...empresa,
            documento_formatado: this.formatCnpjCpf(empresa.documento),
            doc_filter: this.formatCnpjCpf(empresa.documento) + ' ' + empresa.documento
          };
        });
      });

      this.loading = false;
    },

    formatCnpjCpf(document) {
      if (document.length === 14) {
          return this.$options.filters.VMask(document, '##.###.###/####-##');
        } else if (document.length === 11) {
          return this.$options.filters.VMask(document, '###.###.###-##');
        } else {
          return document;
        }
    },

    async updateCollaboratorsCompany() {
      this.loading = true;
      
      const params = {
        id_campanha: this.chooseCampaign.id_campanha
      };
      this.$http.get(this.$api.campanhaEmpresaParameter(this.chooseCompany.id_empresa), { params })
        .then(({data}) => {
          const campanhas = data;
          if(campanhas){
            campanhas.forEach((campanha) => {
              this.chooseCompany.trabalhador -= parseInt(campanha.quantidade_categoria.Trabalhador) || 0
              this.chooseCompany.dependente -= parseInt(campanha.quantidade_categoria.Dependente) || 0
              this.chooseCompany.terceiro -= parseInt(campanha.quantidade_categoria.Terceiro) || 0
            });
            this.loading = false;
          }
          
          if(this.chooseCompany.trabalhador < 0){
            this.chooseCompany.trabalhador = 0
          }
          
          if(this.chooseCompany.dependente < 0){
            this.chooseCompany.dependente = 0
          }
          
          if(this.chooseCompany.terceiro < 0){
            this.chooseCompany.terceiro = 0
          }
        });  
    },    
    formatAddress(address) {
      return `${address.logradouro}, ${address.numero ? address.numero : 'Sem número'}, ${address.bairro}, ${address.nome}, ${address.uf}, ${address.cep}`
    },

    generateOptionsAddressSelect(addresses) {
      addresses.empresa_endereco.forEach((address,index,arr) => {      
          if(arr.length == 1) {
            const companyAddress = {
              id_unidade: address.id_unidade,
              endereco: address,
              contato: address.contato,
              label:this.formatAddress(address)
            }
            this.companyAddress = companyAddress
            this.companyAddresses.push(companyAddress)
          } else {
            this.companyAddresses.push({
              id_unidade: address.id_unidade,
              endereco: address,
              contato: address.contato,
              label:this.formatAddress(address)
            })
          }
        })
    },

    formSubmit() {
        this.$refs.joinCampaign.validate().then((success) => {
          if (success) {
            this.chooseCompany.id_unidade = this.companyAddress.id_unidade
            this.chooseCompany.endereco = this.companyAddress.endereco
            this.chooseCompany.contato = this.companyAddress.contato

            this.$store.commit(
              "adesaoState/SET_CHOOSE_CAMPAIGN",
              this.chooseCampaign
            );
            this.$store.commit(
              "adesaoState/SET_CHOOSE_COMPANY",
              this.chooseCompany
            );

            this.$router.push({ name: "purchase" });
          }
        });
    },
  },
  computed: {
    empresaFoiSelecionada() {
      return this.companiesUser.length > 0
    }
  },
  watch: {
    chooseCampaign(value) {
      if (!value) {
        this.chooseUnit = null;
        this.chooseCompany = null;
        this.companiesUser = [];
        this.choseAddress = false
        this.companyAddresses = []
      } else {  
        this.getCompanyLoggedUser();
      }
    },
    chooseCompany(value) {
      this.choseAddress = true
      this.companyAddresses = []
      if(!value) {
        this.choseAddress = false
      } else {
        this.companyAddress = null;
        this.updateCollaboratorsCompany();
        this.choseAddress = true
        this.generateOptionsAddressSelect(value)
      }
    }
  },
};
</script>

<style>
  @import "./style.scss";
  .radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
      left: 0;
  }
</style>
