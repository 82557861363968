<template>
  <div>
    <div
      v-for="aviso in avisos"
      :key="aviso.id_notificacao"
    >
      <ModalShowAviso
        v-if="aviso.show"
        :aviso="aviso"
        :show-modal="aviso.show"
        @confirmed="viewAviso(aviso)"
      />
    </div>
    
    <section v-if="perfilSuporte" class="d-flex justify-content-center mt-5">
      <div class="box-img-home mt-2">
        <img :src="imgLogo" alt="Bem-vindo ao VacinaSESI">
      </div>
    </section>

    <section v-else>
        <DashboardView />
    </section>

  </div>
</template>

<script>
import moment from 'moment'
import { actions, subjects } from '@/libs/acl/rules'
import { isUserLoggedIn } from '@/auth/utils'
import ModalShowAviso from '@/views/components/custom/modals/ModalShowAviso.vue'
import { TIPONOTIFICACAO } from '@/enum/tipo-notificacao'
import DashboardView from '@/views/custom-pages/dashboard/Index.vue'
import { setTourModalType } from '@/@core/utils/store/setStore';
import { getTourMode } from '@/@core/utils/store/getStore';

export default {
  title: 'Bem vindo!',

  components: {
    ModalShowAviso,
    DashboardView,
  },

  data() {
    return {
      moment,
      imgLogo: require('@/assets/images/pages/bem-vindo-vacina-sesi.png'),
      avisos: [],
      modal: {
        showModal: true,
      },
      show: true,
      perfilSuporte: this.$can(actions.VISUALIZAR, subjects.SUPORTE_GERENCIAMENTO_FILA),
      userData: this.$store.state.sessions.userData,
      company: null
    }
  },
  mounted() {
    const isAdminEmpresa = this.$can(actions.VISUALIZAR, subjects.EMPRESAS_AE);
    if(isAdminEmpresa){
      this.redirectToRegisterCompanyOrStartTour()
    }
    this.redirectToAjuda()
    this.redirectToUrl()

    if (isUserLoggedIn()) {
      this.loadDataAvisoNaoVisualizado()
    }
  },
  methods: {
    loadDataAvisoNaoVisualizado() {
      this.$http.get(this.$api.getNotificacoesVigentes(), {
        params: {
          visualizado: false,
          tipo: TIPONOTIFICACAO.AVISO,
          vigencia: moment().format('YYYY-MM-DD'),
        },
      })
        .then(({ data }) => {
          this.avisos = this.filterList(data.data)
        })
    },
    viewAviso(aviso) {
      this.$http.patch(this.$api.viewNotificacaoNaoVisualizada(aviso.id_notificacao_usuario)).then(() => {
        this.loadDataAvisoNaoVisualizado()
      })
    },
    filterList(list) {
      list.filter((item, index) => {
        item.show = index === 0 && !item.notificado
      })
      return list
    },
    redirectToRegisterCompanyOrStartTour(){
      if (!['Concluiu', 'Pulou'].includes(this.userData.situacao_tutorial) && !getTourMode()) {
        setTourModalType('Iniciou');
        return
      }
      if (this.userData.empresas.length === 0 && !getTourMode()) {
          this.$router.push({
          name: 'empresa-register',
          })
      }
    },
    redirectToAjuda(){
      if (this.$route.params.idAjuda) {
      this.$router.push(
        {
          name: 'pages-faq',
          params: {
            idAjuda: this.$route.params.idAjuda,
            categoria: this.$route.params.categoria,
          },
        },
      )
    }
    },
    redirectToUrl(){
      if (this.$route.params.redirectToUrl) {
      this.$router.push(
        {
          name: this.$route.params.routeName,
          params: this.$route.params.routeParams,
        },
      )
    }
    }
  },
}
</script>

<style scoped>
.box-img-home {
  max-width: 500px;
  position: relative;
}

.box-img-home img {
  width: 100%;
  opacity: .3;
}
</style>
