<template>
    <div class="content-wrapper">
        <PageHeader :screenName="'Nota Fiscal '+form.nota_fiscal" :linkItems="linkItems"/>

        <ErrorModal
            :modalShow="modalError.showModal"
            :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal"
            @confirmed="redirectToList"
        />

        <main class="card">
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section v-else class="form p-2">
                
                <b-form>
                    <div class="top-right-button">
                        <b-button class="d-flex align-items-center" @click="$router.back()" variant="primary-button">
                            <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                            <span class="align-middle">Voltar</span>
                        </b-button>
                    </div>
                    <b-row>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Nota Fiscal"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.nota_fiscal }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Unidade Operacional"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.unidade_operacional }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="pb-1" lg="6" md="6" sm="12">
                            <b-form-group label="Fornecedor"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.fornecedor }}<br>
                                        {{ form.cnpj | VMask('##.###.###/####-##')}}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col class="pb-1" lg="6" md="6" sm="12">
                            <b-form-group label="Nota inserida no sistema por"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.usuario }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="pb-2" lg="6" md="6" sm="12">
                            <b-form-group label="Data de Emissão"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ formatDate(form.data_emissao, 'DD/MM/YYYY') }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col class="pb-2" lg="6" md="6" sm="12">
                            <b-form-group label="Data de Lançamento"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ formatDate(form.data_lancamento, 'DD/MM/YYYY') }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                        <b-col class="pb-2" lg="12" md="12" sm="12">
                            <b-form-group label="Observações"
                            label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div v-if="form.observacao">
                                        {{ form.observacao }}
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>  
                        </b-col>
                    </b-row>
                </b-form>
            </section>
            <section v-if="table.items.length" class="list-itens-entrada">
                    <b-table
                        id="tableItensEntrada"
                        responsive
                        :stickyColumn="true"
                        :fields="table.fields"
                        :items="table.items"
                    >
                        <template #cell(vacina)="row">
                            <span class=" comprimento_maximo_celulas font-weight-bold"
                            v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
                            :title="row.item.lote.vacina.nome_tecnico"
                            >
                                {{ row.item.lote.vacina.nome_tecnico }}
                            </span>
                        </template>
                        <template #cell(fabricante)="row">
                            <span class="font-weight-bold">
                                {{ row.item.lote.fabricante.descricao }}
                            </span>
                        </template>
                        <template #cell(lote)="row">
                            <span class="font-weight-bold">
                                {{ row.item.lote.numero_lote }}
                            </span>
                        </template>
                        <template #cell(dose)="row">
                            <span class="font-weight-bold custom rounded-pill" 
                            :class="row.item.lote.frasco.quantidade == 1 ? 'mono' : 'multi'"
                            >
                                {{ row.item.lote.frasco.descricao }}
                            </span>
                        </template>
                        <template #cell(validade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ formatDate(row.item.lote.validade, 'DD/MM/YYYY') }}
                            </span>
                        </template>
                        <template #cell(quantidade)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                {{ row.item.quantidade_conversao }}
                            </span>
                        </template>
                        <template #cell(valor_unitario)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                R${{ formatDotDecimal(row.item.valor_unitario) }}
                            </span>
                        </template>
                        <template #cell(valor_total)="row">
                            <span class="comprimento_maximo_celulas font-weight-bold"
                            >
                                R${{ formatDotDecimal((row.item.quantidade * row.item.valor_unitario).toFixed(2)) }}
                            </span>
                        </template>
                    </b-table>
                    <div class="pb-2">
                        <b-row>
                            <b-col lg="12" md="12" sm="12" class="border-top d-flex justify-content-end px-3">
                                <div class="mr-2 text-center">
                                    <p class="mb-0 mt-1">
                                        Qt. Doses
                                    </p>
                                    <span class="font-weight-bold font-size-16 mb-0">
                                        {{ info.quantidade_total }}
                                    </span>
                                </div>
                                <div class="text-center">
                                    <p class="mb-0 mt-1">
                                        Valor Total R$
                                    </p>
                                    <span class="font-weight-bold font-size-16 mb-0">
                                        R${{ formatDotDecimal(info.valor_total) }}
                                    </span>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
            </section>
        </main>
    </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, 
BFormRadio, BButton, BFormTextarea, BLink,
BTable, VBTooltip } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import { getIdNotaFiscal } from '@core/utils/store/getStore';
import { setIdNotaFiscal } from '@core/utils/store/setStore';

export default {
    title: 'Detalhes entrada',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, VuePerfectScrollbar, ErrorModal, BLink,
        BTable, VBTooltip,
    },

    data() {
        return{
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Estoque',
                    routeName: 'estoque-list'
                },
                {
                    name: 'Entrada',
                    routeName: 'entrada-list'
                },
                {
                    name: 'Carregando dados...',
                    active: true
                }
            ],
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                nota_fiscal: '',
                unidade_operacional: '',
                fornecedor: '',
                cnpj: '',
                usuario: '',
                data_emissao: '',
                data_lancamento: '',
                observacao: '',
            },
            info: {
                quantidade_total: '',
                valor_total: '',
            },
            table: {
                fields: [
                    {key: "vacina", label: "VACINA", sortable: false, class: 'mw-150'},
                    {key: "fabricante", label: "FABRICANTE", sortable: false, class: 'mw-150'},
                    {key: "lote", label: "LOTE", sortable: false, class: 'mw-150'},
                    {key: "dose", label: "DOSE", sortable: false, class: 'mw-150'},
                    {key: "validade", label: "VALIDADE", sortable: false, class: 'mw-100'},
                    {key: "quantidade", label: "QT", sortable: false, class: 'mw-100'},
                    {key: "valor_unitario", label: "VALOR UN.", sortable: false, class: 'mw-100'},
                    {key: "valor_total", label: "VALOR TOTAL", sortable: false, class: 'mw-100'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'lote',
                order: 'desc'
            },
            loadingData: true,
        }
    },
    mounted() {
    if((getIdNotaFiscal() === null || typeof getIdNotaFiscal() == 'undefined') && typeof this.$route.params.idNotaFiscal == "undefined") {
        this.openModalError();
        return;
    }

    if(typeof this.$route.params.idNotaFiscal != "undefined"){
        if (this.$route.params.idNotaFiscal !== getIdNotaFiscal()) {
            setIdNotaFiscal(this.$route.params.idNotaFiscal);
        }
    }
    this.$http.get(this.$api.notaFiscalParameter(getIdNotaFiscal())).then(({ data }) => {
        this.preenchimentoFormulario(data);
        this.linkItems[2].name = 'Nota Fiscal ' + data.numero;
    })
  },

    methods: {
        preenchimentoFormulario(dados){
            this.form.nota_fiscal = dados.numero;
            this.form.unidade_operacional = dados.nota_fiscal_campanha_vacina_unidade[0].campanha_vacina_unidade.unidade.descricao;
            this.form.fornecedor = dados.nome_empresa;
            this.form.cnpj = dados.cnpj_empresa;
            this.form.usuario = dados.usuario_insercao;
            this.form.data_emissao = dados.emissao;
            this.form.data_lancamento = dados.lancamento;
            this.form.observacao = dados.observacao;
            this.table.items = dados.itens;
            this.loadingData = false;
            
            let quantidadeTotal = 0, valorTotal = 0;
            this.table.items.map((item)=>{
                quantidadeTotal += item.quantidade_conversao;
                valorTotal += item.quantidade_conversao * item.valor_unitario_conversao;
            });
            this.info.quantidade_total += parseInt(quantidadeTotal);
            this.info.valor_total += (parseFloat(valorTotal)).toFixed(2);
        },
        openModalError() {
            this.modalError.showModal = true;
        },
        redirectToList() {
           this.$router.push({name: 'entrada-list'});
        },
        formatString(string){
            const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
            return formatted.toLowerCase();
        },
        formatDate(string, format){
            return moment(string).format(format);
        },
        formatDotDecimal(value){
            let string = value.toString();
            return string.replace('.', ',')
        }
    }
}
</script>

<style>
.custom{
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.mono{
    background-color: #f3eeff;
    color: #8c65e2;
}
.multi{
    background-color: #e3f6f4;
    color: #21a696;
}
legend{
    color: #5E5873;
}

.font-size-16 {
    font-size: 16px;
    line-height: 21px;
}

.altura_max_input_scrollbar{
    max-height:32px;
    word-break: break-all;
    padding-right: 15px;
}

.altura_max_textarea_scrollbar{
    max-height:113px;
    word-break: break-all;
    padding-right: 15px;
}

.top-right-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
}
</style>