<template>
  <div>
    <modal-observacoes-agendamento
      :modal-show="modalObservation.modalShow"
      :info-text="modalObservation.infoText"
      :close-modal.sync="modalObservation.modalShow"
    />

    <main class="card hidden_overflow_tabela">
      <FilterAgendamentoList
        :filter="filter"
        :error-api="errorApiSearchParameters"
        @filterTable="loadScheduleData"
      />
      <div
        v-if="loadingData"
        class="d-flex justify-content-center my-3"
      >
        <div
          class="spinner-border text-custom-blue"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <section
        v-if="!loadingData"
        class="list-agendamento"
      >
        <b-table
          id="tableEmpresa"
          responsive
          :sticky-column="true"
          :busy.sync="filtering"
          :fields="table.fields"
          :items="table.items"
          no-local-sorting
          :no-sort-reset="true"
          @sort-changed="sortColumn"
        >
          <template #cell(nome_fantasia)="row">
            <div class="comprimento_maximo_celulas">              
              <div
                v-b-tooltip
                class="font-weight-bolder"
                :title="row.item.cnpj.length > lengthColumnMaximo ? row.item.cnpj : null"
              >
                {{ row.item.cnpj | VMask('##.###.###/####-##') }}
              </div>
              <div
                v-b-tooltip
                :title="row.item.razao_social.length > lengthColumnMaximo ? row.item.razao_social : null"
              >
                {{ row.item.razao_social }}
              </div>
              <div
                v-b-tooltip
                class="font-weight-bold"
                :title="row.item.nome_fantasia.length > lengthColumnMaximo ? row.item.nome_fantasia : null"
              >
                {{ row.item.nome_fantasia }}
              </div>
            </div>
          </template>
          <template #cell(unidade_descricao)="row">
            <span
              v-b-tooltip
              class="comprimento_maximo_celulas"
              :title="row.item.descricao && row.item.descricao.length > lengthColumnMaximo ? row.item.descricao : null"
            >
              {{ row.item.unidade_descricao }}
            </span>
          </template>
          <template #cell(data_aplicacao)="row">
            <div class="comprimento_maximo_celulas">
              <div
                v-b-tooltip
                :title="row.item.data_aplicacao && row.item.data_aplicacao.length > lengthColumnMaximo ? row.item.data_aplicacao : null"
              >
                {{ formatDate(row.item.data_aplicacao) }}
              </div>
              <div
                v-b-tooltip
                class="littleLabelScheduling comprimento_maximo_celulas"
                :title="null"
              >
                {{ getPeriod(row.item) }}
              </div>
            </div>
          </template>
          <template #cell(quantidade_dose_prevista)="row">
            <span
              v-b-tooltip
              class="comprimento_maximo_celulas"
              :title="row.item.quantidade_dose_prevista && row.item.quantidade_dose_prevista.length > lengthColumnMaximo ? row.item.quantidade_dose_prevista : null"
            >
              {{ row.item.quantidade_dose_prevista ? row.item.quantidade_dose_prevista : '-' }}
            </span>
          </template>
          <template #cell(quantidade_dose_aplicada)="row">
            <span
              v-b-tooltip
              class="comprimento_maximo_celulas"
              :title="row.item.quantidade_dose_aplicada && row.item.quantidade_dose_aplicada.length > lengthColumnMaximo ? row.item.quantidade_dose_aplicada : null"
            >
              {{ row.item.quantidade_dose_aplicada ? row.item.quantidade_dose_aplicada.toLocaleString('pt-BR') : '-' }}
            </span>
          </template>
          <template #cell(situacao)="row">
            <span
              v-b-tooltip
              class="comprimento_maximo_celulas"
              :class="{
                'customStatusAgendado': row.item.situacao === 'Agendado',
                'customStatusFinalizado': row.item.situacao === 'Finalizado',
                'customStatusCancelado': row.item.situacao === 'Cancelado'
              }"
              :title="row.item.situacao && row.item.situacao.length > lengthColumnMaximo ? row.item.situacao : null"
            >
              {{ row.item.situacao }}
            </span>
          </template>
          <template #cell(actions)="row">
            <div class="comprimento_maximo_celulas">
              <feather-icon
                id="view-agendamento"
                role="button"
                class="text-custom-blue mr-1"
                icon="EyeIcon"
                size="18"
                @click.prevent="modalObservations(row.item)"
              />
            </div>
          </template>
        </b-table>
        <div class="pb-2 px-1">
          <span
            v-if="withoutAgendamento"
            class="ml-1"
          >Nenhum agendamento encontrado</span>
          <b-col
            sm="12"
            class="mt-2"
          >
            <CustomPagination
              :paginacao="paginationData"
              @page-cliked="updateCurrentPage"
            />
          </b-col>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import {
  BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import moment from 'moment'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import ModalObservacoesAgendamento from '@/views/components/custom/modals/ObservacoesAgendamento.vue'
import FilterAgendamentoList from '@/views/custom-pages/agendamento/components/FilterAgendamentoList.vue'
import tourSchedulingMixin from '../mixins/tourSchedulingMixin'

export default {
  title: 'Agendamentos',

  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    CustomPagination,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    TableFilter,
    ModalObservacoesAgendamento,
    FilterAgendamentoList,
  },
  mixins: [tourSchedulingMixin],
  data() {
    return {
      filtering: false,
      filter: false,
      loadingData: false,
      errorApiSearchParameters: {
        cnpj: null,
        fantasyName: null,
        date: null,
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      table: {
        fields: [
          {
            key: 'nome_fantasia', label: 'EMPRESA | CNPJ', sortable: true, class: 'width_columns width_columns_header width_columns_header_company',
          },
          {
            key: 'unidade_descricao', label: 'UNIDADE OPERACIONAL', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'data_aplicacao', label: 'DATA', sortable: true, class: 'width_columns_small width_columns_header',
          },
          {
            key: 'quantidade_dose_prevista', label: 'DOSES PREVISTAS', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'quantidade_dose_aplicada', label: 'DOSES APLICADAS', sortable: true, class: 'width_columns width_columns_header',
          },
          {
            key: 'situacao', label: 'STATUS', sortable: true, class: 'width_columns_small width_columns_header',
          },
          {
            key: 'actions', label: 'AÇÕES', sortable: false, class: 'width_columns_small width_columns_header',
          },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'data_aplicacao',
        order: 'desc',
      },
      withoutAgendamento: false,
      modalObservation: {
        modalShow: false,
        infoText: 'Nenhuma observação encontrada.',
      },
      lengthColumnMaximo: 28,
      searchParams: null,
    }
  },

  methods: {
    updateCurrentPage(page) {
      this.paginationData.currentPage = page
      this.filtering = true
      this.filter = !this.filter
    },

    modalObservations(item) {
      this.modalObservation.infoText = item.observacao || item.observacao == '' ? item.observacao : 'Nenhuma observação encontrada'
      this.modalObservation.modalShow = true
    },

    sortColumn(sort) {
      this.table.columnOrder = sort.sortBy
      this.table.order = sort.sortDesc ? 'desc' : 'asc'
      this.loadScheduleData()
    },

    loadScheduleData(filter) {
      if(!this.loadData){
        return
      }
      this.searchParams = filter || this.searchParams
      if (filter != null) {
        if (filter.hasOwnProperty('currentPage')) {
          this.paginationData.currentPage = filter.currentPage
        }
      }
      this.errorApiSearchParameters.cnpj = null
      this.errorApiSearchParameters.fantasyName = null
      this.errorApiSearchParameters.date = null

      let params
      if (filter) {
        params = {
          cnpj: filter ? filter.cnpj : null,
          nome_fantasia: filter ? filter.nome_fantasia : null,
          company_name_filter: filter ? filter.company_name_filter : null,
          data_aplicacao: filter ? filter.data : null,
          situacao: filter ? filter.status : null,
          unidade_operacional: filter ? filter.unidade_operacional : null,
          tamanho: filter ? filter.defaultSize : this.paginationData.defaultSize,
          pagina: filter.pagina ? filter.pagina : this.paginationData.currentPage,
          porPagina: filter.porPagina ? filter.porPagina : this.paginationData.defaultSize,
          colunaNome: this.table.columnOrder,
          colunaOrdem: this.table.order,
        }
      } else {
        params = {
          cnpj: this.searchParams.cnpj ?? this.searchParams.cnpj,
          nome_fantasia: this.searchParams.nome_fantasia ?? this.searchParams.nome_fantasia,
          company_name_filter: this.searchParams.company_name_filter ?? this.searchParams.company_name_filter,
          data_aplicacao: this.searchParams.data ?? this.searchParams.data,
          situacao: this.searchParams.status ?? this.searchParams.status,
          unidade_operacional: this.searchParams.unidade_operacional ?? this.searchParams.unidade_operacional,
          porPagina: this.searchParams.porPagina ? this.searchParams.porPagina : this.paginationData.defaultSize,
          pagina: this.searchParams.pagina ? this.searchParams.pagina : this.paginationData.currentPage,
          colunaNome: this.table.columnOrder,
          colunaOrdem: this.table.order,
        }
      }

      this.filtering = true

      this.$http.get(this.$api.getAndPostAgendamento(), { params }).then(({ data }) => {
        this.table.items = data.data

        this.withoutAgendamento = false

        if (data.data.length == 0) {
          this.withoutAgendamento = true
        }

        this.loadingData = false

        this.filtering = false
        this.startPagination(data)
      }).catch(({ response: { data } }) => {
        const error = data.errors
        this.errorApiSearchParameters.cnpj = error.cnpj ? error.cnpj[0] : ''
        this.filtering = false
      })
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page
      this.paginationData.totalLines = data.total
      this.paginationData.fromLine = data.from
      this.paginationData.toLine = data.to
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },

    getPeriod(item) {
      let periodo
      if (item.agendamento_periodos.length > 0) {
        periodo = []
        item.agendamento_periodos.forEach(period => periodo.push(period.descricao))
      } else {
        periodo = item.periodo
      }

      let resultPeriod
      if (Array.isArray(periodo)) {
        resultPeriod = periodo.join('/')
      } else {
        resultPeriod = periodo
      }

      return resultPeriod
    },
  },
}
</script>

<style lang="scss">
.customStatusAgendado {
  align-items: center;
  padding: 1px 9px;
  gap: 3px;
  width: 83px;
  height: 20px;
  background: #F3EEFF;
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #8C65E2;
}
.customStatusFinalizado {
  padding: 3px 9px;
  background: rgba(40, 199, 111, 0.12);
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #3AAA4E;
}
.customStatusCancelado {
  align-items: center;
  padding: 1px 9px;
  gap: 3px;
  width: 83px;
  height: 20px;
  background: #F3EEFF;
  border-radius: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #EA5555;
}
.companyScheduling {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5E5873;
}
.littleLabelScheduling {
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
}
.hidden_overflow_tabela{
  overflow: hidden;
}
</style>
