const handleData = (data) => {
    return (data === 'Invalid date') ? '' : data;
}

const state = {
    estoque: {
        estoqueFisico: {},
        entrada: {},
        transferencia: {},
        ajusteDeEstoque: {},
        lote: {},
    }
}

const mutations = {
    setLimparState(state) {
        state.estoque.estoqueFisico = {}
        state.estoque.entrada = {}
        state.estoque.transferencia = {}
        state.estoque.ajusteDeEstoque = {}
        state.estoque.lote = {}
    },
    setEstoqueEstoqueFisico(state, data) {
        Object.assign(state.estoque.estoqueFisico, data)
        state.estoque.estoqueFisico.validade = handleData(state.estoque.estoqueFisico.validade);
    },
    setEstoqueEntrada(state, data) {
        Object.assign(state.estoque.entrada, data)
        state.estoque.entrada.emissao = handleData(state.estoque.entrada.emissao);
        state.estoque.entrada.lancamento = handleData(state.estoque.entrada.lancamento);
    },
    setEstoqueTransferencia(state, data) {
        Object.assign(state.estoque.transferencia, data)
    },
    setEstoqueAjusteDeEstoque(state, data) {
        Object.assign(state.estoque.ajusteDeEstoque, data)
        state.estoque.ajusteDeEstoque.data_ajuste = handleData(state.estoque.ajusteDeEstoque.data_ajuste);
    },
    setEstoqueLote(state, data) {
        Object.assign(state.estoque.lote, data)
        state.estoque.lote.validade = handleData(state.estoque.lote.validade);
    },
};

const actions = {}

const getters = {
    getEstoqueEstoqueFisico: state => state.estoque.estoqueFisico,
    getEstoqueEntrada: state => state.estoque.entrada,
    getEstoqueTransferencia: state => state.estoque.transferencia,
    getEstoqueAjusteDeEstoque: state => state.estoque.ajusteDeEstoque,
    getEstoqueLote: state => state.estoque.lote
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
