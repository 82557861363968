import provider from '@/store'
import { getUserData, isUserLoggedIn } from "@/auth/utils";

export const getInfoUserDepartamento = info => {
    if (isUserLoggedIn()) {
        const { departamento } = getUserData();
        return departamento[info];
    }
}

export const getListFilter = () => {
    return provider.state.listFilter.listFilter
}

export const getIdEmpresa = () => {
    return provider.state.empresaState.idEmpresa
}

export const getEstadosEnderecoEmpresa = () => {
    return provider.state.empresaState.estadosEmpresaState
}

export const getCidadeEstadoEnderecoEmpresa = () => {
    return provider.state.empresaState.cidadeEstadosEmpresaState
}

export const getModalValorDiferenciadoRemover = () => {
    return provider.state.empresaState.modalValorDiferenciadoRemoverState
}

export const getUserCompanyList = () => {
    return provider.state.usuarioState.empresas
}

export const getIdUnidade = () => {
    return provider.state.unidadeState.idUnidade
}

export const getIdCampaign = () => {
    return provider.state.campaignState.idCampaign
}

export const getDescricaoCampanha = () => {
    return provider.state.campaignState.descriptionCampaign
}

export const getIdCampaignEditAndShow = () => {
    return provider.state.campaignState.idCampaignEditAndShow
}

export const getRegionsConsulted = (idRegion = null) => {
    let regions = provider.state.campaignState.regionsConsulted;
    return typeof regions[idRegion] != "undefined" ? regions[idRegion] : null
}

export const getIdNotaFiscal = () => {
    return provider.state.entradaState.idNotaFiscal
}

export const getIdLote = () => {
    return provider.state.entradaState.idLote
}

export const getIdUsuario = () => {
    return provider.state.usuarioState.idUsuario
}

export const getEmailForgotPassword = () => {
    return provider.state.usuarioState.emailForgotPassword
}

export const getIdVacina = () => {
    return provider.state.vacinaState.idVacina
}

export const getIdDoenca = () => {
    return provider.state.doencaState.idDoenca
}

export const getIdDepartamento = () => {
    return provider.state.departamentoState.idDepartamento
}

export const getAcronymDepartamento = () => {
    return provider.state.departamentoState.acronymDepartamento
}

export const getAcronymUnidade = () => {
    return provider.state.unidadeState.acronymUnidade
}

export const getIdPontoAtendimento = () => {
    return provider.state.pontoAtendimentoState.idPontoAtendimento
}

export const getDescricaoPontoAtendimento = () => {
    return provider.state.pontoAtendimentoState.descricaoPontoAtendimento
}

export const getIdAgendamento = () => {
    return provider.state.agendamentoState.idAgendamento
}

export const getIdAviso = () => {
    return provider.state.avisoState.idAviso
}

export const getPerfisSelecionadosAviso = () => {
    return provider.state.avisoState.perfisSelecionados
}

export const getIdAjuda = () => {
    return provider.state.ajudaState.idAjuda
}

export const getIdCategoria = () => {
    return provider.state.ajudaState.idCategoria
}

export const getDescricaoCategoria = () => {
    return provider.state.ajudaState.categoria
}

export const getCompanyGroup = () => {
    return provider.state.groupState.companyGroup
}

export const getCompanyGroupFilter = () => {
    return provider.state.groupState.companyGroupFilter
}

export const getIdCompanyGroup = () => {
    return provider.state.groupState.idCompanyGroup
}

export const getCompanyEditWhenCreatingGroup = () => {
    return provider.state.groupState.companyEditWhenCreatingGroup
}

export const getCompanyEditWhenEditingGroup = () => {
    return provider.state.groupState.companyEditWhenEditingGroup
}

export const getCompanyEditIdEmpresa = () => {
    return provider.state.groupState.companyEditIdEmpresa
}

export const getCompanyWithOperators = () => {
    return provider.state.groupState.companyWithOperators
}

export const getTourMode = () => {
    return provider.state.tourState.tourMode
}

export const getTourSteps = () => {
    return provider.state.tourState.steps
}

export const getTourCurrentStep = () => {
    return provider.state.tourState.currentStep
}

export const getTourModalType = () => {
    return provider.state.tourState.modalType
}

export const getTourWatchingTopic = () => {
    return provider.state.tourState.watchingTopic
}

export const getTourWatchingAllTutorials = () => {
    return provider.state.tourState.watchingAllTutorials
}

export const getCompanyBusiness = () => {
    return provider.state.groupState.companyBusiness
}

export const getCompanyBusinessId = () => {
    return provider.state.groupState.companyBusinessId
}