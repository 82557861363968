import router from '@/router'
import { getTourWatchingTopic } from "@/@core/utils/store/getStore";
import { setTourMode, setTourSteps, setTourCurrentStep, setTourWatchingTopic, setTourWatchingAllTutorials, setTourModalType } from '@/@core/utils/store/setStore';
import adhesionSteps from './adhesionSteps';

const firstCompany = [
  {
    target: '#cadastrar-empresa',
    prevAction: false,
    header: false,
    params: {
      placement: 'bottom',
    },
    content: '<strong class="text-dark font-size-16">Cadastrar Nova - Vincular uma empresa</strong><br><span>Cadastre uma nova empresa ou vincule uma empresa já cadastrada ao seu usuário.</span>',
    highlight: true,
    actionOnSequence: () => {
      setTourSteps(editCompany);
      setTourMode(true);
      router.push({
        name: 'empresa-edit',
      })
    },
    actionOnPrevStep: () => {
      setTourMode(false);
      setTourSteps([]);
      setTourModalType('Iniciou');
    }
  }
];

const editCompany = [
  {
    target: '#tab-empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Editar Empresa - Dados principais</strong><br><span>Confira ou cadastre os dados da sua empresa: CNPJ, Nome Fantasia e Razão Social.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnPrevStep: () => {
      setTourSteps(firstCompany);

      router.push({
        name: 'empresa-register'
      });
    }
  },
  {
    target: '#tab-empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Editar Empresa - Dados complementares</strong><br><span>Informe CNAE, FPAS, Número total de trabalhadores e Tipo de Empresa.</span>',
    params: {
      placement: 'top'
    },
    highlight: true
  },
  {
    target: '#tab-empresa',
    header: false,
    params: {
      placement: 'top',
    },
    content: '<strong class="text-dark font-size-16">Editar Empresa - Faturamento</strong><br><span>Informe o CNPJ de faturamento da sua empresa.</span>',
    highlight: true
  },
  {
    target: '#tab-empresa',
    header: false,
    params: {
      placement: 'top',
    },
    content: '<strong class="text-dark font-size-16">Editar Empresa - Endereço</strong><br><span>Informe os dados do endereço da empresa. Caso o local de aplicação seja em um local de endereço diferente, cadastre um novo endereço.</span>',
    highlight: true
  },
  {
    target: '#tab-empresa',
    header: false,
    params: {
      placement: 'top',
    },
    content: '<strong class="text-dark font-size-16">Editar Empresa - Contato</strong><br><span>Informe os dados do contato principal da empresa e o contato para cada endereço(se existir).</span>',
    highlight: true,
    actionOnSequence: () => {
      setTourMode(true)
      setTourSteps(listAndViewCompany);
      router.push({
        name: 'empresa-list',
      })
    }
  }
];

const listAndViewCompany = [
  {
    target: '#empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Empresas - Pesquisar e Listar</strong><br><span>Pesquise e Visualize as empresas vinculadas ao seu usuário.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      router.push({
        name: 'empresa-show',
      })
      setTourCurrentStep(1)
    },
    actionOnPrevStep: () => {
      setTourSteps(editCompany);

      router.push({
        name: 'empresa-edit'
      });
    }
  },
  {
    target: '#empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Empresas - Dados da Empresa</strong><br><span>Através da visualização, pode-se ver os dados cadastrais da empresa e colaboradores.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      router.push({
        name: 'empresa-colaborador-register',
      })
      setTourCurrentStep(2)
    },
    actionOnPrevStep: () => {
      setTourCurrentStep(0);

      router.push({
        name: 'empresa-list',
      });
    }
  },
  {
    target: '#empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Empresas - Cadastrar Colaborador</strong><br><span>Na aba colaborador/cadastrar novo, você pode realizar o cadastro de um novo colaborador em sua empresa. É necessário aceitar o Termo de Consentimento para uso de dados.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      router.push({
        name: 'empresa-colaborador-import',
      })
      setTourCurrentStep(3)
    },
    actionOnPrevStep: () => {
      setTourCurrentStep(1);

      router.push({
        name: 'empresa-show',
      });
    }
  },
  {
    target: '#empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Empresas - Importar Colaboradores</strong><br><span>Você também tem a opção de importar um arquivo para cadastrar vários colaboradores de uma vez. É necessário aceitar o Termo de Consentimento para uso de dados.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      router.push({
        name: 'empresa-colaborador-list',
      })
      setTourCurrentStep(4)
    },
    actionOnPrevStep: () => {
      setTourCurrentStep(2);

      router.push({
        name: 'empresa-colaborador-register',
      });
    }
  },
  {
    target: '#empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Empresas - Ver Colaboradores</strong><br><span>Visualize e consulte os colaboradores cadastrados na empresa.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      router.push({
        name: 'empresa-usuario-register',
      })

      if (getTourWatchingTopic()) {
        setTourWatchingAllTutorials(false);
      }

      setTourCurrentStep(5)
    },
    actionOnPrevStep: () => {
      setTourCurrentStep(3);

      router.push({
        name: 'empresa-colaborador-import',
      });
    }
  },
  {
    target: '#empresa',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Empresas - Cadastrar Novo Usuário</strong><br><span>Cadastre um novo usuário, que poderá assumir a gestão da empresa no sistema, se aplicável.</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      if (getTourWatchingTopic()) {
        setTourWatchingTopic(false);
        setTourMode(false);
        setTourSteps([]);

        router.push({
          name: 'pages-faq',
        })
      }else{
        setTourMode(true)
        setTourSteps(adhesionSteps.joinCampaign);
        router.push({
          name: 'join-campaign',
        })
      }
    },
    actionOnPrevStep: () => {
      setTourCurrentStep(4);

      router.push({
        name: 'empresa-colaborador-list',
      });
    }
  }
];

export default {
    firstCompany: firstCompany,
    editCompany: editCompany,
    listAndViewCompany: listAndViewCompany
};