<template>
  <div :style="loginStyle" class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center">
      <b-col lg="11" class="pt-5 pl-3">
          <InitialHeader :onlyLogo="true"/>
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="11"
        class="d-flex align-items-center mb-5"
      >
        <b-col
          sm="12"
          md="10"
          lg="4"
          class=" mx-auto auth-bg p-0 rounded"
        >
        <div id="bem-vindo-crie" class="px-xl-3 pb-1 pt-3">
          <h2
            class="mb-1 auth-title"
          >
            Bem-vindo ao
            <span class="lt-p1 titles-color">vacina</span>
            <span class="lt-p2 titles-color">sesi</span>
          </h2>
          <div class="initial-alert p-2 mb-2">
            <span class="text-custom-blue font-weight-bolder mb-2">Não possui uma conta?</span><br>
            <span>Clique no botão abaixo para criar sua conta e cadastrar sua empresa.</span>
            <button
              type="submit"
              class="btn button-form-outline btn-block mt-1"
              @click="$router.push({ name: 'auth-register' })"
            >
              Crie uma conta!
            </button>
          </div>
        </div>
        <div id="acessar" class="px-xl-3 pt-1 pb-3">
          <b-card-text class="mb-2">
            Já possui uma conta? Acesse abaixo usando seu e-mail e senha cadastrados.
          </b-card-text>

          <b-alert
            v-if="alert.show"
            show
            fade
            class="mb-2"
            variant="danger"
          >
            <div class="custom-alert">
              <span>
                <BIconExclamationCircle />
                {{ alert.message }}
              </span>
            </div>
          </b-alert>

          <!-- form -->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label="E-mail"
              label-for="login-email"
            >

              <b-form-input
                id="login-email"
                v-model="auth.email"
                name="login-email"
                placeholder="email@email.com"
                autocomplete="off"
                @focus="alert.show = false"
              />
              <small class="text-danger">{{ formErrors.email }}</small>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Senha</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small class="titles-color">Esqueci minha senha</small>
                </b-link>
              </div>

              <b-input-group
                class="input-group-merge"
              >
                <b-form-input
                  id="login-password"
                  v-model="auth.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="******"
                  autocomplete="off"
                  @focus="alert.show = false"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ formErrors.password }}</small>

              
            </b-form-group>

            <!-- submit buttons -->
            <button
              :disabled="disabledButton"
              type="submit"
              class="btn button-form btn-block"
            >
              {{ msgButton }}
            </button>
          </b-form>
        </div>
      </b-col>
    </b-col>
    <!-- /Login-->
    <FooterInitial/>
  </b-row>
</div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BCard,
  BIconExclamationCircle, BInputGroup, BCardText, BForm, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { validation } from '@core/mixins/validation/validation'
import { messages } from '@core/mixins/validation/messages'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {clearStore, getHomeRouteForLoggedInUser} from '@/auth/utils'

// eslint-disable-next-line import/extensions
import LoginImage from '@/views/pages/authentication/components/LoginImage'
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue'
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue'

export default {
  title: 'Login',

  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BIconExclamationCircle,
    BInputGroup,
    BCardText,
    BForm,
    BAlert,
    BCard,
    LoginImage,
    FooterInitial,
    InitialHeader
},
  mixins: [togglePasswordVisibility, validation, messages],

  data() {
    return {
      auth: {
        email: '',
        password: '',
      },
      loginStyle:{
          'background-image': `url(${require('@/assets/custom-images/bg/bg-login.png')})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        },
      loadingShow: true,

      disabledButton: false,
      msgButton: 'Entrar',

      formErrors: {},

      alert: {
        show: false,
        message: '',
        variant: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    if(this.$route.params.errorEmail) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Falha ao verificar o E-mail',
          icon: 'MailIcon',
          text: 'A confirmação de e-mail falhou tente novamente ou contacte o suporte.',
          variant: 'danger',
        },
      },
      {
        timeout: 8000,
      })
    }
    this.$helpers.indexablePage()
  },
  methods: {
    async login() {
      if (this.validation()) {
        this.buttonLoading(true)

        await this.$store.dispatch('sessions/login', this.auth)
          .then(response => {
            const status = response.status
            const token = response.data.auth.access_token
            const userData = response.data
            if (status === 200 && token) {
              this.abilitySessionAndRedirect(userData)
            }
          })
          .catch(error => {
            this.errorHandling(error.response)
          })
      }

      this.buttonLoading(false)
    },

    abilitySessionAndRedirect(userData) {
      if (!userData.ability || userData.ability.length === 0) {
        clearStore()
        this.$router.replace({ name: 'misc-not-enabled' })

        return false
      }

      if (userData.status) {
        userData.status = 'active'
      }

      this.$ability.update(userData.ability)

      this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Bem-vindo(a) ${userData.fullName}`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: '',
            },
          })
        })
    },

    errorHandling(response) {
      const { status } = response
      const message = response.data.errors || 'E-mail ou senha incorretos.'

      if (status === 401) {
        this.showAlert(
          message,
          'danger',
        )
      } else {
        this.showAlert(
          'Não foi possível realizar sua solicitação!',
          'danger',
        )
      }
    },

    validation() {
      this.emailValidate(this.auth.email)

      this.strValidate({
        input: this.auth.password,
        message: this.passwordMsg,
        field: 'password',
      })

      if (this.formValidationRun()) {
        return true
      }
      this.formErrors = {}
      this.formErrors = this.formValidationErrors

      return false
    },

    showAlert(message, variant) {
      this.alert.show = true
      this.alert.message = message
      this.alert.variant = variant
    },

    buttonLoading(disabled) {
      if (disabled) {
        this.disabledButton = true
        this.msgButton = 'Processando...'
      } else {
        this.disabledButton = false
        this.msgButton = 'Entrar'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.initial-alert {
  border-left: 2px solid var(--2-opacity-color-primary-65, rgba(39, 114, 192, 0.65));
  background: var(--2-opacity-color-primary-6, rgba(39, 114, 192, 0.06));
}
</style>
