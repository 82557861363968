<template>
    <b-nav-item
    @click="openTourModal"
    >
        <div 
        class="d-flex align-items-center bg-light-secondary rounded p-5px" >
            <span class="d-none d-md-block mr-5px font-weight-bolder text-secondary">Tutorial de Uso</span>
            <feather-icon
              size="21"
              icon="BookOpenIcon"
            />
        </div>
        <TourStatusModal :modalType="modalType" @closeModal="hideModal" />
    </b-nav-item>
  </template>
  
  <script>
  import { BNavItem } from 'bootstrap-vue'
  import TourStatusModal from '@/views/components/custom/Tour/modal/TourStatusModal.vue';
  import { getTourModalType } from '@/@core/utils/store/getStore';
  import { setTourModalType } from '@/@core/utils/store/setStore';
  
  export default {
    components: {
      BNavItem,
      TourStatusModal
    },
    methods: {
      hideModal(){
        setTourModalType(null);
      },
      openTourModal() {
        this.redirectToHome();
        setTourModalType('Iniciou');
      },
      redirectToHome() {
        if(this.$route.name != 'home') {
          this.$router.push({ name: 'home' })
        }
      }
    },
    computed: {
      modalType() {
        return getTourModalType()
      }
    },
    watch: {
      modalType(val) {
        if(val){
          this.$bvModal.show('tour-modal');
        }else{
          this.$bvModal.hide('tour-modal');
        }
      }
    }

  }
  </script>
  