import { getInfoUserDepartamento } from '@core/utils/store/getStore'
import { actions, subjects } from '@/libs/acl/rules'
import { getUserData } from '@/auth/utils'

export default [
  {
    path: '/inicio',
    name: 'home',
    component: () => import('@/views/pages/home/Home'),
    meta: {
      resource: 'ACL',
      subject: subjects.HOME_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-departamento',
    name: 'departamento-register',
    component: () => import(
      '@/views/custom-pages/DepartamentoRegional/Register-departamento.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/editar-departamento',
    name: 'departamento-edit',
    component: () => import('@/views/custom-pages/DepartamentoRegional/Edit-departamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/lista-departamento',
    name: 'departamento-list',
    component: () => import('@/views/custom-pages/DepartamentoRegional/List-departamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-departamento',
    name: 'departamento-view',
    component: () => import('@/views/custom-pages/DepartamentoRegional/View-departamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DEPARTAMENTO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-departamento-regiao',
    name: 'departamento-view-regiao',
    component: () => import('@/views/custom-pages/DepartamentoRegional/View-departamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DEPARTAMENTO_SESI_REGIAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
      linkItems: [
        {
          name: getInfoUserDepartamento('sigla'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/editar-departamento-regiao',
    name: 'departamento-edit-regiao',
    component: () => import('@/views/custom-pages/DepartamentoRegional/Edit-departamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DEPARTAMENTO_SESI_REGIAO_MENUS_ROTAS,
      action: actions.GERENCIAR,
      linkItems: [
        {
          name: `Editar: ${getInfoUserDepartamento('sigla')}`,
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadastrar-unidade',
    name: 'unidade-register',
    component: () => import('@/views/custom-pages/UnidadeOperacional/CadastrarUnidade.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.UNIDADE_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/listar-unidades',
    name: 'unidade-list',
    component: () => import('@/views/custom-pages/UnidadeOperacional/ListarUnidade.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.UNIDADE_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-unidade',
    name: 'unidade-edit',
    component: () => import('@/views/custom-pages/UnidadeOperacional/EditarUnidade.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.UNIDADE_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/visualizar-unidade',
    name: 'unidade-view',
    component: () => import('@/views/custom-pages/UnidadeOperacional/VisualizarUnidade.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.UNIDADE_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-ponto-atendimento',
    name: 'ponto-atendimento-list',
    component: () => import(
      '@/views/custom-pages/PontoAtendimento/List-ponto-atendimento.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-ponto-atendimento',
    name: 'ponto-atendimento-register',
    component: () => import(
      '@/views/custom-pages/PontoAtendimento/Register-ponto-atendimento.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/editar-ponto-atendimento',
    name: 'ponto-atendimento-edit',
    component: () => import(
      '@/views/custom-pages/PontoAtendimento/Edit-ponto-atendimento.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/visualizar-ponto-atendimento',
    name: 'ponto-atendimento-view',
    component: () => import(
      '@/views/custom-pages/PontoAtendimento/View-ponto-atendimento.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.PONTO_ATENDIMENTO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-campanha',
    name: 'register-campanha',
    component: () => import(
      '@/views/custom-pages/Campanhas/RegisterCampanha/Register-campaign.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.CAMPANHAS_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/listar-campanhas',
    name: 'campanha-list',
    component: () => import('@/views/custom-pages/Campanhas/List/Index'),
    meta: {
      resource: 'ACL',
      subject: subjects.CAMPANHAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-campanha',
    name: 'campanha-edit',
    component: () => import('@/views/custom-pages/Campanhas/Edit/Index.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.CAMPANHAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/termo-de-adesao',
    name: 'adhesion-contract',
    component: () => import(
      '@/views/custom-pages/Campanhas/AdhesionContract/Adhesion-contract.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_TERMO_DR,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-campanha',
    name: 'campaign-show',
    component: () => import('@/views/custom-pages/Campanhas/Show/Show-campaign.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.CAMPANHAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-vacinas',
    name: 'vacina-list',
    component: () => import('@/views/custom-pages/vacina/List-vacina.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.VACINA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-vacina',
    name: 'vacina-register',
    component: () => import('@/views/custom-pages/vacina/Register-vacina.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.VACINA_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/editar-vacina',
    name: 'vacina-edit',
    component: () => import('@/views/custom-pages/vacina/Edit-vacina.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.VACINA_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/visualizar-vacina',
    name: 'vacina-show',
    component: () => import('@/views/custom-pages/vacina/Show-vacina.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.VACINA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/gerenciar-usuarios/sesi/listar',
    name: 'usuario-list',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioAdministrativo/List.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/gerenciar-usuarios/sesi/novo',
    name: 'usuario-register',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioAdministrativo/Insert.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/gerenciar-usuarios/sesi/editar',
    name: 'usuario-edit',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioAdministrativo/Update.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/gerenciar-usuarios/sesi/detalhes',
    name: 'usuario-view',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioAdministrativo/View.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/gerenciar-usuarios/empresa/listar',
    name: 'usuario-empresa-list',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioEmpresa/List.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/gerenciar-usuarios/empresa/novo',
    name: 'usuario-empresa-register',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioEmpresa/Insert.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/gerenciar-usuarios/empresa/editar',
    name: 'usuario-empresa-edit',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioEmpresa/Update.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/gerenciar-usuarios/empresa/sem-vinculo/editar',
    name: 'usuario-empresa-sem-vinculo-edit',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioEmpresa/UpdateNoCompany.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/gerenciar-usuarios/empresa/detalhes',
    name: 'usuario-empresa-view',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioEmpresa/View.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/gerenciar-usuarios/empresa/sem-vinculo/detalhes',
    name: 'usuario-empresa-sem-vinculo-view',
    component: () => import('@/views/custom-pages/gerenciarUsuarios/usuarioEmpresa/ViewNoCompany.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-doencas',
    name: 'doenca-list',
    component: () => import('@/views/custom-pages/doenca/List-doenca.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DOENCA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-doenca',
    name: 'doenca-register',
    component: () => import('@/views/custom-pages/doenca/Register-doenca.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DOENCA_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/editar-doenca',
    name: 'doenca-edit',
    component: () => import('@/views/custom-pages/doenca/Edit-doenca.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DOENCA_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/visualizar-doenca',
    name: 'doenca-show',
    component: () => import('@/views/custom-pages/doenca/Show-doenca.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.DOENCA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-aviso',
    name: 'aviso-list',
    component: () => import('@/views/custom-pages/aviso/List-aviso.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AVISOS_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-aviso',
    name: 'aviso-register',
    component: () => import('@/views/custom-pages/aviso/Register-aviso.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AVISOS_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/visualizar-aviso',
    name: 'aviso-show',
    component: () => import('@/views/custom-pages/aviso/Show-aviso.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AVISOS_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-aviso',
    name: 'aviso-edit',
    component: () => import('@/views/custom-pages/aviso/Edit-aviso.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AVISOS_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/ajuda-e-suporte/:categoria?',
    name: 'faq-ajuda-e-suporte',
    component: () => import('@/views/custom-pages/GerenciarAjuda/Faq.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-ajuda',
    name: 'ajuda-list',
    component: () => import('@/views/custom-pages/GerenciarAjuda/List-ajuda.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-ajuda',
    name: 'ajuda-view',
    component: () => import('@/views/custom-pages/GerenciarAjuda/View-ajuda.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-ajuda',
    name: 'ajuda-register',
    component: () => import('@/views/custom-pages/GerenciarAjuda/Register-ajuda.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/editar-ajuda',
    name: 'ajuda-edit',
    component: () => import('@/views/custom-pages/GerenciarAjuda/Edit-ajuda.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AJUDA_SUPORTE_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/minha-conta',
    name: 'minha-conta',
    component: () => import('@/views/custom-pages/minha-conta/Minha-conta.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.HOME_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/politica-de-privacidade',
    name: 'politica-privacidade',
    component: () => import(
      '@/views/custom-pages/politica-privacidade/PoliticaPrivacidade.vue'
    ),
    meta: {
      layout: Object.keys(getUserData()).length > 0 ? 'vertica' : 'full',
      resource: Object.keys(getUserData()).length > 0 ? 'ACL' : 'AUTH',
      subject: subjects.HOME_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-empresas',
    name: 'empresa-list',
    component: () => import('@/views/custom-pages/empresa/List-empresa.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.EMPRESAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-empresa',
    name: 'empresa-register',
    component: () => import('@/views/custom-pages/empresa/Index.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.EMPRESAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-empresa',
    name: 'empresa-edit',
    component: () => import('@/views/custom-pages/empresa/Edit-empresa.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.EMPRESAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-empresa',
    name: 'empresa-show',
    component: () => import(
      '@/views/custom-pages/empresa/showEmpresaAbas/Show-dados-empresa.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.EMPRESAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-empresa-contato',
    name: 'empresa-contato-show',
    component: () => import(
      '@/views/custom-pages/empresa/showEmpresaAbas/Show-contato-empresa.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.EMPRESAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-empresa-atendimento',
    name: 'empresa-atendimento-show',
    component: () => import(
      '@/views/custom-pages/empresa/showEmpresaAbas/Show-atendimento-empresa.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.EMPRESAS_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-colaboradores',
    name: 'empresa-colaborador-list',
    component: () => import('@/views/custom-pages/colaborador/List-colaborador.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.COLABORADOR_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-colaborador',
    name: 'empresa-colaborador-register',
    component: () => import('@/views/custom-pages/colaborador/Register-colaborador.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.COLABORADOR_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/visualizar-colaborador',
    name: 'empresa-colaborador-show',
    component: () => import('@/views/custom-pages/colaborador/Show-colaborador.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.COLABORADOR_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-colaborador',
    name: 'empresa-colaborador-edit',
    component: () => import('@/views/custom-pages/colaborador/Edit-colaborador.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.COLABORADOR_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/listar-importacoes-colaborador',
    name: 'empresa-colaborador-import-list',
    component: () => import('@/views/custom-pages/colaborador/List-import-colaborador.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.COLABORADOR_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/importar-colaboradores',
    name: 'empresa-colaborador-import',
    component: () => import('@/views/custom-pages/colaborador/Import-colaborador.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.COLABORADOR_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },

  {
    path: '/listar-estoque',
    name: 'estoque-list',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/EstoqueFisico/List-estoque.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_FISICO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-entrada',
    name: 'entrada-list',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Entrada/List-entrada.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_ENTRADA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-entrada',
    name: 'entrada-register',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Entrada/Register-entrada.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_ENTRADA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-entrada',
    name: 'entrada-show',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Entrada/Show-entrada.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_ENTRADA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/selecionar-empresa-vacinacao',
    name: 'empresa-vacinacao-select',
    component: () => import('@/views/custom-pages/Vacinacao/Select-Empresa.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/resumo-registro-vacinacao',
    name: 'resumo-registro-vacinacao',
    component: () => import('@/views/custom-pages/Vacinacao/Pages/ResumoRegistroVacinacao/ResumoRegistroVacinacao.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/listar-registro-vacinacao',
    name: 'registro-vacinacao-list',
    component: () => import('@/views/custom-pages/Vacinacao/Pages/ListRegistro/List-registro-vacinacao.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/listar-vacinacao',
    name: 'vacinacao-list',
    component: () => import('@/views/custom-pages/Vacinacao/Pages/ListRegistro/List-vacinacao.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.REGISTRO_VACINACAO_SESI_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-transferencia',
    name: 'transferencia-list',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Transferencia/List-transferencia.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-transferencia',
    name: 'transferencia-register',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Transferencia/Register-transferencia.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-transferencia',
    name: 'transferencia-edit',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Transferencia/Edit-transferencia.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-transferencia',
    name: 'transferencia-show',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Transferencia/Show-transferencia.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_TRANSFERENCIA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-ajuste-estoque',
    name: 'ajuste-estoque-list',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/AjusteEstoque/List-ajuste-estoque.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_AJUSTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-ajuste-estoque',
    name: 'ajuste-estoque-show',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/AjusteEstoque/Show-ajuste-estoque.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_AJUSTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-lote',
    name: 'lote-list',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Lote/ListLote.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_LOTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-lote',
    name: 'lote-show',
    component: () => import(
      '@/views/custom-pages/GerenciarEstoqueLote/Lote/ShowLote.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.ESTOQUE_LOTE_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-usuarios-empresa',
    name: 'empresa-usuario-list',
    component: () => import('@/views/custom-pages/CompanyUser/CompanyUsersList.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_EMPRESA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-usuario-empresa',
    name: 'empresa-usuario-register',
    component: () => import('@/views/custom-pages/CompanyUser/RegisterCompanyUser.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_EMPRESA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-usuario-empresa',
    name: 'empresa-usuario-show',
    component: () => import('@/views/custom-pages/CompanyUser/ShowCompanyUser.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_EMPRESA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-usuario-empresa',
    name: 'empresa-usuario-edit',
    component: () => import('@/views/custom-pages/CompanyUser/EditCompanyUser.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.USUARIO_EMPRESA_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/listar-agendamentos',
    name: 'agendamento-list',
    component: () => import('@/views/custom-pages/agendamento/Listar-agendamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AGENDAMENTO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-agendamento',
    name: 'agendamento-edit',
    component: () => import('@/views/custom-pages/agendamento/Editar-agendamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AGENDAMENTO_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/cadastrar-agendamento',
    name: 'agendamento-register',
    component: () => import('@/views/custom-pages/agendamento/Cadastrar-agendamento.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.AGENDAMENTO_MENUS_ROTAS,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/exportacao-smd',
    name: 'export-smd',
    component: () => import('@/views/custom-pages/relatorio/Relatorio-smd.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.RELATORIO_SMD_MENU_ROTAS_VISUALIZAR,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/suporte/jobs-com-falha',
    name: 'jobs-com-falha-list',
    component: () => import('@/views/custom-pages/Suporte/JobsComFalha/List.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_GERENCIAMENTO_FILA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/suporte/limpeza-cache-regras',
    name: 'limpeza-cache-regras-list',
    component: () => import('@/views/custom-pages/Suporte/LimpezaCacheRedis/List.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_LIMPEZA_CACHE,
      action: actions.GERENCIAR,
    },
  },
  {
    path: '/suporte/usuarios-integracao',
    name: 'usuarios-integracao-list',
    component: () => import('@/views/custom-pages/Suporte/UsuariosIntegracao/List.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_GERENCIAMENTO_USUARIOS_INTEGRACOES,
      action: actions.VISUALIZAR,
    },
  },

  {
    path: '/suporte/usuarios-integracao/novo',
    name: 'usuarios-integracao-novo',
    component: () => import('@/views/custom-pages/Suporte/UsuariosIntegracao/Insert.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_GERENCIAMENTO_USUARIOS_INTEGRACOES,
      action: actions.INSERIR,
    },
  },
  {
    path: '/suporte/usuarios-integracao/editar',
    name: 'usuarios-integracao-atualizar',
    component: () => import('@/views/custom-pages/Suporte/UsuariosIntegracao/Update.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_GERENCIAMENTO_USUARIOS_INTEGRACOES,
      action: actions.ATUALIZAR,
    },
  },
  {
    path: '/listar-grupos-empresas',
    name: 'group-company-list',
    component: () => import('@/views/custom-pages/GrupoEmpresa/ListGroupCompany.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },

  },
  {
    path: '/criar-grupo-empresa',
    name: 'create-company-group',
    component: () => import('@/views/custom-pages/GrupoEmpresa/CreateGroupCompany.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },

  },
  {
    path: '/editar-grupo-empresa',
    name: 'edit-company-group',
    component: () => import('@/views/custom-pages/GrupoEmpresa/EditGroupCompany.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/criar-negocio',
    name: 'create-business',
    component: () => import('@/views/custom-pages/GrupoEmpresa/Business/CreateBusiness.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-negocio',
    name: 'view-business',
    component: () => import('@/views/custom-pages/GrupoEmpresa/Business/viewGroupBusiness.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-negocio',
    name: 'edit-business',
    component: () => import('@/views/custom-pages/GrupoEmpresa/Business/EditGroupBusiness.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-grupo-empresa',
    name: 'view-company-group',
    component: () => import('@/views/custom-pages/GrupoEmpresa/ViewGroupCompany.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_GRUPO_EMPRESA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/cadastrar-campanha-nacional',
    name: 'register-campanha-nacional',
    component: () => import(
      '@/views/custom-pages/campanha-nacional/RegisterCampanha/Register-campaign.vue'
    ),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL,
      action: actions.INSERIR,
    },
  },
  {
    path: '/listar-campanhas-nacional',
    name: 'campanha-list-nacional',
    component: () => import('@/views/custom-pages/campanha-nacional/List/Index'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_COORDENADOR,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/editar-campanha-nacional',
    name: 'campanha-edit-nacional',
    component: () => import('@/views/custom-pages/campanha-nacional/Edit/Edit-national-campaign.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_COORDENADOR,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/visualizar-campanha-nacional',
    name: 'campaign-show-nacional',
    component: () => import('@/views/custom-pages/campanha-nacional/Show/Show-campaign.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_CAMPANHA_BASE_NACIONAL_COORDENADOR,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/exportacao-relatorios',
    name: 'export-relatorios',
    component: () => import('@/views/custom-pages/relatorio/Relatorio-exportar.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.RELATORIO_EXPORTACAO_MENU_ROTAS_VISUALIZAR,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/suporte/pessoa-fisica',
    name: 'pessoa-fisica-list',
    component: () => import('@/views/custom-pages/Suporte/PessoaFisica/List.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_GERENCIAMENTO_PESSOA_FISICA,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/suporte/pessoa-fisica',
    name: 'pessoa-fisica-edit',
    component: () => import('@/views/custom-pages/Suporte/PessoaFisica/Update.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.SUPORTE_GERENCIAMENTO_PESSOA_FISICA,
      action: actions.VISUALIZAR,
    },
  },

]
