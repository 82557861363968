<template>
  <div class="content-wrapper">
    <PageHeader screenName="Transferência" :linkItems="linkItems"/>

    <ConfirmModalTransferencia
        :modalShow="modal.showModal"
        :typeModal="modal.typeModal"
        @confirmed="closeModalAction"
    />

    <ConfirmModalEstoque
        :infoText="modalConfirm.infoText"
        :action="modalConfirm.action"
        :modalShow="modalConfirm.showModal"
        :typeModal="modalConfirm.typeModal"
        @canceled="modalConfirm.canceled"
        @confirmed="modalConfirm.confirmed"
    />

    <main class="card">
      <b-row class="p-2">
        <b-col lg="4" md="6" sm="7">
          <b-button @click="redirectToRegister()" variant="primary-button">
            <feather-icon
                icon="PlusIcon"
                class="mr-50"
            />
            <span class="align-middle">Nova transferência</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="pt-1 px-2">
        <b-col lg="3" md="6" sm="12">
          <b-form-group
              label="Unidade Operacional Origem"
              label-for="origem-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="origem-input"
                v-model="search.origem"
                :options="unidadeSelect"
                label="descricao_unidade"
                placeholder="Selecione uma unidade"
                :clearable="true"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6" sm="12">
          <b-form-group
              label="Unidade Operacional Destino"
              label-for="destino-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="destino-input"
                v-model="search.destino"
                :options="destinationUnitSelect"
                label="descricao_unidade"
                placeholder="Selecione uma unidade"
                :clearable="true"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6" sm="12">
          <b-form-group
              label="Status"
              label-for="status-input"
              label-class="font_size_label_estoque"
          >
            <v-select
                id="status-input"
                multiselect
                multiple
                v-model="search.status"
                variant="custom"
                placeholder="Selecione um ou mais"
                :options="statusSelect"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6" sm="12">
          <b-form-group
              label="Nota fiscal de transferência"
              label-for="nota-input"
              label-class="font_size_label_estoque"
          >
            <b-form-input
                id="nota-input"
                v-model="search.nota_fiscal"
                placeholder="Digite o número da nota fiscal"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-button @click="filter" variant="primary-button">
          <feather-icon
              icon="SearchIcon"
              class="mr-50"
          />
          <span class="align-middle">Pesquisar</span>
        </b-button>

        <b-button type="reset" class="ml-2 cor_botao" @click="cleanSearch" variant="outline-primary">
          <feather-icon
              icon="XIcon"
              class="mr-50"
          />
          <span class="align-middle">Limpar</span>
        </b-button>
      </b-row>
      <div v-if="loadingData" class="d-flex justify-content-center my-3">
        <div class="spinner-border text-custom-blue" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <hr>
      <div>
        <section class="filters pb-1 px-2" v-if="!loadingData">
          <b-row align-h="between">
            <b-col lg="6" md="6" sm="6">
              <div class="d-flex justify-center-center align-items-center">
                <span class="mr-1">Mostrar</span>
                <v-select
                    id="tableSize"
                    v-model="paginationData.defaultSize"
                    @input="updateTableSize($event)"
                    :options="table.optionsSize"
                    :clearable="false"
                    style="width: 90px;"
                />
              </div>
            </b-col>

            <b-col lg="6" md="6" sm="6">
              <ButtonDownloadSpreadsheet
                  type="Transferencia"
                  :parameters="paramsTransferencia"
                  :size="size"
                  :isEmpty="table.items.length === 0 || search.origem === ''"
                  tooltip-text="Exportação de planilha de transferência"
              />
            </b-col>
          </b-row>
        </section>
        <section class="list-colaborador-import" v-if="!loadingData">
          <b-table
              id="tableColaboradorImport"
              responsive
              :stickyColumn="true"
              :busy.sync="filtering"
              :fields="table.fields"
              :items="table.items"
              @sort-changed="ordenarColuna"
              no-local-sorting
              :no-sort-reset="true"
          >
            <template #cell(codigo)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                                  v-b-tooltip.hover.rightbottom="{ variant: 'light' }"
                                  :title="row.item.codigo"
                            >
                                #{{ row.item.codigo }}
                            </span>
            </template>
            <template #cell(numero_nota_fiscal)="row">
                            <span class="font-weight-bold">
                                {{ row.item.numero_nota_fiscal }}
                            </span>
            </template>
            <template #cell(situacao)="row">
                            <span v-if="userUnits.includes(row.item.id_unidade_destino)"
                                  class="font-weight-bold custom rounded-pill"
                                  :class="formatString(row.item.ultima_situacao ? row.item.ultima_situacao : row.item.situacao)">
                                {{ row.item.ultima_situacao ? row.item.ultima_situacao : row.item.situacao }}
                            </span>
              <span v-else class="font-weight-bold custom rounded-pill" :class="formatString(row.item.situacao)">
                               {{ row.item.situacao }}
                            </span>
            </template>
            <template #cell(numero_lote)="row">
              {{ row.item.itens[0].lote_unidade_origem.lote.numero_lote }}
            </template>
            <template #cell(quantidade)="row">
              {{ row.item.itens[0].quantidade }}
            </template>
            <template #cell(ultima_alteracao)="row">
                            <span class="font-weight-bold">
                                {{
                                formatDate(row.item.ultima_alteracao ? row.item.ultima_alteracao : row.item.cadastro_movimentacao, 'DD/MM/YYYY')
                              }}
                            </span>
            </template>
            <template #cell(origem)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                {{ row.item.descricao_unidade_origem ? row.item.descricao_unidade_origem : '-' }}
                            </span>
            </template>
            <template #cell(destino)="row">
                            <span class="font-weight-bold comprimento_maximo_celulas"
                            >
                                {{ row.item.descricao_unidade_destino ? row.item.descricao_unidade_destino : '-' }}
                            </span>
            </template>
            <template #cell(actions)="row">
              <div v-if="uoEdit">
                <feather-icon
                    id="transferencia-show"
                    role="button"
                    class="text-custom-blue"
                    icon="EyeIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Detalhes da transferência</b>"
                    @click.prevent="redirectToShow(row.item)"
                />
                <feather-icon
                    v-if="userUnits.includes(row.item.id_unidade_destino) && row.item.ultima_situacao == 'Pendente'"
                    id="transferencia-aceite"
                    role="button"
                    class="text-success ml-1 mr-1"
                    icon="CheckCircleIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Aceitar transferência</b>"
                    @click.prevent="openModalConfirmAccept(row.item)"
                />
                <feather-icon
                    id="transferencia-recusa"
                    v-if="userUnits.includes(row.item.id_unidade_destino) && row.item.ultima_situacao == 'Pendente'"
                    role="button"
                    class="text-danger"
                    icon="XCircleIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Recusar transferência</b>"
                    @click.prevent="openModalConfirmDecline(row.item)"
                />
                <feather-icon
                    id="transferencia-edit"
                    v-if="userUnits.includes(row.item.id_unidade_origem) && row.item.ultima_situacao == 'Recusado'"
                    role="button"
                    class="text-custom-blue ml-1"
                    icon="EditIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Editar transferência</b>"
                    @click.prevent="redirectToEdit(row.item)"
                />
              </div>
              <div v-if="drEdit">
                <feather-icon
                    id="transferencia-show"
                    role="button"
                    class="text-custom-blue"
                    icon="EyeIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Detalhes da transferência</b>"
                    @click.prevent="redirectToShow(row.item)"
                />
                <feather-icon
                    v-if="row.item.ultima_situacao == 'Pendente'"
                    id="transferencia-aceite"
                    role="button"
                    class="text-success ml-1 mr-1"
                    icon="CheckCircleIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Aceitar transferência</b>"
                    @click.prevent="openModalConfirmAccept(row.item)"
                />
                <feather-icon
                    id="transferencia-recusa"
                    v-if="row.item.ultima_situacao == 'Pendente'"
                    role="button"
                    class="text-danger"
                    icon="XCircleIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Recusar transferência</b>"
                    @click.prevent="openModalConfirmDecline(row.item)"
                />
                <feather-icon
                    id="transferencia-edit"
                    v-if="row.item.ultima_situacao == 'Recusado'"
                    role="button"
                    class="text-custom-blue ml-1"
                    icon="EditIcon"
                    size="18"
                    v-b-tooltip.hover.html
                    title="<b>Editar transferência</b>"
                    @click.prevent="redirectToEdit(row.item)"
                />
              </div>
            </template>
          </b-table>
          <div class="pb-2 px-1">
            <span v-if="without" class="ml-1">Nenhuma transferência encontrada</span>
            <b-col sm="12" class="mt-2">
              <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
            </b-col>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import moment from 'moment';
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue';
import InfograficoEstoqueCard from '@/views/components/custom/cards/InfograficoEstoqueCard.vue';
import InfoCard from '@/views/components/custom/cards/InfoCard.vue';
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue';
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ConfirmModalTransferencia from '@/views/components/custom/modals/estoque/ConfirmModalTransferencia.vue';
import ConfirmModalEstoque from '@/views/components/custom/modals/estoque/ConfirmModalEstoque.vue';
import ButtonDownloadSpreadsheet from "@/views/components/button-download-spreadsheet/ButtonDownloadSpreadsheet.vue";

export default {
  title: 'Lista de transferências',

  components: {
    ButtonDownloadSpreadsheet,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    TableSwitch,
    BCard,
    vSelect,
    CustomPagination,
    PageHeader,
    TimedAlert,
    VuePerfectScrollbar,
    BSpinner,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    InfograficoEstoqueCard,
    InfoCard,
    ConfirmModalTransferencia,
    ConfirmModalEstoque
  },

  directives: {
    'b-tooltip': VBTooltip
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Estoque',
          routeName: 'estoque-list',

        },
        {
          name: 'Transferência',
          active: true
        }
      ],
      alert: {
        errorMessage: '',
        show: false
      },
      modal: {
        showModal: false,
        typeModal: 'success'
      },
      modalConfirm: {
        showModal: false,
        typeModal: 'danger',
        infoText: '',
        action: '',
        item: null,
        confirmed: this.redirectToRegister,
        canceled: this.closeModalConfirm
      },
      settings: {
        maxScrollbarLength: 70,
        wheelSpeed: 0.2,
        wheelPropagation: false
      },
      timeID: null,
      filtering: false,
      loadingData: false,
      without: false,
      searchTerm: '',
      table: {
        fields: [
          {key: "codigo", label: "TRANSFERÊNCIA", sortable: false, class: 'mw-150'},
          {key: "numero_nota_fiscal", label: "NOTA FISCAL", sortable: false, class: 'mw-150'},
          {key: "situacao", label: "STATUS", sortable: false, class: 'mw-150'},
          {key: "numero_lote", label: "LOTE", sortable: false, class: 'mw-150'},
          {key: "ultima_alteracao", label: "ÚLTIMA ATUALIZAÇÃO", sortable: true, class: 'mw-150'},
          {key: "quantidade", label: "QTD DOSES", sortable: false, class: 'mw-150 text-left'},
          {key: "origem", label: "ORIGEM", sortable: true, class: 'mw-150'},
          {key: "destino", label: "DESTINO", sortable: true, class: 'mw-150'},
          {key: "actions", label: "AÇÕES", sortable: false, class: 'mw-150 col-1'}
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'id_movimentacao',
        order: 'desc'
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      search: {
        origem: '',
        destino: '',
        status: '',
        nota_fiscal: '',
      },
      statusSelect: ['Pendente', 'Aguardando', 'Recusado', 'Concluído', 'Cancelado'],
      unidadeSelect: [],
      userUnits: [],
      destinationUnitSelect: [],
      drEdit: false,
      uoEdit: false,
      size: 0
    }
  },
  mounted() {
    this.setSearchFilter();

    this.loadAllInfo();

    this.drEdit = this.$can(actions.VISUALIZAR, subjects.ESTOQUE_SESI_VACINAS_DR)
    this.uoEdit = this.$can(actions.VISUALIZAR, subjects.ESTOQUE_SESI_VACINAS_UO)
  },

  methods: {
    setSearchFilter() {
      Object.assign(this.search, this.getTransferencia)
      //this.setEmissaoPicker();
      //this.setLancamentoPicker();
    },
    loadAllInfo() {
      let parameters = {
        ativo: true
      }
      this.$http.get(this.$api.unidade(), {params: parameters}).then(({data}) => {
        this.unidadeSelect = data;
        this.userUnits = this.$helpers.retornaAtributoArray(data, 'id_unidade')
      });


      parameters = {
        ativo: true,
        departamento: true,
      }

      this.$http.get(this.$api.unidade(), {params: parameters}).then(({data}) => {
        this.destinationUnitSelect = data;
      });
    },
    loadData() {
      const parameters = this.paramsTransferencia
      this.filtering = true;

      this.$http.get(this.$api.movimentacao(), {params: parameters}).then(({data}) => {
        this.table.items = data.data;
        this.without = this.table.items.length === 0;
        this.loadingData = false;
        this.size = data.total;
        this.filtering = false;
        this.startPagination(data);
      })
    },
    acceptTransferencia() {
      this.$http.patch(this.$api.movimentacaoAceiteParameter(this.modalConfirm.item.id_movimentacao)).then(() => {
        this.loadData();
        this.closeModalConfirm();
        this.openModalAction('success');
      });
    },
    declineTransferencia() {
      this.$http.patch(this.$api.movimentacaoRecusaParameter(this.modalConfirm.item.id_movimentacao)).then(() => {
        this.loadData();
        this.closeModalConfirm();
        this.openModalAction('warning');
      });
    },
    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? 'desc' : 'asc';

      this.filtering = true;

      this.loadData();
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
      this.loadData();
    },

    updateTableSize(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }
      this.filtering = true;

      this.paginationData.currentPage = 1;
      this.loadData();
    },
    filter() {
      if (this.searchTerm.length >= 2 || this.searchTerm.length == 0) {
        this.without = false;

        clearTimeout(this.timeID);
        this.timeID = setTimeout(() => {
          this.filtering = true;
          this.loadData();
        }, 500);
      }
    },
    cleanSearch() {
      this.search.origem = ''
      this.search.destino = ''
      this.search.status = []
      this.search.nota_fiscal = ''
    },
    openModalAction(type) {
      this.modal.showModal = true;
      this.modal.typeModal = type;
    },
    closeModalAction() {
      this.modal.showModal = false;
    },
    formatString(string) {
      const formatted = string.normalize('NFD').replace(/([\u0300-\u036f]|[^a-zA-Z])/g, '');
      return formatted.toLowerCase();
    },
    formatDate(string, format) {
      return moment(string).format(format);
    },
    redirectToRegister() {
      this.$router.push({name: 'transferencia-register'});
    },
    openModalConfirmAccept(item) {
      this.modalConfirm.showModal = true;
      this.modalConfirm.typeModal = 'success';
      this.modalConfirm.action = 'aceitar as doses';
      this.modalConfirm.infoText = 'As doses serão inseridas no estoque da unidade.';
      this.modalConfirm.item = item;
      this.modalConfirm.confirmed = this.acceptTransferencia;
    },
    openModalConfirmDecline(item) {
      this.modalConfirm.showModal = true;
      this.modalConfirm.typeModal = 'warning';
      this.modalConfirm.action = 'rejeitar as doses';
      this.modalConfirm.infoText = 'As doses ficarão em aguardo até que ocorra a edição pela unidade de origem.';
      this.modalConfirm.item = item;
      this.modalConfirm.confirmed = this.declineTransferencia;
    },
    closeModalConfirm() {
      this.modalConfirm.showModal = false;
    },
    redirectToShow(item) {
      this.$router.push(
          {
            name: 'transferencia-show',
            params: {
              idTransferencia: item.id_movimentacao,
            }
          }
      );
    },
    redirectToEdit(item) {
      this.$router.push(
          {
            name: 'transferencia-edit',
            params: {
              idTransferencia: item.id_movimentacao,
            }
          }
      );
    },
  },

  computed: {
    getTransferencia() {
      return this.$store.getters['filtrosState/getEstoqueTransferencia'];
    },

    paramsTransferencia() {
      return {
        id_unidade_origem: this.search.origem ? this.search.origem.id_unidade : '',
        id_unidade_destino: this.search.destino ? this.search.destino.id_unidade : '',
        situacao: this.search.status,
        numero_nota_fiscal: this.search.nota_fiscal,
        termoPesquisa: this.searchTerm,
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order,
        tipo: [1]
      }
    }
  },

  watch: {
    search: {
      handler(newValue) {
        this.$store.commit('filtrosState/setEstoqueTransferencia', newValue);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

.font_size_label_estoque {
  font-size: 15px;
  margin-top: -5px;
}

.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}

.pendente {
  background-color: #fff3e8;
  color: #ff9110;
}

.aguardando {
  background-color: #e5eef7;
  color: #2772c0;
}

.recusado {
  background-color: #fceaea;
  color: #ea5455;
}

.concluido {
  background-color: #e5f8ed;
  color: #28c76f;
}

.cancelado {
  background-color: #eaeaed;
  color: #6e6b7b;
}

.altura_max_scrollbar {
  max-height: 100px;
  padding-right: 15px;
}

.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}

.hidden_overflow_tabela {
  overflow: hidden;
}

.cor_botao {
  color: #2772C0 !important;
  border: 1px solid #2772C0 !important;
}
</style>