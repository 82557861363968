<template>
  <b-form>
    <validation-observer ref="joinCampaignNacional">
      <b-row class="align-items-center">
        <b-col cols="4">
          <b-form-group
            label="Campanha nacional"
            label-for="campanha_descricao"
          >
            <validation-provider
              #default="{ errors }"
              name="Campanha nacional"
              :rules="botaoAvancar ? 'required' : null"
            >
              <v-select
                id="campanha_descricao"
                v-model="campanhaNacionalSelecionada"
                :clearable="true"
                label="descricao"
                :options="campanhasNacional"
                item-text="descricao"
                item-value="id_campanha"
                placeholder="Selecione uma campanha nacional"
                @input="getGroupByCampaignId"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Grupo de empresas"
            label-for="grupo_descricao"
          >
            <validation-provider
              #default="{ errors }"
              name="Grupo de empresas"
              :rules="botaoAvancar ? 'required' : null"
            >
              <v-select
                id="grupo_descricao"
                v-model="grupoDeEmpresaSelecionado"
                :clearable="true"
                label="descricao"
                :options="gruposDeEmpresas"
                item-text="descricao"
                item-value="id_grupo"
                :disabled="!campanhaNacionalSelecionada || disableGroupsSelect"
                placeholder="Selecione um grupo de empresas"
                @input="getOperatorByGroup"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Operador"
            label-for="operador_descricao"
          >
            <validation-provider
              #default="{ errors }"
              name="Operador"
              :rules="botaoAvancar ? 'required' : null"
            >
              <v-select
                id="operador_descricao"
                v-model="grupoOperadorSelecionado"
                :clearable="true"
                label="sigla_operador"
                :options="operadores"
                item-text="sigla_operador"
                item-value="id_grupo_operador"
                :disabled="!grupoDeEmpresaSelecionado || disableOperatorGroupsSelect"
                placeholder="Selecione um operador"
              >
                <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row v-if="botaoAvancar">
      <b-col cols="4">
        <b-button
          variant="custom-blue"
          :disabled="getDesabilitarEnvio"
          @click="getAdesoesNacionaisGrupo"
        >
          <feather-icon
            class="cursor-pointer"
            icon="CheckIcon"
          />
          &nbsp; Avançar
        </b-button>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-button
          variant="primary-button"
          @click="filterAdesoesNacionais()"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50"
          />
          <span class="align-middle">Pesquisar</span>
        </b-button>
        <b-button
          type="reset"
          class="ml-2 cor_botao min-width-button-reset"
          variant="outline-primary"
          @click="cleanSearch()"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Limpar</span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { getUserData } from '@/auth/utils'
import {
  triangleWarnMessage,
  warningInfoMessage,
} from '@/libs/sweetalerts'

import {
  AdesaoNacionalSetInit,
  SetChooseCampaignAdesaoNacional,
  SetChooseCompanyAdesaoNacional,
  SetChooseOperadorAdesaoNacional,
} from '@/views/custom-pages/adesao-nacional/helpers'
import {
  getAdesaoNacionalGrupoOperadorCampanha,
  getNationalCampaigns, getNationalPurchaseGroups, 
  getOperatorGroupCampaign,
} from '@/views/custom-pages/adesao-nacional/requests'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    vSelect,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    botaoAvancar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userData: getUserData(),
      gruposDeEmpresas: [],
      grupoDeEmpresaSelecionado: null,
      campanhasNacional: [],
      campanhaNacionalSelecionada: null,
      operadores: [],
      grupoOperadorSelecionado: null,
      required,

      disableGroupsSelect: true,
      disableOperatorGroupsSelect: true,
    }
  },

  computed: {
    getDesabilitarEnvio() {
      return (
        this.grupoDeEmpresaSelecionado == null
        || this.campanhaNacionalSelecionada == null
        || this.grupoOperadorSelecionado == null
      )
    },
  },

  watch: {
    campanhaNacionalSelecionada(value) {
      if (!value) {
        this.grupoDeEmpresaSelecionado = null
        this.grupoOperadorSelecionado = null
      }
    },

    grupoDeEmpresaSelecionado(value) {
      if (!value) {
        this.grupoOperadorSelecionado = null
      }
    },
  },

  mounted() {
    AdesaoNacionalSetInit({
      idUsuarioLogado: this.userData.id,
    })
    this.listarCampanhasNacionais()
  },

  methods: {
    filterAdesoesNacionais() {
      const filters = {
        idCampanha: this.campanhaNacionalSelecionada != undefined ? this.campanhaNacionalSelecionada.id_campanha : null,
        idGrupo: this.grupoDeEmpresaSelecionado != undefined ? this.grupoDeEmpresaSelecionado.id_grupo : null,
        idGrupoOperador: this.grupoOperadorSelecionado != undefined ? this.grupoOperadorSelecionado.id_grupo_operador : null,
      }

      this.$emit('filterAdesoesNacionais', filters)
    },

    cleanSearch() {
      this.campanhaNacionalSelecionada = null
      this.grupoDeEmpresaSelecionado = null
      this.grupoOperadorSelecionado = null
      this.gruposDeEmpresas = []
      this.operadores = []
    },

    async getAdesoesNacionaisGrupo() {
      this.$emit('setLoading', true)

      const idGrupoOperador = this.grupoOperadorSelecionado.id_grupo_operador
      const idCampanha = this.campanhaNacionalSelecionada.id_campanha

      await getAdesaoNacionalGrupoOperadorCampanha({ idGrupoOperador, idCampanha })
        .then(({ data }) => {
          if (data.empresas_adesoes.length > 0) {
            const error = 'Não é possível criar esta adesão nacional. Já existe uma adesão ativa vinculada à campanha selecionada.'

            this.resetPage()

            warningInfoMessage('', error, 'Entendido')
          } else {
            SetChooseCampaignAdesaoNacional(this.campanhaNacionalSelecionada)
            SetChooseCompanyAdesaoNacional(this.grupoDeEmpresaSelecionado)
            SetChooseOperadorAdesaoNacional(this.grupoOperadorSelecionado)

            this.$router.push({ name: 'pedido-adesao-nacional' })
          }
        })
        .catch(() => triangleWarnMessage('Não foi possível realizar a sua solicitação, se o erro persistir, entre em contato com o suporte.'))

      this.$emit('setLoading', false)
    },

    async listarCampanhasNacionais() {
      this.$emit('setLoading', true)

      const params = {
        situacao: ['Em andamento', 'Agendada'],
      }

      await getNationalCampaigns(params).then(({ data }) => {
        this.campanhasNacional = data
      })

      this.$emit('setLoading', false)
    },

    async getGroupByCampaignId() {
      if (!this.campanhaNacionalSelecionada) {
        this.gruposDeEmpresas = []
        return
      }

      this.grupoDeEmpresaSelecionado = null
      this.grupoOperadorSelecionado = null
      this.$refs.joinCampaignNacional.reset()

      this.disableGroupsSelect = true

      const params = {
        idCampanha: this.campanhaNacionalSelecionada.id_campanha,
      }

      await getNationalPurchaseGroups(params)
        .then(({ data }) => {
          this.gruposDeEmpresas = data
        })

      this.disableGroupsSelect = false
    },

    async getOperatorByGroup() {
      if (!this.grupoDeEmpresaSelecionado) {
        this.operadores = []
        return
      }

      this.grupoOperadorSelecionado = null
      this.$refs.joinCampaignNacional.reset()

      this.disableOperatorGroupsSelect = true

      const params = {
        idCampanha: this.campanhaNacionalSelecionada.id_campanha,
        idGrupo: this.grupoDeEmpresaSelecionado.id_grupo,
        relatorio: 0,
      }

      await getOperatorGroupCampaign(params)
        .then(response => {
          this.operadores = response.data
        })

      this.disableOperatorGroupsSelect = false
    },

    resetPage() {
      this.grupoDeEmpresaSelecionado = null
      this.campanhaNacionalSelecionada = null
      this.grupoOperadorSelecionado = null

      this.$refs.joinCampaignNacional.reset()
    },
  },
}
</script>
