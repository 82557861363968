import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData && !this.filterForm){
           this.table.items = [
            {
              "id_agendamento": 1,
              "nome_fantasia": "Empresa Tutorial",
              "cnpj": "00000000000000",
              "unidade_descricao": "Florianópolis",
              "data_aplicacao": "2025-01-01",
              "periodo": null,
              "situacao": "Agendado",
              "observacao": null,
              "quantidade_dose_prevista": 100,
              "quantidade_dose_aplicada": 90,
              "agendamento_periodos": [
                {
                  "id_agendamento_periodo": 1,
                  "descricao": "Matutino"
                }
              ]
            }
           ];
           this.loadingData = false
           this.filtering = false
           this.startPagination(
            {
              total: 1,
              from: 1,
              to: 1,
              current_page: 1
            }
           );
        }
    }
  };