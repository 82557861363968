import { render, staticRenderFns } from "./Add-novo-item-lote.vue?vue&type=template&id=86086f10&scoped=true&"
import script from "./Add-novo-item-lote.vue?vue&type=script&lang=js&"
export * from "./Add-novo-item-lote.vue?vue&type=script&lang=js&"
import style0 from "./Add-novo-item-lote.vue?vue&type=style&index=0&id=86086f10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86086f10",
  null
  
)

export default component.exports