<template>
  <b-overlay
      :show="loading"
      rounded="lg"
      opacity="0.6"
      class="w-100 mt-1"
  >
    <PageHeader screenName="Empresas do negócio" :linkItems="linkItems" />
    <div v-if="grupo != null">
      <main class="card hidden_overflow_tabela">
        <b-row class="m-1 d-flex">
          <b-col class="mt-2">
            <h2 class="text-custom-blue">
              Nome do negócio: {{ groupBusiness.descricao }}
            </h2>
          </b-col>
          <b-col
              lg="2"
              class="mt-2 button-edit"
          >
            <b-button
                class="ml-auto"
                variant="primary-button"
                @click="redirectToEdit(idGroupBusiness)"
            >
              <feather-icon
                  icon="EditIcon"
                  class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </b-col>
        </b-row>
        <div
            v-if="loadingData"
            class="d-flex justify-content-center my-3"
        >
          <div
              class="spinner-border text-custom-blue"
              role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </div>
            <div v-if="grupo != null">
              <CompanyBusinessList
                  :idGroupBusiness="idGroupBusiness"
                  :groupBusiness="groupBusiness"
              />
            </div>
      </main>
    </div>
  </b-overlay>
</template>

<script>
import {
  BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend, BTab, BTabs, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import {setIdCompanyGroup, setCompanyBusinessId, setCompanyBusiness} from '@core/utils/store/setStore'
import {getCompanyBusinessId, getIdCompanyGroup} from '@core/utils/store/getStore'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import { actions, subjects } from '@/libs/acl/rules'
import CompanyList from '@/views/custom-pages/GrupoEmpresa/ListViewGroupCompany/CompanyList.vue'
import DocumentList from '@/views/custom-pages/GrupoEmpresa/ListViewGroupCompany/DocumentList.vue'
import FilterCompanyList from '@/views/custom-pages/GrupoEmpresa/Components/FilterCompanyList.vue'
import FilterGroupList from '@/views/custom-pages/GrupoEmpresa/Components/FilterGroupList.vue'
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import CompanyBusinessList from "@/views/custom-pages/GrupoEmpresa/Components/CompanyBusinessList.vue";

export default {
  title: 'Ver grupo de empresas',

  components: {
    CompanyBusinessList,
    PageHeader,
    FilterGroupList,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    CustomPagination,
    BTab,
    BTabs,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    TableFilter,
    CompanyList,
    DocumentList,
    FilterCompanyList,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Listagem de grupos',
          routeName: 'group-company-list'
        },
        {
          name: 'Visualizar',
          routeName: '',
          active: true,
        },
      ],
      loading: false,
      loadingData: false,
      filtering: false,
      clear: false,
      errorApiSearchParameters: {
        identificador_empresa: null,
      },
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      lengthColumnMaximo: 28,
      grupo: null,
      filter: false,
      business: [],
      idGroupBusiness: null,
      groupBusiness: [],
    }
  },
  async mounted() {

    this.idGroupBusiness = getCompanyBusinessId()

    this.loading = true
    const hasIdEntityRouteParamIdGroup = this.$helpers.verifyIfHasIdEntityRouteParam(getIdCompanyGroup(), this.$route.params.idGrupo)
    const hasIdEntityRouteParamIdBusiness = this.$helpers.verifyIfHasIdEntityRouteParam(getCompanyBusinessId(), this.$route.params.idGroupBusiness)

    if (hasIdEntityRouteParamIdGroup) {
      setIdCompanyGroup(this.$route.params.idGrupo)
    }
    if(hasIdEntityRouteParamIdBusiness) {
      setCompanyBusinessId(this.$route.params.idGroupBusiness)
      this.idGroupBusiness = getCompanyBusinessId()
    }
    await this.loadInfoGroup(getIdCompanyGroup())
    this.groupBusiness = this.grupo.grupo_negocio.find(grupoNegocio => grupoNegocio.id_grupo_negocio == this.idGroupBusiness)
    this.verifyPermission()
    this.loading = false
  },

  methods: {
    async loadInfoGroup(idGrupo) {
      this.loading = true

      await this.$http.get(this.$api.getAndPutAndDeleteGrupo(idGrupo)).then(({ data }) => {
        this.grupo = data
      })
      this.loading = false
    },

    redirectToEdit(idGroupBusiness) {
      setCompanyBusinessId(idGroupBusiness)
      setCompanyBusiness(this.grupo.empresa_operador)
      this.$router.push(
          {
            name: 'edit-business',
            params: {
              idGroupBusiness: idGroupBusiness,
            },
          },
      )
    },

    verifyPermission() {
      return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
    },
  },
  updated() {
    this.clear = false
  },
}
</script>

<style lang="scss">

.table-company {
  background-color: #fff;
}

.button-edit {
  text-align: end;
}
</style>
