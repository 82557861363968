<template>
  <tour :steps="tourSteps">
  <div class="content-wrapper">
    <PageHeader screenName="Adicionar colaborador" :linkItems="linkItems" />
    <ConfirmaTermoModal
      :showModal="modalTermo.showModal"
      :IdEmpresa="empresa.id_empresa"
      @confirmed="getInfoForTerms"
      @errorModal="openErrorModal"
    />
    <ConfirmModal
      itemIdentifier="usuário"
      :modalShow="modal.showModal"
      :typeModal="modal.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalConfirm"
    />

    <ActionColaboradorModal
      itemIdentifier="colaborador"
      :modalShow="modalCpf.showModal"
      :typeModal="modalCpf.typeModal"
      :infoText="modalCpf.infoText"
      @confirmed="closeModalCpf"
    />

    <SucessoModal
      :modalShow="modalSucesso.showModal"
      :typeModal="modalSucesso.typeModal"
      :caseModal="modalSucesso.caseModal"
      @confirmed="redirectToList"
    />
    <ModalShowError
      :modalShow="modalError.showModal"
      :infoText="modalError.infoText"
      :typeModal="modalError.typeModal"
      @confirmed="redirectToCompanyPage"
    />
    <div id="empresa">
      <main class="card">
        <HeaderEmpresa :empresa="empresa" @confirmed="resolveRoute" />
        <section class="form p-2">
          <validation-observer ref="colaboradorRules">
            <b-form>
              <b-row>
                <b-col lg="4" md="4" sm="12">
                  <b-form-group label="CPF*">
                    <validation-provider
                      #default="{ errors }"
                      name="cpf"
                      rules="required"
                    >
                      <vue-cleave
                        id="cpf-input"
                        v-model="form.cpf"
                        :raw="false"
                        :options="option.cpf"
                        class="form-control"
                        placeholder="000.000.000-00"
                        name="cpf"
                        maxlength="14"
                        autocomplete="off"
                        @input="returnPessoa"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small v-if="form.errorApi.cpf" class="text-danger">
                        {{ form.errorApi.cpf }}
                      </small>
                      <small v-if="form.errorApi.error" class="text-danger">
                        {{ form.errorApi.error }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4" sm="12">
                  <b-form-group label="Data de nascimento*">
                    <validation-provider
                      #default="{ errors }"
                      name="data_nascimento"
                      rules="required"
                    >
                      <b-input-group>
                        <vue-cleave
                          id="data-nascimento-input"
                          v-model="input.data_nascimento"
                          :raw="false"
                          :options="option.data"
                          class="form-control"
                          placeholder="00/00/0000"
                          name="data_nascimento"
                          autocomplete="off"
                          :disabled="form.disabled"
                          @input="setNascimentoInput"
                        />
                        <b-input-group-append>
                          <b-form-datepicker
                            locale="pt-BR"
                            button-only
                            right
                            :hide-header="true"
                            v-model="form.data_nascimento"
                            :max="today"
                            class="form-control d-flex align-items-center"
                            label-help="Selecione a data de nascimento"
                            button-variant="custom-blue"
                            clearable
                            :disabled="form.disabled"
                            @input="setNascimentoPicker"
                          />
                        </b-input-group-append>
                      </b-input-group>
  
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small
                        v-if="form.errorApi.data_nascimento"
                        class="text-danger"
                      >
                        {{ form.errorApi.data_nascimento }}
                      </small>
                      <small v-if="form.errorApi.error" class="text-danger">
                        {{ form.errorApi.error }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4" sm="12">
                  <b-form-group label="Nome completo do colaborador*">
                    <validation-provider
                      #default="{ errors }"
                      name="nome"
                      rules="required"
                    >
                      <b-form-input
                        :state="
                          errors.length > 0 ||
                          form.errorApi.nome ||
                          form.errorApi.error
                            ? false
                            : null
                        "
                        id="nome-input"
                        v-model="form.nome"
                        placeholder="John Doe"
                        :disabled="form.disabled"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small v-if="form.errorApi.nome" class="text-danger">
                        {{ form.errorApi.nome }}
                      </small>
                      <small v-if="form.errorApi.error" class="text-danger">
                        {{ form.errorApi.error }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="4" md="4" sm="12">
                  <b-form-group label="Nome da mãe">
                    <validation-provider #default="{ errors }" name="nome_mae">
                      <b-form-input
                        :state="
                          errors.length > 0 || form.errorApi.nome_mae
                            ? false
                            : null
                        "
                        id="nome_mae-input"
                        v-model="form.nome_mae"
                        :disabled="disabled.nome_mae"
                        placeholder="Nome da mãe"
                      />
                      <small v-if="form.errorApi.nome_mae" class="text-danger">
                        {{ form.errorApi.nome_mae }}
                      </small>
                      <small v-if="form.errorApi.error" class="text-danger">
                        {{ form.errorApi.error }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4" sm="12">
                  <validation-provider
                    #default="{ errors }"
                    name="genero"
                    rules="required"
                  >
                    <b-form-group
                      label="Sexo*"
                      :state="
                        errors.length > 0 || form.errorApi.genero ? false : null
                      "
                    >
                      <v-select
                        id="genero"
                        v-model="form.genero"
                        :options="generoSelect"
                        label="topico"
                        placeholder="Selecione uma opção"
                        :clearable="true"
                        :disabled="disabled.genero"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small v-if="form.errorApi.genero" class="text-danger">
                        {{ form.errorApi.genero }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col lg="4" md="4" sm="12">
                  <validation-provider
                    #default="{ errors }"
                    name="categoria"
                    rules="required"
                  >
                    <b-form-group
                      label="Categoria*"
                      :state="
                        errors.length > 0 || form.errorApi.categoria
                          ? false
                          : null
                      "
                    >
                      <v-select
                        id="categoria"
                        v-model="form.categoria"
                        :options="categoriaSelect"
                        label="descricao"
                        placeholder="Selecione uma categoria"
                        :clearable="true"
                        :reduce="(option) => option.id_categoria"
                      />
                      <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                      </small>
                      <small v-if="form.errorApi.categoria" class="text-danger">
                        {{ form.errorApi.categoria }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="4" md="4" sm="12">
                  <NacionalidadeField
                    :key="form.renderNacionalidade"
                    :idNacionalidade="form.id_nacionalidade"
                    @updateNacionalidade="updateNacionalidade"
                    :errorApiIdNacionalidade="form.errorApi.id_nacionalidade"
                    customClass="register-colaborador"
                    :isDisabled="disabled.nacionalidade"
                  />
                </b-col>
                <b-col lg="4" md="4" sm="12">
                  <b-form-group label="Matrícula">
                    <validation-provider #default="{ errors }" name="matricula">
                      <b-form-input
                        :state="
                          errors.length > 0 ||
                          form.errorApi.matricula ||
                          form.errorApi.error
                            ? false
                            : null
                        "
                        id="matricula-input"
                        v-model="form.matricula"
                        placeholder="Identificação interna"
                      />
                      <small v-if="form.errorApi.matricula" class="text-danger">
                        {{ form.errorApi.matricula }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="4" sm="12">
                  <b-form-group label="Setor">
                    <validation-provider #default="{ errors }" name="setor">
                      <b-form-input
                        :state="
                          errors.length > 0 ||
                          form.errorApi.setor ||
                          form.errorApi.error
                            ? false
                            : null
                        "
                        id="setor-input"
                        v-model="form.setor"
                        placeholder="Área, setor ou departamento"
                      />
                      <small v-if="form.errorApi.setor" class="text-danger">
                        {{ form.errorApi.setor }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </section>
        <section class="buttons p-2">
          <b-button
            id="save-colaborador"
            class="mr-2 mb-2 mb-sm-0"
            variant="custom-blue"
            @click.prevent="validationForm(true)"
            :disabled="saving || savingAndNew"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Salvar colaborador</span>
            <span
              v-if="saving"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
          <b-button
            id="save-new-colaborador"
            class="mr-2 mb-2 mb-sm-0"
            variant="custom-blue"
            @click.prevent="validationForm(false)"
            :disabled="saving || savingAndNew"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Salvar e cadastrar um novo</span>
            <span
              v-if="savingAndNew"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
          <b-button
            id="cancel-register-colaborador"
            :disabled="saving || savingAndNew"
            variant="outline-danger"
            @click.prevent="openModalConfirm"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </section>
      </main>
    </div>
  </div>
</tour>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import moment from "moment";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ConfirmModal from "@/views/components/custom/modals/ConfirmModal.vue";
import ModalShowError from "@/views/components/custom/modals/ModalShowError.vue";
import SucessoModal from "@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue";
import ActionColaboradorModal from "@/views/components/custom/modals/ActionsColaboradorModal.vue";
import HeaderEmpresa from "@/views/components/custom/page-header/Header-empresa.vue";
import VueCleave from "vue-cleave-component";
import ConfirmaTermoModal from "@/views/components/custom/modals/ConfirmaTermoModal.vue";
import handleErrorsService from "./Services/HandleErrors";
import { getIdEmpresa } from "@core/utils/store/getStore";
import { setIdEmpresa } from "@core/utils/store/setStore";
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";
import VerificaAceiteTermoEmpresa from './Services/VerificaAceiteTermoEmpresa';
import tourMixin from "@/mixins/tourMixin";
import colaboradorTourMixin from "./mixins/colaboradorTourMixin";

export default {
  title: "Cadastrar colaborador",

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    TimedAlert,
    PageHeader,
    ConfirmModal,
    SucessoModal,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BCardText,
    ModalShowError,
    HeaderEmpresa,
    VueCleave,
    ActionColaboradorModal,
    ConfirmaTermoModal,
    NacionalidadeField,
  },
  mixins: [tourMixin, colaboradorTourMixin],
  data() {
    return {
      linkItems: [
        {
          name: "Empresa",
          routeName: "empresa-colaborador-list",
        },
        {
          name: "Carregando dados...",
          active: true,
        },
        {
          name: "Adicionar colaborador",
          active: true,
        },
      ],
      modal: {
        showModal: false,
        typeModal: "danger",
      },
      modalTermo: {
        showModal: false,
        typeModal: "erro-aviso",
        infoText: "",
      },
      modalSucesso: {
        showModal: false,
        typeModal: "success",
        caseModal: "criacao",
      },
      modalError: {
        showModal: false,
        typeModal: "erro-aviso",
      },
      modalCpf: {
        showModal: false,
        typeModal: "custom-blue",
        infoText: "",
      },
      eliminateItem: "Convênio",
      required,
      savedAlert: false,
      saving: false,
      savingAndNew: false,
      today: moment().format("YYYY-MM-DD"),
      option: {
        data: {
          date: true,
          delimiter: "/",
          datePattern: ["d", "m", "Y"],
        },
        cpf: {
          numericOnly: true,
          uppercase: true,
          delimiters: [".", ".", "-"],
          blocks: [3, 3, 3, 2],
        },
      },
      disabled: {
        nome_mae: false,
        nacionalidade: false,
        genero: false,
      },
      form: {
        disabled: false,
        cpf: "",
        nome: "",
        genero: "",
        data_nascimento: "",
        categoria: "",
        matricula: "",
        setor: "",
        nome_mae: "",
        id_nacionalidade: null,
        renderNacionalidade:0,
        errorApi: {
          cpf: "",
          nome: "",
          genero: "",
          data_nascimento: "",
          categoria: "",
          matricula: "",
          setor: "",
          error: "",
          nome_mae: "",
          id_nacionalidade: "",
        },
      },
      input: {
        data_nascimento: "",
      },
      generoSelect: [],
      categoriaSelect: [],
      empresa: {
        id_empresa: 0,
        nome: "",
        gerenciarColaboradores: false,
      },
      userData: this.$store.state.sessions.userData,
      profileEmpresa: false,
      empresaSetor: ["admin", "publi"],
    };
  },

  mounted() {
    if(this.loadData) {
      this.mountedInit()
    }
  },

  methods: {
    mountedInit() {
      const profiles = this.userData.perfis.map((profile) => profile.id_perfil);
    if (
      (getIdEmpresa() === null || typeof getIdEmpresa() == "undefined") &&
      typeof this.$route.params.idEmpresa == "undefined"
    ) {
      this.openModalError();
      return;
    }

    if (typeof this.$route.params.idEmpresa != "undefined") {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa);
      }
    }

    this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
      this.modalTermo.showModal = VerificaAceiteTermoEmpresa.verificaSeTermoPrecisaSerAceito(
        data.empresa_termo,
        profiles
      );

      this.empresa.id_empresa = data.id_empresa;
      this.empresa.nome = data["cnpj"] ? data["nome_fantasia"] : data["nome"];
      this.empresa.gerenciarColaboradores = data["pode_gerenciar_colaboradores"];
      this.linkItems[1].name = this.empresa.nome;
    });

    this.generoSelect = ["Masculino", "Feminino"];
    this.$http.get(this.$api.getEmpresaCategoria()).then(({ data }) => {
      this.categoriaSelect = data.filter(
        (item) => item.descricao !== this.eliminateItem
      );
    });
    },
    validationForm(redirectToList) {
      this.$refs.colaboradorRules.validate().then((success) => {
        if (success) {
          if (redirectToList) {
            this.saving = true;
          } else {
            this.savingAndNew = true;
          }
          this.saveColaborador(redirectToList);
        }
      });
    },

    saveColaborador(redirectToList) {
      const parameters = {
        cpf: this.formatCpf(this.form.cpf),
        nome: this.form.nome,
        genero: this.form.genero,
        data_nascimento: this.form.data_nascimento,
        id_empresa: this.empresa.id_empresa,
        ano: moment().format("YYYY"),
        id_categoria: this.form.categoria,
        matricula: this.form.matricula,
        setor: this.form.setor,
        nome_mae: this.form.nome_mae,
        id_nacionalidade: this.form.id_nacionalidade,
      };

      this.$http
        .post(this.$api.colaborador(), parameters)
        .then(() => {
          if (redirectToList) {
            this.openModalSucesso();
          } else {
            this.savedAlert = !this.savedAlert;
          }

          this.saving = false;
          this.savingAndNew = false;
          this.cleanForm();
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosColaborador(data, this.form.errorApi);

          this.saving = false;
          this.savingAndNew = false;
        });
    },

    cleanForm() {
      this.form.cpf = "";
      this.form.nome = "";
      this.form.genero = "";
      this.form.data_nascimento = "";
      this.form.categoria = "";
      this.form.matricula = "";
      this.form.setor = "";
      this.form.nome_mae = "";
      this.form.id_nacionalidade = null;
      this.form.renderNacionalidade += Math.random();

      this.form.errorApi.cpf = "";
      this.form.errorApi.nome = "";
      this.form.errorApi.genero = "";
      this.form.errorApi.data_nascimento = "";
      this.form.errorApi.categoria = "";
      this.form.errorApi.matricula = "";
      this.form.errorApi.setor = "";
      this.form.errorApi.nome_mae = "";
      this.form.errorApi.id_nacionalidade = "";

      this.$refs.colaboradorRules.reset();
    },

    returnPessoa() {
      if (this.form.cpf.length == 14) {
        this.form.disabled = true;

        this.$http
          .get(this.$api.getPessoaFisicaPorCpf(this.formatCpf(this.form.cpf)))
          .then((data) => {
            const pessoa = data.data;
            const colaboradorEmpresa = pessoa?.colaborador
              ? pessoa.colaborador.find((colaborador) => {
                  return (
                    colaborador.id_empresa == this.empresa.id_empresa &&
                    colaborador.ano == moment().format("YYYY")
                  );
                })
              : null;

            this.fillDataForm(data, pessoa, colaboradorEmpresa);
            this.form.errorApi.cpf = "";
          })
          .catch(({ response: { data } }) => {
            handleErrorsService.handleErrosColaborador(
              data,
              this.form.errorApi
            );
            this.form.disabled = false;
            this.disabled.nome_mae = false
            this.disabled.nacionalidade = false
            this.disabled.genero = false
          });
      } else {
        this.form.disabled = false;
        this.disabled.nome_mae = false
        this.disabled.nacionalidade = false
        this.disabled.genero = false
        this.form.nome = "";
        this.form.genero = "";
        this.form.data_nascimento = "";
        this.input.data_nascimento = "";
        this.form.errorApi.cpf = "";
      }
    },

    setNascimentoPicker() {
      this.input.data_nascimento = moment(this.form.data_nascimento).format(
        "DD/MM/YYYY"
      );
    },

    setNascimentoInput() {
      this.form.data_nascimento = moment(
        this.input.data_nascimento,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
    },

    redirectToList() {
      this.$router.push({ name: "empresa-colaborador-list" });
    },

    openModalConfirm() {
      this.modal.showModal = true;
    },

    closeModalConfirm() {
      this.modal.showModal = false;
    },

    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },

    openModalCpf(colaborador) {
      if (colaborador && !colaborador.ativo) {
        this.modalCpf.infoText =
          "O colaborador se encontra inativo na empresa, confira os dados e depois salve para reativar o colaborador";
      }

      this.modalCpf.showModal = true;
    },

    closeModalCpf() {
      this.modalCpf.showModal = false;
    },

    formatCpf(cpf) {
      return cpf.replace(/\D/g, "");
    },

    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa,
          },
        });
      }
    },

    getInfoForTerms() {
      this.modalTermo.showModal = false;
    },

    openErrorModal(data) {
      this.modalError.showModal = true;
      this.modalError.infoText = data.errors;
    },

    redirectToCompanyPage() {
      this.$router.push({ name: "empresa-show" });
    },

    fillDataForm(data, pessoa, colaborador) {
      if (data.status == 200) {
        this.openModalCpf(colaborador);
        this.form.nome = pessoa.nome;
        this.form.genero = pessoa.genero;
        this.form.data_nascimento = pessoa.data_nascimento;
        this.form.nome_mae = pessoa.nome_mae;
        this.form.id_nacionalidade = pessoa.id_nacionalidade;

        this.disabled.nome_mae = !!pessoa.nome_mae
        this.disabled.nacionalidade = !!pessoa.id_nacionalidade
        this.disabled.genero = !!pessoa.genero
        this.form.renderNacionalidade += Math.random();

        this.form.categoria = colaborador?.id_categoria
          ? colaborador.id_categoria
          : "";
        this.form.matricula = colaborador?.matricula
          ? colaborador.matricula
          : "";
        this.form.setor = colaborador?.empresa_setor
          ? colaborador.empresa_setor.descricao
          : "";

        this.setNascimentoPicker();
      } else {
        this.form.disabled = false;
        this.disabled.nome_mae = false
        this.disabled.nacionalidade = false
        this.disabled.genero = false
      }
    },

    updateNacionalidade(id_nacionalidade) {
      this.form.id_nacionalidade = id_nacionalidade;
    },
  },
};
</script>
