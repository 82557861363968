import { getTourWatchingTopic } from "@/@core/utils/store/getStore";
import { setTourMode, setTourSteps, setTourWatchingTopic, setTourModalType, setTourWatchingAllTutorials } from "@/@core/utils/store/setStore";
import router from '@/router';
import {
  getUserData
} from "@/auth/utils";
import $store from '@/store';
import $ability from '@/libs/acl/ability';
import { updateStatusTutorial } from "../functions/UpdateStatusFunctions";
import { actions, subjects } from "@/libs/acl/rules";


const companiesDashboard = [
    {
      target: '#company-dashboard',
      header: false,
      content: '<strong class="text-dark font-size-16">Painel de Todas as minhas empresas</strong><br><span>Este painel permite visualizar os dados de doses contratadas e o andamento da vacinação das campanhas que sua empresa participa.</span>',
      params: {
        placement: 'right'
      },
      highlight: true,
      actionOnSequence: () => {
        setTourMode(true);
        setTourSteps(viewVaccinatedRecords);
        router.push({
          name: 'vacinacao-list',
        })
      },
      actionOnPrevStep: () => {
        setTourMode(false);
        setTourSteps([]);
        setTourModalType('Adesao');
      }
    }
];
  
const viewVaccinatedRecords = [
    {
      target: '#vaccination-list',
      header: false,
      content: '<strong class="text-dark font-size-16">Ver Vacinados - Listar vacinados</strong><br><span>Nesta tela, é possível buscar os vacinados em uma campanha de vacinação e refinar a pesquisa com filtros adicionais.</span>',
      params: {
        placement: 'top'
      },
      highlight: true,
      actionOnPrevStep: () => {
        setTourSteps(companiesDashboard);
        router.push({
          name: 'home',
        });
      }
    },
    {
      target: '#export-vaccinated',
      header: false,
      content: '<strong class="text-dark font-size-16">Ver Vacinados - Exportar planilha</strong><br><span>Também é possível exportar uma planilha com os colaboradores vacinados, conforme os filtros aplicados nesta tela.</span>',
      params: {
        placement: 'top'
      },
      highlight: true,
      actionOnSequence: () => {
        setTourMode(true);
        setTourSteps(scheduling);
        const schedulingRule = getUserData().ability.find(item => item.subject === 'AGENDAMENTO_MENUS_ROTAS');
        if(!schedulingRule){
              let rules = getUserData().ability;
              rules.push({
                subject: subjects.AGENDAMENTO_MENUS_ROTAS,
                action: actions.VISUALIZAR,
                tour: true
              })
              rules.push({
                subject: subjects.AGENDAMENTO_AE,
                action: actions.VISUALIZAR,
                tour: true
              })

              $store.commit('sessions/SET_USER_ABILITY', rules)
              $ability.update(rules)
        }
        router.push({
          name: 'agendamento-list',
        });
      }
    },
];


const scheduling = [
    {
      target: '#scheduling-admin-empresa',
      header: false,
      content: '<strong class="text-dark font-size-16">Ver Agendamentos de Vacinação</strong><br><span>Nesta tela, você pode visualizar uma lista com todos os agendamentos de vacinação realizados para qualquer uma de suas empresas.</span>',
      params: {
        placement: 'top'
      },
      highlight: true,
      actionOnSequence: () => {
        setTourMode(true);
        setTourSteps(exportReports);

        if (getTourWatchingTopic()) {
          setTourWatchingAllTutorials(false);
        }

        router.push({
          name: 'export-relatorios',
        })
      },
      actionOnPrevStep: () => {
        setTourSteps(viewVaccinatedRecords);
        router.push({
          name: 'vacinacao-list',
        });
      }
    }
];

const exportReports = [
    {
      target: '#export-reports',
      header: false,
      content: '<strong class="text-dark font-size-16">Exportação de Relatórios</strong><br><span>Exporte relatórios de Agendamentos, Colaboradores Cadastrados, Colaboradores Vacinados, Empresas Cadastradas e Quantitativo de Vacinação.</span>',
      params: {
        placement: 'top'
      },
      highlight: true,
      actionOnSequence: () => {
        if (getTourWatchingTopic()) {
          setTourWatchingTopic(false);
          setTourMode(false);
          setTourSteps([]);
  
          router.push({
            name: 'pages-faq',
          })
        }else{
          setTourModalType('Final');
          updateStatusTutorial('Concluiu');
          let excludeSchedulingRule = getUserData().ability.filter(item => !item.tour);
          $store.commit('sessions/SET_USER_ABILITY', excludeSchedulingRule);
          $ability.update(excludeSchedulingRule)
        }
      },
      actionOnPrevStep: () => {
        setTourSteps(scheduling);
        router.push({
          name: 'agendamento-list',
        });
      }
    }
];



export default {
    viewVaccinatedRecords,
    companiesDashboard,
    exportReports,
    scheduling
  };