<template>
    <div class="content-wrapper">
        <PageHeader :screenName="'Lote ' + form.numero_lote" :linkItems="linkItems" />

        <ErrorModal :modalShow="modalError.showModal" :typeModal="modalError.typeModal"
            :caseModal="modalError.caseModal" @confirmed="redirectToList" />

        <main class="card">
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <output class="spinner-border text-custom-blue" aria-live="polite" aria-busy="true">
                    <span class="sr-only">Loading...</span>
                </output>
            </div>
            <section v-else class="form p-2">

                <b-form>
                    <div class="top-right-button">
                        <b-button class="d-flex align-items-center" @click="$router.back()" variant="primary-button">
                            <feather-icon icon="ArrowLeftIcon" class="mr-50" />
                            <span class="align-middle">Voltar</span>
                        </b-button>
                    </div>
                    <b-row>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Número do lote" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.numero_lote }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Vacina" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.vacina }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Fabricante" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.fabricante }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Tipo de Dose" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.frasco }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col class="pb-2" lg="6" md="6" sm="12">
                            <b-form-group label="Data de Criação" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ formatDate(form.criacao, 'DD/MM/YYYY') }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col class="pb-2" lg="6" md="6" sm="12">
                            <b-form-group label="Data de Validade" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ formatDate(form.validade, 'DD/MM/YYYY') }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Status" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div>
                                        {{ form.status }}
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                        <b-col class="pb-1" lg="6" md="12" sm="12">
                            <b-form-group label="Código Externo" label-class="font-weight-bold">
                                <vue-perfect-scrollbar :settings="settings">
                                    <div v-if="form.codigo_externo">
                                        {{ form.codigo_externo }}
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </vue-perfect-scrollbar>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </section>

        </main>
    </div>
</template>

<script>
import {
    BRow, BCol, BForm, BFormGroup, BFormInput,
    BFormRadio, BButton, BFormTextarea, BLink,
    BTable, VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ErrorModal from '@/views/components/custom/modals/ErroIdInexistenteModal.vue'
import { getIdLote } from '@core/utils/store/getStore';
import { setIdLote } from '@core/utils/store/setStore';

export default {
    title: 'Detalhes entrada',
    components: {
        BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormTextarea,
        BButton, PageHeader, VuePerfectScrollbar, ErrorModal, BLink,
        BTable, VBTooltip,
    },

    data() {
        return {
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Estoque',
                    routeName: 'estoque-list'
                },
                {
                    name: 'Lote',
                    routeName: 'lote-list'
                },
                {
                    name: 'Carregando dados...',
                    active: true
                }
            ],
            modalError: {
                showModal: false,
                typeModal: 'error',
                caseModal: 'idInexistente'
            },
            form: {
                numero_lote: '',
                fabricante: '',
                cadastro: '',
                vacina: '',
                codigo_externo: '',
                frasco: '',
                validade: '',
                status: '',
            },
            loadingData: true,
        }
    },
    mounted() {
        if ((getIdLote() === null || typeof getIdLote() == 'undefined') && typeof this.$route.params.idLote == "undefined") {
            this.openModalError();
            return;
        }

        if (typeof this.$route.params.idLote != "undefined") {
            if (this.$route.params.idLote !== getIdLote()) {
                setIdLote(this.$route.params.idLote);
            }
        }
        this.$http.get(this.$api.loteParameter(getIdLote())).then(({ data }) => {
            this.preenchimentoFormulario(data);
            this.linkItems[2].name = 'Lote ' + data.numero_lote;
        })
    },

    methods: {
        preenchimentoFormulario(dados) {
            this.form.numero_lote = dados.numero_lote;
            this.form.cadastro = dados.cadastro;
            this.form.fabricante = dados.fabricante.descricao;
            this.form.vacina = dados.vacina.nome_tecnico;
            this.form.codigo_externo = dados.codigo_externo;
            this.form.frasco = dados.frasco.descricao;
            this.form.validade = dados.validade;
            this.form.status = dados.lote_ativo ? 'Ativo' : 'Inativo';
            this.loadingData = false;
        },
        openModalError() {
            this.modalError.showModal = true;
        },
        redirectToList() {
            this.$router.push({ name: 'lote-list' });
        },
        formatDate(string, format) {
            return moment(string).format(format);
        },
    }
}
</script>

<style>
.custom {
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}

.mono {
    background-color: #f3eeff;
    color: #8c65e2;
}

.multi {
    background-color: #e3f6f4;
    color: #21a696;
}

legend {
    color: #5E5873;
}

.font-size-16 {
    font-size: 16px;
    line-height: 21px;
}

.altura_max_input_scrollbar {
    max-height: 32px;
    word-break: break-all;
    padding-right: 15px;
}

.altura_max_textarea_scrollbar {
    max-height: 113px;
    word-break: break-all;
    padding-right: 15px;
}

.top-right-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;
}
</style>