<template>
  <section>
    <app-tour 
    :steps="steps"
    @nextStep="nextStep"
    @prevStep="prevStep"
    />
    <slot />
  </section>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AppTour from './app-tour/AppTour.vue';

export default {
  name: 'ShepherdExample',
  components: {
    BCardCode,
    BButton,
    AppTour
  },
  directives: {
    Ripple,
  },
  props: {
    steps: {
      required: true,
      type: Array,
    }
  },
  methods: {
    nextStep() {
      this.$emit('nextStep')
    },
    prevStep(){
      this.$emit('prevStep')
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';

[dir=ltr] .v-tour .v-step .v-step__arrow {
    border-right-color: #fff;
    border-color: #fff;
}

.v-step[x-placement^=right][data-v-7c9c03f0] {
    margin-left: 1.5rem;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
  border-radius: 0.5rem;
  padding: 0.5rem;
  pointer-events: none;
}

.v-tour .v-step .v-step__header {
    background: none;
}
.v-step {
    padding: 2rem!important;
}

[dir] .v-tour .v-step .v-step__content {
    padding: 0 0 1rem;
    margin-bottom: 0;
}
.v-step__content div {
    display: flex;
    flex-flow: column;
}
</style>
