<template>
  <tour :steps="tourSteps">
  <div class="content-wrapper">
    <PageHeader :screenName="empresa.nome" :linkItems="linkItems" />
    <ConfirmaTermoModal
      :showModal="modalTermo.showModal"
      :IdEmpresa="empresa.id_empresa"
      @confirmed="changeTermoEmpresa"
      @errorModal="openErrorModal"
    />
    <ModalShowError
      :modalShow="modalError.showModal"
      :infoText="modalError.infoText"
      :typeModal="modalError.typeModal"
      :titleModal="modalError.titleModal"
      :errorImport="modalError.errorImport"
      @confirmed="redirectToCompanyPageOrErrorImport"
    />
    <ActionColaboradorModal
      itemIdentifier="colaborador"
      :modalShow="modalSucesso.showModal"
      :typeModal="modalSucesso.typeModal"
      :infoOlVersion="getPlanilhaVersaoAntiga"
      @confirmed="redirectToList"
    />
    <div id="empresa">
    <main class="card">
      <HeaderEmpresa :empresa="empresa" @confirmed="resolveRoute" />
        <section class="form m-2">
          <h1>Importação de colaboradores</h1>
          <div
            class="ml-50 mt-2 d-flex justify-content-start align-items-center"
          >
            <feather-icon
              icon="HelpCircleIcon"
              class="mr-50 text-custom-blue"
            />
            <b-link @click="redirectToFaqSection(20, 1)" style="line-height: 0"
              ><strong class="text-custom-blue"
                >Siga essas orientações para preencher a planilha
                corretamente</strong
              ></b-link
            >
          </div>
          <b-row class="m-0 mt-2">
            <b-col
              lg="5"
              md="5"
              sm="12"
              class="border d-flex align-items-center bg-gray"
            >
              <b-card
                bg-variant="custom-blue"
                class="rounded-2 mx-2 my-1"
                text-variant="white"
                style="min-width: 6rem"
              >
                <feather-icon icon="DownloadIcon" size="3x" />
              </b-card>
              <span>
                <b-link
                  href="planilhas/modelo_importacao.csv"
                  download
                  style="text-decoration: underline"
                  class="text-custom-blue"
                >
                  Clique aqui
                </b-link>
                e baixe a planilha modelo de importação das pessoas. Preencha os
                campos e salve no formato CSV.
              </span>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="border pt-2">
              <validation-observer ref="importRulesColaborador">
                <validation-provider
                  #default="{ errors }"
                  name="arquivo"
                  rules="required"
                >
                  <b-form-group label="Importar planilha:" label-size="lg">
                    <b-form-file
                      id="archive-import-colaborador"
                      size="lg"
                      v-model="form.arquivo"
                      placeholder="Nenhum arquivo selecionado"
                      drop-placeholder="Insira o arquivo aqui..."
                      browse-text="Escolher arquivo"
                      variant="primary"
                      accept=".csv"
                    >
                    </b-form-file>
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.colaborador.arquivo"
                      class="text-danger"
                    >
                      {{ form.errorApi.colaborador.arquivo }}
                    </small>
                    <small
                      v-if="form.errorApi.colaborador.error"
                      class="text-danger"
                    >
                      {{ form.errorApi.colaborador.error }}
                    </small>
                    <small
                      v-if="form.errorApi.colaborador.id_empresa"
                      class="text-danger"
                    >
                      {{ form.errorApi.colaborador.id_empresa }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </validation-observer>
            </b-col>
          </b-row>
        </section>
        <section class="buttons p-2 d-flex justify-content-center">
          <b-button
            id="import-colaborador"
            class="mr-2 mb-2-sm"
            variant="custom-blue"
            @click.prevent="validationFormColaborador(true)"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle">Importar</span>
            <span
              v-if="importing.colaborador"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
        </section>

      <div v-if="funcionalidadeDesativada" id="import-matricula-setor"></div>
      <div v-else id="import-matricula-setor">
        <section class="form m-2">
          <h1>Importação de dados complementares (Opcional)</h1>
          <div
            class="ml-50 mt-2 d-flex justify-content-start align-items-center"
          >
            Atualize o cadastro dos seus colaboradores importando as informações
            de matrícula e/ou setor.
          </div>
          <b-row class="m-0 mt-2">
            <b-col
              lg="5"
              md="5"
              sm="12"
              class="border d-flex align-items-center bg-gray"
            >
              <b-card
                bg-variant="custom-blue"
                class="rounded-2 mx-2 my-1"
                text-variant="white"
                style="min-width: 6rem"
              >
                <feather-icon icon="DownloadIcon" size="3x" />
              </b-card>
              <span>
                <b-link
                  href="planilhas/modelo_importacao_dados_complementares.csv"
                  download
                  style="text-decoration: underline"
                  class="text-custom-blue"
                >
                  Clique aqui
                </b-link>
                e baixe a planilha modelo de importação de matrícula e/ou setor.
                Preencha os campos e salve no formato CSV.
              </span>
            </b-col>
            <b-col lg="6" md="6" sm="12" class="border pt-2">
              <validation-observer ref="importRulesDadosComplementares">
                <validation-provider
                  #default="{ errors }"
                  name="arquivo"
                  rules="required"
                >
                  <b-form-group label="Importar planilha:" label-size="lg">
                    <b-form-file
                      id="archive-import-matricula-setor"
                      size="lg"
                      v-model="form.arquivo"
                      placeholder="Nenhum arquivo selecionado"
                      drop-placeholder="Insira o arquivo aqui..."
                      browse-text="Escolher arquivo"
                      variant="primary"
                      accept=".csv"
                    >
                    </b-form-file>
                    <small v-if="errors[0]" class="text-danger">
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.dadosComplementares.arquivo"
                      class="text-danger"
                    >
                      {{ form.errorApi.dadosComplementares.arquivo }}
                    </small>
                    <small
                      v-if="form.errorApi.dadosComplementares.error"
                      class="text-danger"
                    >
                      {{ form.errorApi.dadosComplementares.error }}
                    </small>
                    <small
                      v-if="form.errorApi.dadosComplementares.id_empresa"
                      class="text-danger"
                    >
                      {{ form.errorApi.dadosComplementares.id_empresa }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </validation-observer>
            </b-col>
          </b-row>
        </section>
        <section class="buttons p-2 d-flex justify-content-center">
          <b-button
            id="import-matricula-setor"
            class="mr-2 mb-2-sm"
            variant="custom-blue"
            @click.prevent="validationFormMatriculaSetor(true)"
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span class="align-middle">Importar</span>
            <span
              v-if="importing.matriculaSetor"
              class="spinner-border spinner-border-sm ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </b-button>
        </section>
      </div>
    </main>
    </div>
  </div>
</tour>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BCard,
  BLink,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import ActionColaboradorModal from "@/views/components/custom/modals/ActionsColaboradorModal.vue";
import HeaderEmpresa from "@/views/components/custom/page-header/Header-empresa.vue";
import handleErrorsService from "./Services/HandleErrors";
import { getIdEmpresa } from "@core/utils/store/getStore";
import ModalShowError from "@/views/components/custom/modals/ModalShowError.vue";
import { setIdEmpresa } from "@core/utils/store/setStore";
import ConfirmaTermoModal from "@/views/components/custom/modals/ConfirmaTermoModal.vue";
import VerificaAceiteTermoEmpresa from './Services/VerificaAceiteTermoEmpresa';
import tourMixin from "@/mixins/tourMixin";
import colaboradorTourMixin from "./mixins/colaboradorTourMixin";

export default {
  title: "Importar colaboradores",
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCard,
    BButton,
    vSelect,
    BLink,
    TimedAlert,
    PageHeader,
    ActionColaboradorModal,
    HeaderEmpresa,
    ValidationProvider,
    ValidationObserver,
    ConfirmaTermoModal,
    ModalShowError,
  },
  mixins: [tourMixin, colaboradorTourMixin],
  data() {
    return {
      required,
      linkItems: [
        {
          name: "Empresa",
          routeName: "empresa-colaborador-list",
        },
        {
          name: "Carregando dados...",
          active: true,
        },
        {
          name: "Importar colaboradores",
          active: true,
        }
      ],
      modalSucesso: {
        showModal: false,
        typeModal: "success",
      },
      modalErro: {
        showModal: false,
        typeModal: "danger",
      },
      modalTermo: {
        showModal: false,
        typeModal: "erro-aviso",
        infoText: "",
      },
      modalError: {
        showModal: false,
        typeModal: "erro-aviso",
      },
      importing: {
        colaborador: false,
        matriculaSetor: false,
      },
      form: {
        arquivo: null,
        errorApi: {
          colaborador: {
            arquivo: "",
            id_empresa: "",
            error: "",
          },
          dadosComplementares: {
            arquivo: "",
            id_empresa: "",
            error: "",
          },
        },
        headers: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      },
      empresa: {
        id_empresa: 0,
        nome: "",
        gerenciarColaboradores: false,
      },
      ajuda: false,
      userData: this.$store.state.sessions.userData,
      profileEmpresa: false,
      planilhaVersaoAntiga: false,
      funcionalidadeDesativada: true,
    };
  },

  mounted() {
    if(this.loadData) {
      this.mountedInit()
    }
  },
  methods: {
    mountedInit() {
      const profiles = this.userData.perfis.map((profile) => profile.id_perfil);
    if (
      (getIdEmpresa() === null || typeof getIdEmpresa() == "undefined") &&
      typeof this.$route.params.idEmpresa == "undefined"
    ) {
      this.openModalError();
      return;
    }

    if (typeof this.$route.params.idEmpresa != "undefined") {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa);
      }
    }

    this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
      this.modalTermo.showModal = VerificaAceiteTermoEmpresa.verificaSeTermoPrecisaSerAceito(
        data.empresa_termo,
        profiles
      );
          
      this.empresa.nome = data["cnpj"] ? data["nome_fantasia"] : data["nome"];
      this.empresa.gerenciarColaboradores =
        data["pode_gerenciar_colaboradores"];
      this.empresa.id_empresa = data.id_empresa;
      this.linkItems[1].name = this.empresa.nome;
    });
    },
    validationFormColaborador(redirectToList) {
      this.$refs.importRulesColaborador.validate().then((success) => {
        if (success) {
          this.importing.colaborador = true;
          this.importColaborador(redirectToList);
        }
      });
    },
    validationFormMatriculaSetor(redirectToList) {
      this.$refs.importRulesDadosComplementares.validate().then((success) => {
        if (success) {
          this.importing.matriculaSetor = true;
          this.importMatriculaSetor(redirectToList);
        }
      });
    },
    validationSizeFile(size, type) {
      const maxSize = 1000000;
      if (maxSize < size) {
        this.$swal(
          "Erro",
          "Tamanho do documento não deve ultrapassar 1MB!",
          "error"
        );
        this.importing[type] = false;
        return false;
      }
      return true;
    },
    handleFormDataFields(arquivo) {
      let formData = new FormData();
      formData.append("arquivo", arquivo);
      formData.append("nome", this.form.arquivo.name);
      formData.append("id_empresa", this.empresa.id_empresa);
      return formData;
    },
    importColaborador(redirectToList) {
      let imagefile = document.querySelector("#archive-import-colaborador"),
        arquivo = imagefile.files[0];
      if (!this.validationSizeFile(arquivo.size, "colaborador")) {
        return;
      }

      let formData = this.handleFormDataFields(arquivo);

      this.$http
        .post(this.$api.importColaborador(), formData, this.form.headers)
        .then(({ data }) => {
          this.form.errorApi.colaborador.arquivo = "";
          this.form.errorApi.colaborador.error = "";
          this.planilhaVersaoAntiga = data.data.planilha_versao_antiga;
          if (redirectToList) {
            this.openModalSucesso();
          }
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosImport(
            data,
            this.form.errorApi.colaborador
          );

          if (data.errors.cabecalho != undefined) {
            this.openErrorModal(
              data.errors.cabecalho[0],
              "Erro de Importação",
              true
            );
          }
          this.importing.colaborador = false;
        });
    },
    importMatriculaSetor(redirectToList) {
      let imagefile = document.querySelector("#archive-import-matricula-setor"),
        arquivo = imagefile.files[0];
      if (!this.validationSizeFile(arquivo.size, "matriculaSetor")) {
        return;
      }

      let formData = this.handleFormDataFields(arquivo);

      this.$http
        .post(
          this.$api.importColaboradorDadosComplementares(),
          formData,
          this.form.headers
        )
        .then(() => {
          this.form.errorApi.dadosComplementares.arquivo = "";
          this.form.errorApi.dadosComplementares.error = "";
          if (redirectToList) {
            this.openModalSucesso();
          }
        })
        .catch(({ response: { data } }) => {
          handleErrorsService.handleErrosImport(
            data,
            this.form.errorApi.dadosComplementares
          );
          this.importing.matriculaSetor = false;
        });
    },
    cleanForm() {
      this.form.errorApi.colaborador.arquivo = "";
      this.form.errorApi.colaborador.error = "";
      this.form.errorApi.dadosComplementares.arquivo = "";
      this.form.errorApi.dadosComplementares.error = "";
    },
    openModalSucesso() {
      this.modalSucesso.showModal = true;
    },
    openModalErro() {
      this.modalErro.showModal = true;
    },
    closeModalErro() {
      this.modalErro.showModal = false;
    },
    redirectToList() {
      this.$router.push({ name: "empresa-colaborador-import-list" });
    },
    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa,
          },
        });
      }
    },
    changeTermoEmpresa() {
      this.modalTermo.showModal = false;
    },
    openErrorModal(data, titleModal = null, errorImport = false) {
      this.modalError.infoText = typeof data == "string" ? data : data.errors;
      this.modalError.titleModal = titleModal;
      this.modalError.errorImport = errorImport;
      this.modalError.showModal = true;
    },
    redirectToCompanyPageOrErrorImport() {
      if (this.modalError.errorImport) {
        this.modalError.showModal = false;
      } else {
        this.$router.push({ name: "empresa-show" });
      }
    },
    redirectToFaqSection(idAjuda, categoria) {
      this.$router.push({
        name: "pages-faq",
        params: {
          idAjuda,
          categoria,
        },
      });
    },
  },
  computed: {
    getPlanilhaVersaoAntiga() {
      return this.planilhaVersaoAntiga;
    },
  },
};
</script>

<style>
.bg-gray {
  background-color: #efefef;
}
.custom-file-label::after {
  color: #2772c0 !important;
  border: 1px solid #2772c0 !important;
  border-radius: 4px !important;
}
</style>
