const state = {
    tourMode: false,
    steps: [],
    currentStep: 0,
    modalType: null,
    watchingTopic: false,
    watchingAllTutorials: false
}

const mutations = {
    SET_TOUR_MODE(state, data){
        state.tourMode = data;
    },
    SET_STEPS(state, data){
        state.steps = data;
    },
    SET_CURRENT_STEP(state, data){
        state.currentStep = data;
    },
    SET_MODAL_TYPE(state, data){
        state.modalType = data;
    },
    SET_WATCHING_TOPIC(state, data) {
        state.watchingTopic = data;
    },
    SET_WATCHING_ALL_TUTORIALS(state, data) {
        state.watchingAllTutorials = data;
    }
}

const actions = {
  
}

const getters = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
