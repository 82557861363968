import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: true
      };
    },
    mounted() {
        this.loadData = !getTourMode();
        if(!this.loadData){
            this.empresa.nome = 'Empresa Tutorial';
            this.form.dadosPrincipais.cnpj = '00.000.000/0000-00';
            this.form.dadosPrincipais.nome_fantasia = this.empresa.nome;
            this.form.dadosPrincipais.razao_social = 'Razão Empresa Tutorial';
            this.form.dadosComplementares.base_nacional = false;
            this.linkItems[2].name = this.empresa.nome
            this.loadingShow = false;
        }
    }
  };