<template>
    <b-modal
      id="tour-modal"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer
      centered
      size="lg"
      content-class="modal-tour"
    >
    <div v-if="modalConfig.skipButton" class="d-flex align-items-center justify-content-end mt-1">
      <b-button variant="primary-button" @click="skipTutorial">
            Pular
      </b-button>
    </div>
      <div
        class="d-flex align-items-center justify-content-center my-2 text-center"
      >
      <div
        class="mx-2"
        v-html="modalConfig.text"
      />
      <div class="mx-2">
        <b-img-lazy fluid class="modal-tour-img" center :src="manBandAid" alt="man-band-aid"/>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-center mb-1">
      <b-button variant="primary-button" @click="modalConfig.actionButton">
            {{ modalConfig.textButton }}
      </b-button>
    </div>
    </b-modal>
  </template>
      
  <script>
  import { BModal, BButton, BLink, BImgLazy } from "bootstrap-vue";
  import manBandAid from '@/assets/custom-images/initial-pages/man-band-aid.png';
  import { setTourMode, setTourSteps, setTourWatchingAllTutorials } from '@/@core/utils/store/setStore';
  import companySteps from '@/views/components/custom/Tour/Steps/companySteps';
  import monitoringSteps from '@/views/components/custom/Tour/Steps/monitoringSteps';
  import { updateStatusTutorial } from "../functions/UpdateStatusFunctions";

  
  export default {
    components: {
      BModal,
      BButton,
      BLink,
      BImgLazy
    },
    props: {
      modalType: {
        type: String,
        default: "Inicio",
        required: false,
      },
    },
    data() {
      return {
        manBandAid,
      };
    },
    methods: {
      closeModal() {
        this.$emit("closeModal");
      },
      initTutorial() {
        this.redirectToEmpresaRegister();
        updateStatusTutorial('Iniciou');
        setTimeout(() => {
          setTourWatchingAllTutorials(true);
          setTourMode(true);
          setTourSteps(companySteps.firstCompany);
          this.closeModal();
        }, 500);
      },
      continueTutorial() {
        setTourMode(true);
        setTourSteps(monitoringSteps.companiesDashboard);
        this.redirectToHome()
        this.closeModal();
      },
      finishTutorial() {
        setTourWatchingAllTutorials(false);
        this.closeModal();
        this.redirectToHome();
      },
      skipTutorial() {
        updateStatusTutorial('Pulou');
        setTourWatchingAllTutorials(false);
        this.closeModal();
        this.redirectToHome();
      },
      redirectToHome() {
        if(this.$route.name != 'home') {
          this.$router.push({ name: 'home' })
        }
      },
      redirectToEmpresaRegister(){
        if(this.$route.name != 'empresa-register') {
          this.$router.push({ name: 'empresa-register' })
        }else{
          this.$tours.vuexyTour.start();
        }
      }
    },
    computed: {
        modalConfig() {
            let modalConfig = {}
            const fieldConfig = {
            "Iniciou": {
                text: `<span class="text-left">
                  <h1 class="text-custom-blue font-weight-bolder">Olá!</h1>
                  <h2 class="font-weight-normal">Criamos um tutorial com os passos para você entender melhor o <strong class="text-custom-blue">VacinaSESI.</strong></h2>
                </span>
                `,
                textButton: "Iniciar tutorial",
                skipButton: true,
                actionButton: this.initTutorial
            },
            "Adesao": {
                text: `<span class="text-left">
                    <h1 class="text-custom-blue font-weight-bolder">Parabéns!</h1>
                    <h2 class="font-weight-normal">Você concluiu o tutorial até o fluxo de adesão no <strong class="text-custom-blue">VacinaSESI.</strong></h2>
                  </span>
                  `,
                textButton: "Continuar tutorial",
                skipButton: true,
                actionButton: this.continueTutorial
            },
            "Final": {
                text: `<span class="text-left">
                    <h1 class="text-custom-blue font-weight-bolder">Você concluiu o tutorial!</h1>
                    <h2 class="font-weight-normal">Esperamos que tenha gostado. Agora, você pode iniciar sua adesão à nossa campanha.</h2>
                  </span>
                  `,
                textButton: "Iniciar uso",
                skipButton: false,
                actionButton: this.finishTutorial
            }
            };

            const config = fieldConfig[this.modalType];
            if (config) {
                modalConfig = config;
            }

            return modalConfig
        },
    },
  };
  </script>

<style>
.modal-tour {
  background: rgb(198,221,255);
  background: linear-gradient(45deg, rgba(198,221,255,1) 0%, rgba(255,255,255,1) 100%);
}

.modal-tour-img{
  max-width: 320px;
}
</style>