<template>
  <div>
    <b-container fluidc class="d-flex container">
      <b-row align-v="center" class="w-100 m-0">
        <b-col cols="5">
          <h6 class="text-secondary">Empresas não vinculadas</h6>
          <div class="border border-muted rounded containerCities">

            <b-form-group class="d-flex align-items-center">
              <b-form-checkbox
                  class="m-1"
                  v-for="company in companiesNotLinked"
                  v-model="selectedCompaniesNotLinked"
                  :key="company.id_empresa"
                  :value="company.id_empresa"
                  name="notLinkeedCities"
              >{{ company.nome_empresa }}</b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="2" >
          <b-row class="d-flex flex-column flex-fill justify-content-center">
            <b-button class="mb-1" variant="custom-blue"
                      @click.prevent="changeSideCompany('selectedCompaniesNotLinked', 'companiesNotLinked', 'companiesLinked')"
            > {{ 'Vincular >>' }} </b-button>
            <b-button variant="custom-blue"
                      @click.prevent="changeSideCompany('selectedCompaniesLinked', 'companiesLinked', 'companiesNotLinked')"
            > {{ '<< Desvincular' }} </b-button>
          </b-row>
        </b-col>
        <b-col cols="5">
          <h6 class="text-secondary">Empresas vinculadas</h6>
          <div class="border border-muted rounded containerCities">
            <b-form-group >
              <b-form-checkbox
                  class="m-1"
                  v-for="company in companiesLinked"
                  v-model="selectedCompaniesLinked"
                  :key="company.id_empresa"
                  :value="company.id_empresa"
                  name="linkedCompanies"
              >{{ company.nome_empresa }}</b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {BButton, BCol, BContainer, BFormCheckbox, BFormGroup, BRow} from "bootstrap-vue";
import {getCompanyBusiness} from "@core/utils/store/getStore";
import {resetCompanyBusiness, resetCompanyBusinessId} from '@/@core/utils/store/setStore/index'

export default {
  components: {
    BFormCheckbox, BButton, BCol, BRow, BContainer, BFormGroup
  },
  props: {
    companiesProp: {
      required: false
    },
    edit: {
      required: false
    }
  },
  data() {
    return {
      companies: [],
      companiesNotLinked: [],
      companiesLinked: [],
      selectedCompaniesNotLinked: [],
      selectedCompaniesLinked: [],
    }
  },
  mounted() {
    this.companies = this.edit ? this.companiesProp : getCompanyBusiness()
    this.setCompaniesLinked()
    this.setCompaniesNotLinked()
    this.$emit('companiesLinked', this.companiesLinked);
    this.$emit('companiesNotLinked', this.companiesNotLinked)
  },
  beforeDestroy() {
    resetCompanyBusiness()
    resetCompanyBusinessId()
  },
  methods: {
    setCompaniesLinked() {
      if(this.edit) {
        this.companiesLinked = this.companiesProp.filter((company, index, self) => {
          return index === self.findIndex(c => c.id_empresa === company.id_empresa);
        });
      }
    },
    setCompaniesNotLinked() {
      if(!this.edit) {
        this.companiesNotLinked = this.companies
      } else {
        const ids = new Set(this.companies.map(company => company.id_empresa));
        const result = getCompanyBusiness().filter(company => !ids.has(company.id_empresa));
        this.companiesNotLinked = result
      }
    },
    changeSideCompany(selectedCompanies, fromSide, toSide) {
      this[selectedCompanies].map(company_id => {
        const companySelected = this[fromSide].find(company => company.id_empresa == company_id);

        if(companySelected) {
          this[fromSide] = this[fromSide].filter(company => company.id_empresa !== company_id);
          this[toSide].push(companySelected);
        }
      });

      this[selectedCompanies] = [];

      this.$emit('companiesLinked', this.companiesLinked);
      this.$emit('companiesNotLinked', this.companiesNotLinked)

    },
  },
  watch: {
    companies(companies) {
      if(companies && !this.edit) {
        this.selectedCompaniesNotLinked = []
        this.selectedCompaniesLinked = []
      }
    }
  },
}
</script>

<style scoped>
.container {
  min-height: 300px;
}

.containerCities {
  height: 250px;
  overflow-y: scroll;
}

.infoText {
  position: relative;
  top: 50%;
  text-align: center;
}
</style>