<template>
  <b-modal
      id="confirm-delete"
      no-close-on-backdrop
      no-close-on-esc
      hide-header
      hide-footer
      centered
      v-model="modalShow"
  >
    <div class="d-flex flex-column align-items-center justify-center-center my-2">
      <feather-icon
          id="view-departamento"
          icon="AlertCircleIcon"
          size="80"
          class="text-danger mb-2"
      />
      <h3>Exclusão de negócio</h3>
      <span class="text-center">Tem certeza que deseja excluir este negócio?</span>
      <div class="d-flex mt-2">
        <b-button :disabled="loading" variant="primary-button" @click="confirmAction" >
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Sim
        </b-button>
        <b-button :disabled="loading" variant="outline-danger" class="ml-2 " @click="cancelAction">
          Cancelar
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue';
export default {
  components: {
    BModal, BButton
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      loading: false,
      type: '',
    }
  },

  methods: {
    confirmAction(modalEvent) {
      modalEvent.preventDefault();

      this.loading = true;

      this.$emit('confirmed', modalEvent);
    },

    cancelAction(modalEvent) {
      modalEvent.preventDefault();

      this.$emit('canceled', modalEvent);
    },
  },
}
</script>

