var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tour',{attrs:{"steps":_vm.tourSteps}},[_c('div',{staticClass:"content-wrapper"},[_c('PageHeader',{attrs:{"screenName":"Adicionar colaborador","linkItems":_vm.linkItems}}),_c('ConfirmaTermoModal',{attrs:{"showModal":_vm.modalTermo.showModal,"IdEmpresa":_vm.empresa.id_empresa},on:{"confirmed":_vm.getInfoForTerms,"errorModal":_vm.openErrorModal}}),_c('ConfirmModal',{attrs:{"itemIdentifier":"usuário","modalShow":_vm.modal.showModal,"typeModal":_vm.modal.typeModal},on:{"confirmed":_vm.redirectToList,"canceled":_vm.closeModalConfirm}}),_c('ActionColaboradorModal',{attrs:{"itemIdentifier":"colaborador","modalShow":_vm.modalCpf.showModal,"typeModal":_vm.modalCpf.typeModal,"infoText":_vm.modalCpf.infoText},on:{"confirmed":_vm.closeModalCpf}}),_c('SucessoModal',{attrs:{"modalShow":_vm.modalSucesso.showModal,"typeModal":_vm.modalSucesso.typeModal,"caseModal":_vm.modalSucesso.caseModal},on:{"confirmed":_vm.redirectToList}}),_c('ModalShowError',{attrs:{"modalShow":_vm.modalError.showModal,"infoText":_vm.modalError.infoText,"typeModal":_vm.modalError.typeModal},on:{"confirmed":_vm.redirectToCompanyPage}}),_c('div',{attrs:{"id":"empresa"}},[_c('main',{staticClass:"card"},[_c('HeaderEmpresa',{attrs:{"empresa":_vm.empresa},on:{"confirmed":_vm.resolveRoute}}),_c('section',{staticClass:"form p-2"},[_c('validation-observer',{ref:"colaboradorRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"CPF*"}},[_c('validation-provider',{attrs:{"name":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-cleave',{staticClass:"form-control",attrs:{"id":"cpf-input","raw":false,"options":_vm.option.cpf,"placeholder":"000.000.000-00","name":"cpf","maxlength":"14","autocomplete":"off"},on:{"input":_vm.returnPessoa},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.cpf)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.cpf)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Data de nascimento*"}},[_c('validation-provider',{attrs:{"name":"data_nascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('vue-cleave',{staticClass:"form-control",attrs:{"id":"data-nascimento-input","raw":false,"options":_vm.option.data,"placeholder":"00/00/0000","name":"data_nascimento","autocomplete":"off","disabled":_vm.form.disabled},on:{"input":_vm.setNascimentoInput},model:{value:(_vm.input.data_nascimento),callback:function ($$v) {_vm.$set(_vm.input, "data_nascimento", $$v)},expression:"input.data_nascimento"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"max":_vm.today,"label-help":"Selecione a data de nascimento","button-variant":"custom-blue","clearable":"","disabled":_vm.form.disabled},on:{"input":_vm.setNascimentoPicker},model:{value:(_vm.form.data_nascimento),callback:function ($$v) {_vm.$set(_vm.form, "data_nascimento", $$v)},expression:"form.data_nascimento"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.data_nascimento)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.data_nascimento)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome completo do colaborador*"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ||
                          _vm.form.errorApi.nome ||
                          _vm.form.errorApi.error
                            ? false
                            : null,"id":"nome-input","placeholder":"John Doe","disabled":_vm.form.disabled},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.nome)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.nome)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Nome da mãe"}},[_c('validation-provider',{attrs:{"name":"nome_mae"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.form.errorApi.nome_mae
                            ? false
                            : null,"id":"nome_mae-input","disabled":_vm.disabled.nome_mae,"placeholder":"Nome da mãe"},model:{value:(_vm.form.nome_mae),callback:function ($$v) {_vm.$set(_vm.form, "nome_mae", $$v)},expression:"form.nome_mae"}}),(_vm.form.errorApi.nome_mae)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.nome_mae)+" ")]):_vm._e(),(_vm.form.errorApi.error)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.error)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"genero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sexo*","state":errors.length > 0 || _vm.form.errorApi.genero ? false : null}},[_c('v-select',{attrs:{"id":"genero","options":_vm.generoSelect,"label":"topico","placeholder":"Selecione uma opção","clearable":true,"disabled":_vm.disabled.genero},model:{value:(_vm.form.genero),callback:function ($$v) {_vm.$set(_vm.form, "genero", $$v)},expression:"form.genero"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.genero)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.genero)+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('validation-provider',{attrs:{"name":"categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categoria*","state":errors.length > 0 || _vm.form.errorApi.categoria
                          ? false
                          : null}},[_c('v-select',{attrs:{"id":"categoria","options":_vm.categoriaSelect,"label":"descricao","placeholder":"Selecione uma categoria","clearable":true,"reduce":function (option) { return option.id_categoria; }},model:{value:(_vm.form.categoria),callback:function ($$v) {_vm.$set(_vm.form, "categoria", $$v)},expression:"form.categoria"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e(),(_vm.form.errorApi.categoria)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.categoria)+" ")]):_vm._e()],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('NacionalidadeField',{key:_vm.form.renderNacionalidade,attrs:{"idNacionalidade":_vm.form.id_nacionalidade,"errorApiIdNacionalidade":_vm.form.errorApi.id_nacionalidade,"customClass":"register-colaborador","isDisabled":_vm.disabled.nacionalidade},on:{"updateNacionalidade":_vm.updateNacionalidade}})],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Matrícula"}},[_c('validation-provider',{attrs:{"name":"matricula"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ||
                          _vm.form.errorApi.matricula ||
                          _vm.form.errorApi.error
                            ? false
                            : null,"id":"matricula-input","placeholder":"Identificação interna"},model:{value:(_vm.form.matricula),callback:function ($$v) {_vm.$set(_vm.form, "matricula", $$v)},expression:"form.matricula"}}),(_vm.form.errorApi.matricula)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.matricula)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"4","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Setor"}},[_c('validation-provider',{attrs:{"name":"setor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ||
                          _vm.form.errorApi.setor ||
                          _vm.form.errorApi.error
                            ? false
                            : null,"id":"setor-input","placeholder":"Área, setor ou departamento"},model:{value:(_vm.form.setor),callback:function ($$v) {_vm.$set(_vm.form, "setor", $$v)},expression:"form.setor"}}),(_vm.form.errorApi.setor)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.form.errorApi.setor)+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)],1),_c('section',{staticClass:"buttons p-2"},[_c('b-button',{staticClass:"mr-2 mb-2 mb-sm-0",attrs:{"id":"save-colaborador","variant":"custom-blue","disabled":_vm.saving || _vm.savingAndNew},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(true)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar colaborador")]),(_vm.saving)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{staticClass:"mr-2 mb-2 mb-sm-0",attrs:{"id":"save-new-colaborador","variant":"custom-blue","disabled":_vm.saving || _vm.savingAndNew},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(false)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Salvar e cadastrar um novo")]),(_vm.savingAndNew)?_c('span',{staticClass:"spinner-border spinner-border-sm ml-1",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-register-colaborador","disabled":_vm.saving || _vm.savingAndNew,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.openModalConfirm.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }