<template>
  <main class="card hidden_overflow_tabela px-2 mt-2">
    <validation-observer>
      <b-form>
        <b-row class="align-items-start">
          
          <b-col
              sm="12"
              lg="4"
              class="px-1"
          >

            <b-form-group
                label="CNPJ/CPF"
                label-class="font_size_label"
                label-for="identifier_company"
            >

              <b-form-input
                  v-if="showIdentifierCompanyMask"
                  id="identifier_company"
                  v-model="form.identifier_company"
                  v-mask="computedMask"
                  placeholder="00.000.000/0000-00"
                  max-length="18"
                  autocomplete="off"
              >
              </b-form-input>
              <small  v-if="errorApi.cnpj" class="text-danger">{{ errorApi.cnpj }}</small>

            </b-form-group>
          </b-col>

          <b-col
              sm="12"
              lg="4"
              class="px-1"
          >
            <company-input-filter
              v-model="form.company_name"
              :form="form"
            />
          </b-col>

          <b-col
              sm="6"
              lg="4"

          >
            <b-form-group
                label="Data"
                label-for="data-input"
                label-class="font_size_label"
            >
              <b-input-group>
                <b-form-input
                    id="data-input"
                    v-model="inputDate"
                    v-mask="'##/##/####'"
                    class="form-control"
                    :state="inputDate > 0 ? false:null"
                    placeholder="00/00/0000"
                    autocomplete="off"
                    @input="setDateInput"
                />
                <b-input-group-append>
                  <b-form-datepicker
                      v-model="form.date"
                      class="form-control d-flex align-items-center"
                      locale="pt-BR"
                      button-only
                      right
                      :hide-header="true"
                      label-help="Selecione a data"
                      button-variant="custom-blue"
                      clearable
                      @input="setDatePicker"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row class="align-items-center">
          <b-col
              lg="4"
              sm="12"
          >
            <b-form-group
                label="Unidade Operacional"
                label-class="font_size_label"
                label-for="unit"
            >
              <validation-provider
                  #default="{errors}"
                  name="Unidade operacional"
              >
                <v-select
                    id="unit"
                    v-model="form.operating_unit"
                    :options="operatingOptions"
                    placeholder="Selecione a unidade operacional"
                    label="descricao_unidade"
                    item-text="descricao_unidade"
                    item-value="id_unidade"
                    :clearable="false"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
              lg="4"
              sm="12"
          >
            <b-form-group
                label="Status"
                label-class="font_size_label"
                label-for="status"
            >
              <validation-provider
                  #default="{errors}"
                  name="status"
              >
                <v-select
                    id="status"
                    v-model="form.status"
                    :options="statusOptions"
                    placeholder="Selecione o status"
                    label="descricao"
                    item-text="status"
                    item-value="status"
                    :clearable="false"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
    <Filters
        @actionSearch="search"
        @actionClear="clear"
    />
    <b-row>
      <b-col
          lg="5"
          md="5"
          sm="5"
          class="col-12 mb-sm-0"
      >
        <div class="d-flex justify-center-center align-items-center">
          <span
              class="mr-1"
              for="tableSize"
          >
            Mostrar
          </span>
          <v-select
              id="tableSize"
              v-model="defaultSize"
              :options="optionsSize"
              :clearable="false"
              class="customizeDifferentSpace"
              @input="fireEventFilter"
              append-to-body
          >
            <span slot="no-options">Nenhuma opção selecionável.</span>
          </v-select>
        </div>
      </b-col>
    </b-row>
  </main>

</template>

<script>

import {
  BButton,
  BCol, BForm,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment/moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Filters from '@/views/components/custom/filters/Filters.vue'
import tourSchedulingMixin from '../mixins/tourSchedulingMixin'
import CompanyInputFilter from '@/views/components/custom/empresa/CompanyInputFilter.vue';

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    Filters,
    ValidationObserver,
    ValidationProvider,
    CompanyInputFilter,
  },
  mixins: [tourSchedulingMixin],
  props: {
    filter: {
      required: false,
      default: false,
    },
    errorApi: {
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      filterForm: true,
      form: {
        identifier_company: '',
        company_name: '',
        date: '',
        operating_unit: '',
        status: '',
        company_name_filter: 'nome_fantasia',
      },
      searchFilter: null,
      inputDate: '',
      statusOptions: ['Agendado', 'Finalizado', 'Cancelado'],
      operatingOptions: [],
      showIdentifierCompanyMask: true,
      defaultSize: 10,
      optionsSize: [10, 25, 50, 100],
      error: {
        cnpj: null
      }
    }
  },
  computed: {
    computedMask() {
      return this.form.identifier_company.length <= 11 ? '###.###.###-##' : '##.###.###/####-##'
    },
  },
  mounted() {
    if(this.loadData){
      this.getUnits()
      this.start()
    }
  },
  methods: {
    setDateInput() {
      if (this.inputDate.length == 0) {
        this.form.date = null
      } else if (this.inputDate.length == 10) {
        this.form.date = moment(this.inputDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
    },
    setDatePicker() {
      this.inputDate = moment(this.form.date).format('DD/MM/YYYY')
    },
    getUnits() {
      const parameters = {
        ativo: true,
      }
      this.$http.get(this.$api.unidade(), { params: parameters }).then(({ data }) => {
        this.operatingOptions = data
      })
    },
    start() {
      this.searchFilter = {
        route: this.$router.currentRoute.name,
        column_order: 'asc',
        nome_fantasia: this.form.company_name,
        company_name_filter: this.form.company_name_filter,
        cnpj: this.form.identifier_company,
        data: this.form.date,
        unidade_operacional: this.form.operating_unit.id_unidade,
        status: this.form.status,
        filter: this.filter,
        porPagina: this.defaultSize,
      }
      this.$emit('filterTable', this.searchFilter)
    },
    fireEventFilter() {
      this.searchFilter = {
        route: this.$router.currentRoute.name,
        column_order: 'asc',
        nome_fantasia: this.form.company_name,
        company_name_filter: this.form.company_name_filter,
        cnpj: this.form.identifier_company,
        data: this.form.date,
        unidade_operacional: this.form.operating_unit.id_unidade,
        status: this.form.status,
        filter: this.filter,
        porPagina: this.defaultSize,
        pagina: 1,
      }

      this.$emit('filterTable', this.searchFilter)
    },
    search() {
      this.searchFilter = {
        porPagina: this.defaultSize,
        route: this.$router.currentRoute.name,
        column_order: 'asc',
        nome_fantasia: this.form.company_name,
        company_name_filter: this.form.company_name_filter,
        cnpj: this.form.identifier_company,
        data: this.form.date,
        unidade_operacional: this.form.operating_unit.id_unidade,
        status: this.form.status,
        pagina: 1,
      }

      this.$emit('filterTable', this.searchFilter)
    },
    clear() {
      this.showIdentifierCompanyMask = false
      this.form.company_name = ''
      this.form.company_name_filter = 'nome_fantasia'
      this.form.identifier_company = ''
      this.form.date = ''
      this.form.operating_unit = ''
      this.form.status = ''

      this.$nextTick(() => {
        this.showIdentifierCompanyMask = true
        this.searchFilter = {
          defaultSize: this.defaultSize,
          route: this.$router.currentRoute.name,
          column_order: 'asc',
          nome_fantasia: this.form.company_name,
          company_name_filter: this.form.company_name_filter,
          cnpj: this.form.identifier_company,
          data: this.form.date,
          unidade_operacional: this.form.operating_unit.id_unidade,
          status: this.form.status,
          porPagina: 10,
          pagina: 1,
        }

        this.$emit('filterTable', this.searchFilter)
      })
    },
  },
  watch: {
    filter() {
      this.start()
    },
    'errorApi.cnpj': function (cnpj) {
      if (cnpj !== undefined) {
        this.error.cnpj = cnpj
      }
    }
  }
}
</script>

