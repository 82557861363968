<template>
  <b-modal
    id="empresa-select"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
    centered
    v-model="modal.show"
    @hidden="fecharModal"
  >
    <div
      class="d-flex flex-column align-items-center justify-center-center mt-1 mb-3 mx-2"
    >
      <h1>Escolha uma empresa</h1>
 
      <b-form-group>
        <v-select
          id="company"
          v-model="modal.empresaSelecionada"
          :options="modal.empresas"
          variant="custom"
          item-text="nome_empresa"
          item-value="id_empresa"
          placeholder="Selecione uma empresa"
          :label="empresaFilter"
          class="w-100 my-2"
          :loading="loading"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
          <template #option="item">
            <strong>{{item.documento_formatado}}</strong><br>
            <span v-if="empresaFilter != 'doc_filter' && item[empresaFilter]">{{ item[empresaFilter] }}</span>
            <span v-else>{{ item.nome_empresa }}</span>
          </template>
          <template #selected-option="item">
              <span>
                {{ empresaFilter != 'doc_filter' ? item[empresaFilter] : item.documento_formatado }}
              </span>
            </template>
        </v-select>
        <b-form-radio-group
          id="radio-group-empresa"
          v-model="empresaFilter"
          name="radio-sub-component"
          class="mt-10px radio-empresa"
          size="sm"
        >
          <span class="mr-1">Filtrar por:</span>
          <b-form-radio value="doc_filter">CNPJ/CPF</b-form-radio>
          <b-form-radio value="razao_social">Razão Social</b-form-radio>
          <b-form-radio value="nome_empresa">Nome Fantasia</b-form-radio>
        </b-form-radio-group>
      </b-form-group>

      <div role="group" v-if="!loading">
        <button @click="fecharModal" class="mr-1 btn custom-blue">
          Voltar
        </button>

        <button
          @click="adicionarEmpresa"
          class="ml-1 btn custom-blue"
          :disabled="modal.empresaSelecionada == null"
        >
          &nbsp; Selecionar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import { 
  BModal,
  BFormRadioGroup,
  BFormRadio,
  BFormGroup
} from "bootstrap-vue";
import {
  AdesaoGetApiEmpresasParaAdesao,
  AdesaoAdicionarEmpresaSesi,
  AdesaoGetEmpresas,
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";

export default {
  components: {
    BModal,
    vSelect,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup
  },
  data() {
    return {
      empresaFilter: 'doc_filter',
      modal: {
        show: true,
        empresaSelecionada: null,
        empresas: [],
      },
      loading: true,
    };
  },
  beforeMount() {
    this.handleAdicionarEmpresa();
  },
  methods: {
    handleAdicionarEmpresa() {
      AdesaoGetApiEmpresasParaAdesao().then((empresas) => {
        let idEmpresaJaExistenteNaSessao = AdesaoGetEmpresas().map(
          (empresa) => empresa.id_empresa
        );

        this.modal.empresas = empresas.filter((empresa) => {
          return !idEmpresaJaExistenteNaSessao.includes(empresa.id_empresa);
        });

        this.modal.empresas = this.modal.empresas.map((empresa) => {
          return {
            ...empresa,
            documento_formatado: this.formatCnpjCpf(empresa.documento),
            doc_filter: this.formatCnpjCpf(empresa.documento) + ' ' + empresa.documento
          };
        });
              
        this.loading = false;
      });
    },

    fecharModal() {
      this.modal = {
        show: false,
        empresaSelecionada: null,
        empresas: [],
      };
      this.$emit("fechar-modal");
    },
    adicionarEmpresa() {
      AdesaoAdicionarEmpresaSesi(this.modal.empresaSelecionada);
      this.fecharModal();
    },

    formatCnpjCpf(document) {
      if (document.length === 14) {
          return this.$options.filters.VMask(document, '##.###.###/####-##');
        } else if (document.length === 11) {
          return this.$options.filters.VMask(document, '###.###.###-##');
        } else {
          return document;
        }
    },
  },
};
</script>

<style>
  #empresa-select .modal-header {
    background: none !important;
  }

  #empresa-select .modal-dialog {
    max-width: 550px !important;
  }
  .radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
    left: 0;
  }
</style>