import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData){
           this.table.items = [
            {
              "id_empresa": 1,
              "nome_empresa": "Empresa Tutorial",
              "base_nacional": false,
              "documento": "000000000000000",
              "cadastro": "Completo",
              "cidade": "Florianópolis",
              "uf": "SC",
              "ufs_enderecos_empresa": null,
              "total_colaborador": 0,
              "id_registro_empresa": null,
              "documento_formatado": "31.540.876/0001-54",
              "logradouro": null,
              "numero": null,
              "bairro": null,
              "empresa_endereco":[
                {
                  "id_empresa_endereco": null,
                  "logradouro": null,
                  "numero": null,
                  "bairro": null,
                  "cep": null,
                  "id_cidade": null,
                  "id_unidade": null,
                  "cidade": {
                    "uf": null,
                    "nome": null
                  }
                }
              ]
            }
          ]
          this.canEdit = true;
          this.filtering = false;
          this.startPagination(
            {
              total: 1,
              from: 1,
              to: 1,
              current_page: 1
            }
          )
        }
    }
  };