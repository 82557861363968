<template>
  <b-row>
    <div class="d-flex justify-center-center align-items-center mx-2 my-1">
      <span class="mr-1" for="tableSize">Mostrar</span>
      <v-select
        id="tableSize"
        v-model="paginationData.defaultSize"
        @input="updateTableSize"
        :options="tabela.quantidadeLinhasVisualizar"
        :clearable="false"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
    </div>

    <b-table
      id="TabelaTipoRelatorioUsuarioTable"
      responsive
      :no-local-sorting="true"
      :fields="tabela.fields"
      :items="tabela.items"
      @context-changed="handleOrderTable"
    >
    <template #cell(descricao)="row">
        <span>{{ row.item.descricao }}</span>
        <span v-if="getCNPJ(row.item.filtro)"> - CNPJ: {{ getCNPJ(row.item.filtro) | VMask('##.###.###/####-##') }}</span>
      </template>
      <template #cell(criacao)="row">
        <span>{{ moment(row.item.criacao).format("DD/MM/YYYY HH:mm") }}</span>
      </template>

      <template #cell(actions)="row">
        <!-- Botão ver filtro -->
        <feather-icon
          @click="verFiltro(row.item)"
          icon="EyeIcon"
          class="cursor-pointer mr-1 text-custom-blue"
          size="18"
          v-b-tooltip.hover.html
          title="<b>Filtro de pesquisa utilizados</b>"
        />

        <!-- Botão baixar -->
        <feather-icon
          v-if="row.item.id_anexo != null"
          @click="baixarRelatorio(row.item)"
          icon="DownloadIcon"
          class="cursor-pointer text-custom-blue"
          size="18"
          v-b-tooltip.hover.html
          title="<b>Fazer download</b>"
        />
        <feather-icon
          v-else
          icon="DownloadIcon"
          size="18"
          v-b-tooltip.hover.html
          :title="row.item.situacao == 'Sucesso' ? '<b>Não há dados para exportar</b>' : '<b>Processando</b>'"
        />
      </template>
    </b-table>
    <h4 v-if="tabela.items.length == 0" class="d-block w-100 text-center">
      <span v-if="filtering">Processando.</span>
      <span v-else>Nenhum registro encontrado.</span>
    </h4>

    <b-col class="px-3" sm="12" v-if="showTable">
      <CustomPagination
        :paginacao="paginationData"
        @page-cliked="updateCurrentPage"
      />
    </b-col>
  </b-row>
</template>

<script>
import { BCol, BRow, BButton, BTable, BTooltip } from "bootstrap-vue";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import moment from "moment";
import vSelect from "vue-select";
import {
  TIPO_RELATORIO_ENUM,
  TEXTO_PADRAO_SEM_FILTRO,
} from "@/views/custom-pages/relatorio/config";
import tourReportMixin from "../tour-mixin/tourReportMixin";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BTable,
    CustomPagination,
    vSelect,
    BTooltip,
  },
  mixins: [tourReportMixin],
  data() {
    return {
      moment,
      showTable: false,
      filtering: false,
      paginationData: {
        currentPage: 0,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        quantidadeLinhasVisualizar: [10, 25, 50, 100],
        ordem: "desc",
        campoOrdenado: "criacao",
        fields: [
          {
            key: "descricao",
            label: "Relatório",
            sortable: true,
            class: "text-left tipo_relatorio",
          },
          {
            key: "criacao",
            label: "Data",
            sortable: true,
            class: "text-center criacao",
          },
          {
            key: "situacao",
            label: "Situação",
            sortable: true,
            class: "text-center situacao",
          },
          {
            key: "actions",
            label: "AÇÕES",
            sortable: false,
            class: "width-custom-column text-center actions",
          },
        ],
        items: [],
      },
      csvDownload: [
        TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID
      ]
    };
  },
  beforeMount() {
    if(this.loadData){
      this.setRelatoriosGerados();
    }
  },
  methods: {
    baixarRelatorio(item) {
      const rota = this.$api.tipoRelatorioUsuarioExportar(
        item.id_tipo_relatorio_usuario
      );
      (async () => {
        try {
           this.$http.post(rota).then(({ data }) => {
            let fileContent = data.conteudo,
              filename = data.descricao;
            if(this.csvDownload.includes(item.id_tipo_relatorio)) {
              filename = filename.replace(".xlsx", ".csv");
              fileContent = this.prepareDataForCSV(fileContent);
              this.$helpers.downloadArchiveCsv(fileContent, filename);
            }else{
              filename = filename.replace(".csv", ".xls");
              this.$helpers.downloadArchiveXlsx(fileContent, filename, false);
            }

           });
        } catch (error) {
          this.modalErroExportarRelatorio();
        }
      })();
    },
    prepareDataForCSV(data) {
      const header = Object.keys(data[0]).join(";");
      let content = "";

      data.map((item) => {
        let values = Object.values(item).join(";");
        content += values + "\n";
      })

      return `${header}\n${content}`;
    },

    verFiltro(item) {
      this.$swal({
        title: item.descricao,
        icon: null,
        iconColor: "green",
        html: this.retornaFiltroFormatado(item),
        customClass: {
          popup: "custom-modal-size",
          confirmButton: "d-none",
          cancelButton: "d-none",
        },
        buttonsStyling: false,
      });
    },

    modalErroExportarRelatorio() {
      this.$swal({
        title: "Ocorreu um erro",
        icon: "warning",
        iconColor: "red",
        html: "Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.",
        customClass: {
          popup: "custom-modal-size",
          confirmButton: "d-none",
          cancelButton: "d-none",
        },
        buttonsStyling: false,
      });
    },

    updateTableSize(event) {
      if (!event) {
        this.paginationData.defaultSize = 10;
      }
      this.filtering = true;

      this.paginationData.currentPage = 1;
      this.setRelatoriosGerados();
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.setRelatoriosGerados();
    },

    startPagination(data) {
      this.paginationData.currentPage = data.current_page;
      this.paginationData.totalLines = data.total;
      this.paginationData.fromLine = data.from;
      this.paginationData.toLine = data.to;
    },

    handleOrderTable(context) {
      this.tabela.campoOrdenado = context.sortBy;
      this.tabela.ordem = context.sortDesc ? "desc" : "asc";

      this.setRelatoriosGerados();
    },

    getParamsSearch() {
      return {
        params: {
          porPagina: this.paginationData.defaultSize,
          perPage: this.paginationData.defaultSize,
          pagina: this.paginationData.currentPage,
          page: this.paginationData.currentPage,
          colunaOrdem: this.tabela.ordem,
          colunaNome: this.tabela.campoOrdenado,
        },
      };
    },

    setRelatoriosGerados() {
      this.$http
        .get(this.$api.tipoRelatorioUsuario(), this.getParamsSearch())
        .then((response) => {
          if (response.status != 204) {
            this.tabela.items = response.data.data;            
            this.startPagination(response.data);
            this.showTable = response.data.data.length > 0;
          }
        });
    },

    retornaFiltroFormatado(item) {
      if (item.filtro == "[]") {
        return this.getTextoPadraoSemFiltroPorTipoRelatorio(
          item.id_tipo_relatorio
        );
      }

      let tooltipText = "";
      let filtros = JSON.parse(item.filtro);

      

      Object.keys(filtros).forEach((key) => {
        const value = filtros[key];

        
        if (item.id_tipo_relatorio == TIPO_RELATORIO_ENUM.COLABORADORES_CADASTRADOS_ID) {
          if (!['CNPJ', 'Razão Social'].includes(key)) {
            tooltipText += `<h5 class='btn-custom-blue'>${key}</h5>`;            
          }

          if(key == 'Empresa') {
            tooltipText += `<h6>` + this.formatCnpjCpf(filtros['CNPJ']) + `</h6>`;
            tooltipText += `<h6>` + filtros['Empresa'][0] + `</h6>`;
            tooltipText += `<h6>` + filtros['Razão Social'] + `</h6>`;
          }else if(!['CNPJ', 'Razão Social'].includes(key)){
            tooltipText +=
              `<h6>` + value.map((descricao) => descricao).join(", ") + `</h6>`;
          }
        }else{
          tooltipText += `<h5 class='btn-custom-blue'>${key}</h5>`;
          tooltipText +=
            `<h6>` + value.map((descricao) => descricao).join(", ") + `</h6>`;
        }

      });
      return tooltipText;
    },
    getCNPJ(filtro) {
      let filtros = JSON.parse(filtro);

      return filtros['CNPJ'];
    },
    formatCnpjCpf(document) {
      if (document.length === 14) {
          return this.$options.filters.VMask(document, '##.###.###/####-##');
        } else if (document.length === 11) {
          return this.$options.filters.VMask(document, '###.###.###-##');
        } else {
          return document;
        }
    },
    
    getTextoPadraoSemFiltroPorTipoRelatorio(idTipoRelatorio) {
      let html = TEXTO_PADRAO_SEM_FILTRO.NENHUM_FILTRO_SELECIONADO;
      switch (idTipoRelatorio) {
        case TIPO_RELATORIO_ENUM.EMPRESAS_CADASTRADAS_ID:
          html = TEXTO_PADRAO_SEM_FILTRO.EMPRESAS_CADASTRADAS.join("");
          break;
        case TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID:
          html = TEXTO_PADRAO_SEM_FILTRO.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL.join("");
          break;
        case TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID:
          html = TEXTO_PADRAO_SEM_FILTRO.QUANTITATIVO_VACINACAO.join("");
          break;
        case TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID:
          html = TEXTO_PADRAO_SEM_FILTRO.COLABORADORES_VACINADOS.join("");
          break;
        case TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID:
          html = TEXTO_PADRAO_SEM_FILTRO.ADESAO_POR_EMPRESAS_BASE_NACIONAL.join("");
          break;
        case TIPO_RELATORIO_ENUM.AGENDAMENTOS_ID:
          html = TEXTO_PADRAO_SEM_FILTRO.AGENDAMENTOS.join("");
        default:
          break;
      }

      return html;
    },
  },
};
</script>

<style lang="scss">
.custom-modal-size {
  width: 80% !important;
  max-width: 600px !important;
}
.swal2-html-container {
  overflow: hidden !important;
  h5 {
    padding: 5px;
  }
  .swal2-content h6:last-child {
    margin-bottom: 0;
  }
}
</style>