<template>
  <div class="content-wrapper">
    <page-header screenName="Ver adesões nacionais" :linkItems="linkItems" />

    <div class="card acceptances-list">
      <b-tabs active-nav-item-class="text-custom-blue" :align="alignTabs">
        <b-tab title="Lançados" active>
          <List :statusAdhesion="'Concluído'" />
        </b-tab>
        <b-tab title="Cancelados">
          <List :statusAdhesion="'Cancelado'" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import { BTabs, BTab, BSpinner } from 'bootstrap-vue'
import List from './List.vue';

export default {
  title: "Ver pedidos",
  
  components: {
    PageHeader,
    BTabs,
    BTab,
    BSpinner,
    List
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Adesão Nacional',
          active: true,
          routeName: 'listar-adesao-nacional',
        },
      ],

      alignTabs: 'center',

      ordersPosted: [

      ],
      ordersOutline: [],
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>

<style>
.nav-tabs .nav-link:after {
    left: 0;
    background: #2771c0b7 !important;
}
</style>