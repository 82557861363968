import { actions, subjects } from '@/libs/acl/rules'

export default [
  {
    path: '/adesao',
    name: 'acceptances',
    component: () => import('@/views/custom-pages/adesao/Acceptances/Index.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/adesao/aderir-campanha',
    name: 'join-campaign',
    component: () => import('@/views/custom-pages/adesao/JoinCampaign/Index.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/adesao/pedidos',
    name: 'purchase',
    component: () => import('@/views/custom-pages/adesao/Purchase/Index'),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/adesao/editar-pedido',
    name: 'editar-pedido',
    component: () => import('@/views/custom-pages/adesao/Purchase/Index'),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },

  },
  {
    path: '/adesao/pedido/detalhes',
    name: 'detalhes-pedido',
    component: () => import('@/views/custom-pages/adesao/Details/CompanyOrder/CompanyOrderDetails.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/adesao/visualizar',
    name: 'visualizar-adesao-empresa',
    component: () => import('@/views/custom-pages/adesao/Details/CompanyOrder/CompanyOrderDetails.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.ADESAO_MENUS_ROTAS,
      action: actions.VISUALIZAR,
    },
  },

  {
    path: '/adesao-nacional',
    name: 'listar-adesao-nacional',
    component: () => import('@/views/custom-pages/adesao-nacional/list/Index.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/adesao-nacional/visualizar',
    name: 'view-adesao-nacional',
    component: () => import('@/views/custom-pages/adesao-nacional/View.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL,
      action: actions.VISUALIZAR,
    },
  },
  {
    path: '/adesao-nacional/atualizar',
    name: 'edit-adesao-nacional',
    component: () => import('@/views/custom-pages/adesao-nacional/Update.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL,
      action: actions.ATUALIZAR,
    },
  },
  {
    path: '/adesao-nacional/aderir-campanha',
    name: 'choose-campaign-nacional',
    component: () => import('@/views/custom-pages/adesao-nacional/ChooseCampaign.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL,
      action: actions.INSERIR,
    },
  },
  {
    path: '/adesao-nacional/novo',
    name: 'pedido-adesao-nacional',
    component: () => import('@/views/custom-pages/adesao-nacional/Register.vue'),
    meta: {
      resource: 'ACL',
      subject: subjects.TIPO_ATUACAO_ADESAO_BASE_NACIONAL,
      action: actions.INSERIR,
    },
  },
]
