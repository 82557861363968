var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"add-new-lote","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":"","hide-footer":"","centered":""},on:{"shown":_vm.onModalShown},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.loading)?_c('div',{staticClass:"modal-spinner"},[_c('b-spinner')],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"d-flex flex-column my-2"},[_c('h2',[_vm._v("Novo Lote")]),_c('validation-observer',{ref:"itemRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Lote*","label-for":"lote-input"}},[_c('validation-provider',{attrs:{"name":"lote","rules":{ required: true, uniqueLote: { lotes: _vm.lotes, disabled: _vm.editing } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editing,"id":"lote-input","placeholder":"N° do Lote","maxLength":"15"},model:{value:(_vm.item.lote),callback:function ($$v) {_vm.$set(_vm.item, "lote", $$v)},expression:"item.lote"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2448089240)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Código Externo","label-for":"codigo-externo-input"}},[_c('validation-provider',{attrs:{"name":"codigoExterno","rules":"max:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"codigo-externo-input","placeholder":"Cód. sistema externo","disabled":_vm.disabled.codigoExterno},model:{value:(_vm.item.codigoExterno),callback:function ($$v) {_vm.$set(_vm.item, "codigoExterno", $$v)},expression:"item.codigoExterno"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Máximo de 7 caracteres. ")]):_vm._e()]}}],null,false,2975571965)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Fabricante*","label-for":"fabricante-input"}},[_c('validation-provider',{attrs:{"name":"fabricante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"fabricante-input","options":_vm.fabricanteSelect,"label":"descricao","placeholder":"Selecione o fabricante","clearable":true,"disabled":_vm.disabled.fabricante},model:{value:(_vm.item.fabricante),callback:function ($$v) {_vm.$set(_vm.item, "fabricante", $$v)},expression:"item.fabricante"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}],null,false,3551079575)})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Vacina*","label-for":"vacina-input"}},[_c('validation-provider',{attrs:{"name":"vacina","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vacina-input","options":_vm.vacinaSelect,"label":"nome_tecnico_vacina","placeholder":"Selecione uma vacina","clearable":true,"disabled":_vm.disabled.vacina},model:{value:(_vm.item.vacina),callback:function ($$v) {_vm.$set(_vm.item, "vacina", $$v)},expression:"item.vacina"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}],null,false,4157115541)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de frasco*","label-for":"dose-input"}},[_c('validation-provider',{attrs:{"name":"tipo_dose","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"dose-input","options":_vm.frascoSelect,"label":"descricao","placeholder":"Selecione um frasco","clearable":true,"disabled":_vm.disabled.frasco},model:{value:(_vm.item.frasco),callback:function ($$v) {_vm.$set(_vm.item, "frasco", $$v)},expression:"item.frasco"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v("Nenhuma opção selecionável.")])]),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Este campo é de preenchimento obrigatório. ")]):_vm._e()]}}],null,false,2789645374)})],1)],1),_c('b-col',{attrs:{"lg":"6","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Validade*","label-for":"validade-input"}},[_c('validation-provider',{attrs:{"name":"validade","rules":{ required: true, isPastDate: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"form-control",attrs:{"id":"validade-input","placeholder":"00/00/0000","autocomplete":"off","disabled":_vm.disabled.validade},on:{"input":_vm.setInput},model:{value:(_vm.input.validade),callback:function ($$v) {_vm.$set(_vm.input, "validade", $$v)},expression:"input.validade"}}),_c('b-input-group-append',[_c('b-form-datepicker',{staticClass:"form-control d-flex align-items-center",attrs:{"locale":"pt-BR","button-only":"","right":"","hide-header":true,"label-help":"Selecione a data de validade","button-variant":"custom-blue","clearable":"","disabled":_vm.disabled.validade},on:{"input":_vm.setPicker},model:{value:(_vm.item.validade),callback:function ($$v) {_vm.$set(_vm.item, "validade", $$v)},expression:"item.validade"}})],1)],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,2346726961)})],1)],1)],1)],1)],1)],1):_vm._e(),(!_vm.loading)?_c('section',{staticClass:"d-flex justify-content-center mb-1"},[_c('b-button',{staticClass:"mr-2 mb-2-sm",attrs:{"id":"save-lote","variant":"custom-blue","disabled":_vm.saving},on:{"click":function($event){$event.preventDefault();return _vm.confirmAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.editing ? 'Editar Lote' : 'Inserir Lote'))]),(_vm.saving)?_c('output',{staticClass:"spinner-border spinner-border-sm ml-1"}):_vm._e()],1),_c('b-button',{attrs:{"id":"cancel-lote","disabled":_vm.saving,"variant":"outline-danger"},on:{"click":function($event){$event.preventDefault();return _vm.cancelAction.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Descartar")])],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }