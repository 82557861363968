<template>
  <b-form-group
    :label="ehObrigatorio? 'Campanha*': 'Campanha'"
    label-for="campanha"
    label-class="font_size_label campanha"
  >
    <validation-provider #default="{ errors }" name="Campanha" :rules="rules">
      <v-select
        id="campanha"
        :options="options"
        v-model="selected"
        placeholder="Selecione uma campanha"
        label="descricao"
        :clearable="true"
        :multiselect="ehMultiplo"
        :multiple="ehMultiplo"
        :disabled="disabled"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { tipoCampanhaEnum } from "@/enum/tipoCampanha";
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { TIPO_RELATORIO_ENUM } from "../config";

import {getUserData} from "@/auth/utils";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
    idTipoRelatorio: {
      required: true,
      type: Number
    },
    departamento: {
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [],
      userData: getUserData(),
      selected: this.value,
      parametros: {
        params: {
          paginacao: false,
          tipoCampanha: this.idTipoRelatorio == TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID ? tipoCampanhaEnum.NACIONAL : tipoCampanhaEnum.REGIONAL
        },
      },
      rules: '',
      ehMultiplo: false,
      ehObrigatorio: false,
      relatoriosObrigatorios: [
        TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_BASE_NACIONAL_ID,
        TIPO_RELATORIO_ENUM.ADESAO_POR_EMPRESAS_CAMPANHA_ESTADUAL_ID,
        TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID,
        TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID,
      ]
    };
  },
  beforeMount() {
    this.setParams();
    this.setOptions();
  },
  mounted() {
    this.ehObrigatorio = this.relatoriosObrigatorios.includes(this.idTipoRelatorio);
    this.rules = this.ehObrigatorio ? 'required' : ''
  },
  methods: {
    setParams() {
      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.AGENDAMENTOS_ID) {
        this.parametros.params.tipoCampanha = null;
        this.parametros.params.situacao = ['Em andamento', 'Concluída'];
        this.parametros.params.uf = this.departamento ? this.departamento.uf : null;
      }
    },
    async setOptions() {
      await this.$http
        .get(this.$api.campanha(), this.parametros)
        .then(({ data }) => {
          const ufConcatenated = this.verifyIfFildHasUfConcatenated()
          this.options = data.map((campanha) => {
            let campaignDescription = campanha.campanha_descricao;
            if(ufConcatenated){
              campaignDescription = campanha.departamento_uf ? campanha.departamento_uf + ' - ' + campanha.campanha_descricao : 'BN - ' + campanha.campanha_descricao;
            }
            return {
              id_campanha: campanha.id_campanha,
              descricao: campaignDescription,
              uf: campanha.departamento_uf,
              inicio_campanha: campanha.inicio_campanha.substring(0, 10),
              termino_campanha: campanha.termino_campanha.substring(0, 10),
            };
          });
          this.sortOptions();
         
        });
    },
    sortOptions() {
      this.options.sort((a, b) => {
          if (!a.uf && b.uf) {
            return -1;
          } else if (!b.uf && a.uf) {
            return 1;
          } else {
            return a.descricao.localeCompare(b.descricao);
          }
        });
    },

    perfilAdminDNOrAdminEmpresa() {
      const profiles = this.userData.perfis.map(profile => profile.id_perfil);

      return (profiles.includes(this.$perfil.ADMIN_DN) || profiles.includes(this.$perfil.ADMIN_EMPRESA));
    },

    perfilSaudeDNOrAdminEmpresa() {
      const profiles = this.userData.perfis.map(profile => profile.id_perfil);
      
      return (profiles.includes(this.$perfil.SAUDE_DN) || profiles.includes(this.$perfil.ADMIN_EMPRESA));
    },
    perfilDNorAdminEmpresa(){
      const profiles = this.userData.perfis.map(profile => profile.id_perfil);
      
      return [
        this.$perfil.ADMIN_DN,
        this.$perfil.SAUDE_DN,
        this.$perfil.ADMIN_EMPRESA
      ].some(profile => profiles.includes(profile));
    },
    verifyIfFildHasUfConcatenated() {

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.QUANTITATIVO_VACINACAO_ID) {
          return this.perfilAdminDNOrAdminEmpresa();
      }

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.COLABORADORES_VACINADOS_ID) {
          return this.perfilSaudeDNOrAdminEmpresa();
      }

      if (this.idTipoRelatorio == TIPO_RELATORIO_ENUM.AGENDAMENTOS_ID) {
          return this.perfilDNorAdminEmpresa();
      }

      return false;
    }
  },
  watch: {
    selected: function (valor) {
      valor = this.ehMultiplo ? valor : [valor];
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.$emit("options", valor);
    },
    departamento: function (valor) {
      this.setParams();
      this.setOptions();
    }
  },
};
</script>