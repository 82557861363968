import { getTourMode, getTourSteps, getTourCurrentStep } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode(),
        tourSteps: [{}],
      };
    },
    mounted(){
      if(!this.loadData){
        this.tourSteps = getTourSteps();
        this.$tours.vuexyTour.currentStep = getTourCurrentStep();
      }
    },
    beforeMount() {
        if(!this.loadData){
          this.showLoading = false;
          this.populated = true;
          this.campaignDescription = 'Campanha Regional da Gripe 2025'
          this.count = 1000
            this.order = {
              "id_adesao": 1,
              "id_empresa": 1,
              "id_unidade": 1,
              "observacao": null,
              "data_prevista": "2024-10-22",
              "nome_cidade": "Florianópolis",
              "uf": "SC",
              "empresa": {
                  "id_empresa": 1,
                  "id_tipo_empresa": 1,
                  "id_tipo_preco_empresa": 1,
                  "nome_empresa": "Empresa Tutorial",
                  "documento": "00000000000000",
                  "telefone_empresa": "0000000000",
                  "responsavel": "Responsável da Empresa Tutorial",
                  "email": "responsavel@empresa.com.br",
                  "empresa_endereco": [],
                  "empresa_contato": []
              },
              "sesi_responsavel": {
                  "id_unidade": 55,
                  "descricao": "UO - SESI Florianópolis",
                  "email_responsavel_unidade": "responsavel@sesisc.org.br"
              },
              "doses": [
                  {
                      "id_campanha_vacina_unidade": 1,
                      "id_vacina": 1,
                      "id_incentivo": 2,
                      "saldo": 1000,
                      "qtd_distribuida": 10,
                      "vacina_nome_tecnico": "Quadrivalente Influenza Sazonal",
                      "vacina_nome_popular": "Quadrivalente",
                      "valor": 5000,
                      "valor_total": 5000,
                      "custo_unitario": 50,
                      "nome_categoria": "Trabalhador",
                      "id_categoria": 1
                  }
              ],
              "termo": {
                  "id_anexo": 1,
                  "arquivo": "/anexo/adesao-contrato/termo_padrao.pdf",
                  "id_tipo_anexo": 2,
                  "descricao": "termo_padrao.pdf",
                  "id_termo": 1,
                  "id_campanha": 1
              },
              "observation": "---",
              "expira_em": 10
            }

          }
        
    }
  };