import { getTourMode, getTourSteps, getTourCurrentStep } from "@/@core/utils/store/getStore";
import {
  AdesaoGetEmpresas
} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers";

export default {
    data() {
      return {
        loadData: !getTourMode(),
        tourSteps: [{}],
        empresaTutorial: null
      };
    },
    beforeMount() {
      if(!this.loadData){

        this.empresaTutorial = {
          "possui_convenio":false,
          "id_empresa":1,
          "id_unidade":1,
          "cnpj_faturamento":"0000000000000",
          "id_tipo_empresa":1,
          "id_tipo_preco_empresa":1,
          "nome_empresa":"Empresa Tutorial",
          "documento":"00000000000000",
          "cadastro":"Completo",
          "id_tipo_preco":1,
          "trabalhador":10,
          "dependente":10,
          "terceiro":10
          };
          
        const campaign = {
          "id_campanha":1,
          "permite_adesao_parcial":false,
          "campanha_descricao":"Campanha Regional da Gripe 2025",
          "termo":[]
        };
  
        this.$store.commit(
          "adesaoState/SET_CHOOSE_CAMPAIGN",
          campaign
        );
        this.$store.commit(
          "adesaoState/SET_CHOOSE_COMPANY",
          this.empresaTutorial
        );
      }
    },
    mounted() {
        if(!this.loadData){
            const vacinas = [
              {
                  "id_vacina": 1,
                  "nome_tecnico": "Quadrivalente Influenza Sazonal",
                  "nome_popular": "Quadrivalente",
                  "estimativas": [
                      {
                          "id_vacina": 1,
                          "id_incentivo": 2,
                          "saldo": 1000,
                          "saldo_restante": 1000,
                          "id_categoria": 1
                      },
                      {
                          "id_vacina": 1,
                          "id_incentivo": 2,
                          "saldo": 1000,
                          "saldo_restante": 1000,
                          "id_categoria": 2
                      },
                      {
                          "id_vacina": 1,
                          "id_incentivo": 2,
                          "saldo": 1000,
                          "saldo_restante": 1000,
                          "id_categoria": 3
                      }
                  ]
              }
          ];
            
            const vacinasDoses = [
              {
                "id_vacina": 1,
                "id_incentivo": 2,
                "id_tipo_preco": 1,
                "descricao": "Indústria",
                "nome_tecnico": "Quadrivalente Influenza Sazonal",
                "doses": {
                  "sem_fomento": {
                    "trabalhador": {
                      "qtd": 0,
                      "valor": 0,
                      "qtdDisponivel": 10,
                      "total": 0,
                      "id_categoria": null
                    },
                    "terceiro": {
                      "qtd": 0,
                      "valor": 0,
                      "qtdDisponivel": 10,
                      "total": 0,
                      "id_categoria": null
                    },
                    "dependente": {
                      "qtd": 0,
                      "valor": 0,
                      "qtdDisponivel": 10,
                      "total": 0,
                      "id_categoria": null
                    }
                  }
                }
              }
            ];

            const empresaJaIncluida = AdesaoGetEmpresas().length ? AdesaoGetEmpresas()[0].id_empresa : null;
            this.$store.dispatch("adesaoState/setVacinas", vacinas);
            this.$store.dispatch("adesaoState/initEmpresasDoses", vacinasDoses);
            if(empresaJaIncluida != this.empresaTutorial.id_empresa){
              this.$store.dispatch("adesaoState/addEmpresa", this.empresaTutorial);
            }
            this.$store.dispatch("adesaoState/setValidacaoDosesEmpresa", null);
            this.tourSteps = getTourSteps();
            this.$tours.vuexyTour.currentStep = getTourCurrentStep();
        }
    }
  };