<template>
  <section>
    <b-table
        id="tableSubgrupo"
        responsive
        :stickyColumn="true"
        :busy.sync="filtering"
        :fields="tableBusiness.fields"
        :items="tableBusiness.items"
        no-local-sorting
        :no-sort-reset="true"
        @sort-changed="ordenarColuna"
    >
      <template #cell(documento)="row">
        <div class="comprimento_maximo_celulas">
            <span class="font-weight-bold comprimento_maximo_celulas"
                  v-if="row.item.documento.length == 11"
                  v-b-tooltip
                  :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
              {{ row.item.documento | VMask('###.###.###-##') }}
              </span>
          <span class="font-weight-bold comprimento_maximo_celulas"
                v-else
                v-b-tooltip
                :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
              {{ row.item.documento | VMask('##.###.###/####-##') }}
              </span>
        </div>
      </template>
      <template #cell(cidade)="row">
        {{ row.item.nome }}
      </template>
      <template #cell(uf)="row">
        {{ row.item.uf }}
      </template>
      <template #cell(status)="row">
        <RowSituacaoCadastro
            :length-column-maximo="lengthColumnMaximo"
            :situacao-cadastro="row.item.situacao_cadastro"
            classe="comprimento_maximo_celulas"
        />
      </template>
      <template #cell(acoes)="row">
        <feather-icon id="view-document" role="button" class="text-custom-blue mr-1" icon="EyeIcon" size="18"
                      @click.prevent="viewGroupBusiness(row.item.id_grupo_negocio)" />
      </template>
    </b-table>
    <div class="pb-2 px-1">
      <span v-if="withoutBusiness" class="ml-1">Nenhum negócio encontrado</span>
      <b-col sm="12" class="mt-2">
        <CustomPagination :paginacao="paginationDataCompany" @page-cliked="updateCurrentPage" />
      </b-col>
    </div>
  </section>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BTab, BTabs } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import RowSituacaoCadastro from "@/views/components/custom/empresa/RowSituacaoCadastro.vue";
export default {
  props: {
    idGroupBusiness: {
      required: true,
    },
    groupBusiness: {
      required: true,
    },
  },

  components: {
    RowSituacaoCadastro,
    BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable,
    CustomPagination, BTab, BTabs, TableFilter
  },

  data() {
    return {
      filtering: false,
      paginationDataCompany: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      tableBusiness: {
        fields: [
          { key: "documento", label: "CPF/CNPJ", sortable: true, class: 'width_columns width_columns_header width_columns_header_company' },
          { key: "Cidade", label: "Cidade", sortable: true, class: 'width_columns width_columns_header' },
          { key: "UF", label: "UF", sortable: true, class: 'width_columns_small width_columns_header' },
          { key: "Status", label: "Status", sortable: true, class: 'width_columns_small width_columns_header' },
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'id_grupo_documento',
        order: 'asc'
      },
      withoutBusiness: false,
      lengthColumnMaximo: 28,
    }
  },

  async mounted() {
    this.tableBusiness.items = this.business
    await this.loadScheduleData()
  },

  methods: {

    async loadScheduleData() {
      const params = {
        id_grupo_negocio: this.idGroupBusiness,
        pagina: this.paginationDataCompany.currentPage,
        colunaOrdem: this.tableBusiness.order,
        colunaNome: this.tableBusiness.columnOrder,
        porPagina: this.paginationDataCompany.porPagina,
        todos: false,
      }
      this.$http.get(this.$api.grupoEmpresa(), {params: params}).then((resp) => {
        this.tableBusiness.items = resp.data.data
        if(resp.data.data.length == 0) {
          this.withoutBusiness = true
        }
        this.startPagination(resp.data)
      })
    },
    updateCurrentPage(page) {
      this.paginationDataCompany.currentPage = page
      this.filtering = true
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy
      this.table.order = sort.sortDesc ? 'desc' : 'asc'
      this.filtering = true
      this.loadScheduleData()
    },

    startPagination(data) {
      this.paginationDataCompany.currentPage = data.current_page
      this.paginationDataCompany.totalLines = data.total
      this.paginationDataCompany.fromLine = data.from
      this.paginationDataCompany.toLine = data.to
    },
  }
}

</script>
