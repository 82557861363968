<template>
  <section>
    <ConfirmDeleteBusinessModal
        :modalShow="modal.modalConfirm.modalShow"
        @confirmed="confirmDeleteBusiness"
        @canceled="closeModalConfirm"
    />
    <b-row>
      <b-col
          lg="2"
          class="mt-2 button-create-subgrupo"
      >
        <b-button
            class="ml-auto"
            variant="primary-button"
            @click="redirectToCreateSubGroup()"
        >
          <span class="align-middle">Criar Negócio</span>
        </b-button>
      </b-col>
    </b-row>
    <b-table
        id="tableSubgrupo"
        responsive
        :stickyColumn="true"
        :busy.sync="filtering"
        :fields="tableBusiness.fields"
        :items="tableBusiness.items"
        no-local-sorting
        :no-sort-reset="true"
        @sort-changed="orderColumn"
    >
      <template #cell(descricao)="row">
        {{ row.item.descricao }}
      </template>
      <template #cell(numero_empresas_vinculadas)="row">
        {{ row.item.quantidade_empresas_vinculadas }}
      </template>
      <template #cell(data_criacao)="row">
        {{ row.item.data_criacao }}
      </template>
      <template #cell(ultima_atualizacao)="row">
        {{ row.item.ultimaAlteracao ? row.item.ultimaAlteracao : ' - ' }}
      </template>
      <template #cell(acoes)="row">
        <feather-icon id="view-document" role="button" class="text-custom-blue mr-1" icon="EyeIcon" size="18"
                      @click.prevent="viewGroupBusiness(row.item.id_grupo_negocio)" />
        <feather-icon id="download-document" role="button" class="text-custom-blue mr-1" icon="EditIcon"
                      @click.prevent="editGroupBusiness(row.item.id_grupo_negocio)" size="18" />
        <feather-icon id="download-document" role="button" class="text-custom-blue mr-1" icon="XCircleIcon"
                      @click.prevent="deleteGroupBusiness(row.item)" size="18" />
      </template>
    </b-table>
    <div class="pb-2 px-1">
      <span v-if="withoutBusiness" class="ml-1">Nenhum negócio encontrado</span>
      <b-col sm="12" class="mt-2">
        <CustomPagination :paginacao="paginationDataBusiness" @page-cliked="updateCurrentPage" />
      </b-col>
    </div>
  </section>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend, BTab, BTabs } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import {setCompanyBusiness, setCompanyBusinessId} from '@/@core/utils/store/setStore/index'
import ConfirmDeleteBusinessModal from '@/views/custom-pages/GrupoEmpresa/Components/ConfirmDeleteBusinessModal.vue'
export default {
  props: {
    companies: {
      required: true,
    },
    idGroup: {
      required: true,
    },
    business: {
      required: true,
    }
  },

  components: {
    BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend,
    CustomPagination, BTab, BTabs, TableFilter, ConfirmDeleteBusinessModal
  },

  data() {
    return {
      filtering: false,
      paginationDataBusiness: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      tableBusiness: {
        fields: [
          { key: "descricao", label: "Nome negócio", sortable: false, class: 'width_columns width_columns_header width_columns_header_company' },
          { key: "numero_empresas_vinculadas", label: "Número de empresas vinculadas", sortable: false, class: 'width_columns width_columns_header' },
          { key: "data_criacao", label: "Data de criação", sortable: false, class: 'width_columns_small width_columns_header' },
          { key: "ultima_atualizacao", label: "Última atualização", sortable: false, class: 'width_columns_small width_columns_header' },
          { key: "acoes", label: "AÇÕES", sortable: false, class: 'width_columns_small width_columns_header' }
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'descricao',
        order: 'asc'
      },
      modal: {
        modalConfirm: {
          modalShow: false
        },
      },
      withoutBusiness: false,
      lengthColumnMaximo: 28,
      idGroupBusiness: null,
    }
  },

  mounted() {
    this.tableBusiness.items = this.business
    this.loadScheduleData()
  },

  methods: {
    redirectToCreateSubGroup() {
      setCompanyBusiness(this.setCompaniesNotLinked())
      this.$router.push(
          {
            name: 'create-business',
            query: { id_grupo: this.idGroup },
          },
      )
    },
    setCompaniesNotLinked() {
      return this.companies
    },
    viewGroupBusiness(idGroupBusiness) {
      setCompanyBusinessId(this.idGroupBusiness)
      setCompanyBusiness(this.setCompaniesNotLinked())
      this.$router.push(
          {
            name: 'view-business',
            params: {
              idGroupBusiness: idGroupBusiness,
            },
          },
      )
    },

    deleteGroupBusiness(item) {
      if(item.grupo_negocio_empresa.length == 0) {
        this.modal.modalConfirm.modalShow = true
        this.idGroupBusiness = item.id_grupo_negocio
      } else {
        this.openModalErrorDelete()
      }
    },
    editGroupBusiness(idGroupBusiness) {
      setCompanyBusinessId(idGroupBusiness)
      setCompanyBusiness(this.setCompaniesNotLinked())
      this.$router.push(
          {
            name: 'edit-business',
            params: {
              idGroupBusiness: idGroupBusiness,
            },
          },
      )
    },
    confirmDeleteBusiness(){
      this.$http.delete(this.$api.getAndPutAndDeleteGrupoNegocio(this.idGroupBusiness)).then(response => {
        this.idGroupBusiness = null
        this.$emit('loadInfo', true)
      }).catch((error) => {
        this.openModalErrorDelete(error.response.data.errors)
        this.loading = false
      })
      this.modal.modalConfirm.modalShow = false
    },
    closeModalConfirm() {
      this.modal.modalConfirm.modalShow = false
    },
    openModalErrorDelete(data) {
      this.$swal({
        title: 'Ocorreu um erro.',
        icon: 'warning',
        iconColor: 'red',
        html: data ?
            `<div>${data}</div>` :
            'Não é possível excluir o Negócio. Há empresas vinculadas a ele. Desvincule todas as empresas antes de prosseguir com a exclusão',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-custom-blue',
        },
        buttonsStyling: false,
      })
    },
    updateCurrentPage(page) {
      this.paginationDataBusiness.currentPage = page;
      const indexArrayCompanies = page - 1
      this.loadScheduleData(indexArrayCompanies)
    },

    async loadScheduleData(index = this.paginationDataBusiness.currentPage - 1, order = false, sortOptions = {}) {
      this.paginationDataBusiness.defaultSize = 10;

      let business = this.splitOnPage(this.business, 10)

      if(order) {
        business = this.order(business, sortOptions, index)
      }

      this.tableBusiness.items = business[index]
      this.withoutBusiness = false;

      if (this.business == null) {
        this.withoutBusiness = true
      }

      this.startPagination(business[index], index)
    },

    startPagination(business, fromLine) {
      this.paginationDataBusiness.totalLines = this.getTotalLines()
      this.paginationDataBusiness.fromLine = this.getFromLine(fromLine, business)
      this.paginationDataBusiness.toLine = this.getToLine(business, fromLine)
    },

    getTotalLines() {
      if (this.business == null) {
        return 0
      }

      return this.business.length
    },

    getFromLine(fromLine, business) {
      if (business) {
        return fromLine == 0 ? '1' : String(fromLine) + '1';
      }

      return fromLine == 0 ? '0' : String(fromLine) + '1';
    },

    getToLine(business, fromLine) {
      const totalCompanies = business != null ? business.length : 0

      if (fromLine == 0) {
        return String(totalCompanies)
      }

      if (totalCompanies == 10) {
        return String(1 + fromLine) + '0'
      }

      return String(fromLine) + String(totalCompanies)

    },

    splitOnPage(business, itemPerPage) {
      if (business == null) {
        return 1;
      }

      const pages = [];
      for (let i = 0; i < business.length; i += itemPerPage) {
        pages.push(business.slice(i, i + itemPerPage));
      }
      return pages;
    },

    orderColumn(sort) {
      this.tableBusiness.columnOrder = sort.sortBy;
      this.tableBusiness.order = sort.sortDesc ? 'desc' : 'asc';
      const sortOptions = {
        sortBy: sort.sortBy,
        sortDesc: sort.sortDesc
      }
      this.loadScheduleData(this.paginationDataBusiness.currentPage - 1, true, sortOptions);
    },

    order(companies, sortOptions, index) {
      const self = this

      if (sortOptions.sortDesc) {
        companies[index].sort(function(a, b) {
          if(sortOptions.sortBy == 'data_criacao') {
            return self.parseDate(b.criacao) - self.parseDate(a.criacao)
          }
          if(sortOptions.sortBy == 'ultima_atualizacao' && (b.alteracao || a.alteracao) != null) {
            return self.parseDate(b.alteracao) - self.parseDate(a.alteracao)
          }

          return b.companies[sortOptions.sortBy].localeCompare(a.companies[sortOptions.sortBy]);
        });
      }

      if (!sortOptions.sortDesc) {
        companies[index].sort(function(a, b) {
          if(sortOptions.sortBy == 'data_criacao' || sortOptions.sortBy == 'ultima_atualizacao') {
            return self.parseDate(a.companies[sortOptions.sortBy]) - self.parseDate(b.companies[sortOptions.sortBy])
          }
        });
      }
      return companies
    },

    parseDate(date) {
      const dateSplit = date.split('-')

      return new Date('20' + dateSplit[2].substring(0,2), dateSplit[1] - 1, dateSplit[0])
    }
  },
}

</script>

<style>
.button-create-subgrupo {
  margin: 0px 0px 30px 30px;
}
</style>