<template>
    <b-form-group
      label="Tipo de Vacina"
      label-for="tipo-de-vacina"
      label-class="font_size_label tipo_de_vacina"
    >
      <validation-provider #default="{ errors }" name="Tipo de Vacina">
        <v-select
          id="tipo-de-vacina"
          :options="options"
          v-model="selected"
          placeholder="Selecione o tipo de vacina"
          label="descricao"
          :clearable="true"
          multiselect
          multiple
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
        <small v-if="errors[0]" class="text-danger">
          {{ errors[0] }}
        </small>
      </validation-provider>
    </b-form-group>
  </template>
  
  <script>
  import {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  
  export default {
    components: {
      BForm,
      BCol,
      BRow,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BSpinner,
      BFormDatepicker,
      BFormTextarea,
      BOverlay,
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      value: {
        required: true,
      },
      optionsConsulted: {
        required: false,
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        options: [],
        selected: this.value,
      };
    },
    beforeMount() {
      this.setOptions();
    },
    methods: {
      async setOptions() {
        if (this.optionsConsulted.length > 0) {
          this.options = this.optionsConsulted;
          return;
        }
        await this.$http
          .get(this.$api.saveAndGetVacina(), this.parametros)
          .then(({ data }) => {
            this.options = data.data.map((vacina) => {
              return {
                id_vacina: vacina.id_vacina,
                descricao: vacina.nome_popular,
              };
            });
          });
      },
    },
    watch: {
      selected: function (valor) {
        this.$emit("selected", valor);
      },
      options: function (valor) {
        this.$emit("options", valor);
      },
    },
  };
  </script>
  
  