import { getTourMode } from "@/@core/utils/store/getStore";
import moment from 'moment';

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData){
			this.nacional = false

            this.allCampaigns = [
                {
                    id: 1,
                    name: 'Campanha Regional da Gripe 2025',
                    startDate: '01/01/2021',
                    nacional: false
                }
            ]
            this.filterFields.allCampaigns = this.allCampaigns
            this.selectedCampaign = this.allCampaigns[0]

            this.filterFields.allCompanies = [
                {
                    id: 1,
                    name: 'Empresa Tutorial'
                }
            ]

            this.vaccinationAbsoluteData[0].value = 75
            this.vaccinationAbsoluteData[1].value = 10
            this.vaccinationAbsoluteData[2].value = 5
            this.vaccinationAbsoluteData[3].value = 10

            this.VaccinationDonutChartPanelData = {
                dosesSolicitadas: 100,
                dosesAplicadas: 90
            }

            this.vaccinationRangeData = {
                totalColaborators: 100,
                vaccinatedColaborators: 90
            }

            const vaccinationData = {
                "historico_anos": [
                  "2025",
                  "2025",
                  "2025"
                ],
                "historico_periodo_inicial": [
                  "21-10-2025"
                ],
                "historico_periodo_final": [
                  "27-12-2025"
                ],
                "historico": [
                  {
                    "quantidade": 15,
                    "numero_semana": 43,
                    "periodo": {
                      "inicio": "21-10-2025",
                      "fim": "27-10-2025"
                    }
                  },
                  {
                    "quantidade": 40,
                    "numero_semana": 44,
                    "periodo": {
                      "inicio": "27-10-2025",
                      "fim": "12-11-2025"
                    }
                  },
                  {
                    "quantidade": 35,
                    "numero_semana": 45,
                    "periodo": {
                      "inicio": "12-11-2025",
                      "fim": "27-12-2025"
                    }
                  }
                ]
              }

            let dataWeek2 = []
            let numWeek2 = []
            let subWeek2 = []
            let historicoCompleto2 = []

            for (let i = 0; i < vaccinationData.historico.length; i++) {
                dataWeek2[i] = vaccinationData.historico[i].quantidade;
                numWeek2[i] = "Semana " + (i + 1);
            }
            subWeek2 = vaccinationData.historico_anos;
            historicoCompleto2 = vaccinationData.historico_periodo_inicial.map((value, index) => {
                return `${value} até ${vaccinationData.historico_periodo_final[index]}`;
            });

            this.evolutionData = {
                historicoCompleto: historicoCompleto2,
                numWeek: numWeek2,
                dataWeek: dataWeek2,
                subWeek: subWeek2
            }

			this.updatedAt = `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`

            this.campaignExists = true
            this.isLoading = false
			this.$emit("loadFinished", true)
        }
    }
  };