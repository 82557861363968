import router from '@/router'
import { setTourMode, setTourSteps, setTourCurrentStep, setTourModalType, setTourWatchingTopic, setTourWatchingAllTutorials } from '@/@core/utils/store/setStore';
import { getTourWatchingTopic } from '@/@core/utils/store/getStore';
import companySteps from "@/views/components/custom/Tour/Steps/companySteps";

const joinCampaign = [
    {
      target: '#adesao',
      header: false,
      content: '<strong class="text-dark font-size-16">Aderir Campanha - Passo 1</strong><br><span>Selecione a Campanha, a Empresa e o Endereço que deseja realizar a Adesão e clique em Avançar.</span>',
      params: {
        placement: 'top'
      },
      highlight: true,
      actionOnSequence: () => {
        router.push({
          name: 'purchase',
        })
        setTourCurrentStep(1)
      },
      actionOnPrevStep: () => {
        setTourSteps(companySteps.listAndViewCompany);
        setTourCurrentStep(5);

        router.push({
          name: 'empresa-usuario-register'
        });
      }
    },
    {
      target: '#adesao',
      header: false,
      content: '<strong class="text-dark font-size-16">Aderir Campanha - Passo 2</strong><br><span>Insira a quantidade de doses que deseja adquirir por vacina e tipo de pessoa (Trabalhador, Dependente e Terceiro). Se desejar, adicione observação/sugestão sobre a data de vacinação. Clique em Avançar.</span>',
      params: {
        placement: 'top'
      },
      highlight: true,
      actionOnSequence: () => {
        router.push({ name: "detalhes-pedido" })
        setTourCurrentStep(2)
      },
      actionOnPrevStep: () => {
        setTourSteps(joinCampaign);
        setTourCurrentStep(0);

        router.push({
          name: 'join-campaign'
        });
      }
    },
    {
      target: '#adesao',
      header: false,
      content: '<strong class="text-dark font-size-16">Aderir Campanha - Passo 3</strong><br><span>Clique em “Editar o Pedido” caso tenha alguma inconsistência. Leia o Termo de Adesão, marque a leitura e clique em Finalizar Adesão. Confirme a Adesão clicando em “Sim, finalizar a adesão”.</span>',
      params: {
        placement: 'left-top'
      },
      highlight: true,
      actionOnSequence: () => {
        setTourMode(true);
        setTourSteps(listAdhesion);

        if (getTourWatchingTopic()) {
          setTourWatchingAllTutorials(false);
        }

        router.push({ name: "acceptances" })
      },
      actionOnPrevStep: () => {
        setTourSteps(joinCampaign);
        setTourCurrentStep(1);

        router.push({
          name: 'purchase'
        });
      }
    }
  ];

const listAdhesion = [
  {
    target: '#list-orders',
    header: false,
    content: '<strong class="text-dark font-size-16">Ver Pedidos - Pesquisar e Listar</strong><br><span>Pesquise as adesões realizadas e tenha acesso aos detalhes clicando em Ações (ícone do olho).</span>',
    params: {
      placement: 'top'
    },
    highlight: true,
    actionOnSequence: () => {
      if (getTourWatchingTopic()) {
        setTourWatchingTopic(false);
        setTourMode(false);
        setTourSteps([]);

        router.push({
          name: 'pages-faq',
        })
      }else{
        setTourMode(false);
        setTourSteps([]);
        setTourModalType('Adesao');
      }
    },
    actionOnPrevStep: () => {
      setTourSteps(joinCampaign);

      router.push({
        name: 'join-campaign'
      });
    }
  }
];

export default {
    joinCampaign: joinCampaign,
    listAdhesion: listAdhesion
}

