import { getTourMode, getTourSteps, getTourCurrentStep } from "@/@core/utils/store/getStore";
import { setTourMode, setTourSteps, setTourWatchingAllTutorials, setTourWatchingTopic } from "@/@core/utils/store/setStore";

export default {
    data() {
      return {
        tourSteps: [{}]
      };
    },
    mounted() {
      setTimeout(() => {
        const tourSteps = getTourSteps();
        const elemento= tourSteps.length > 0 ? document.querySelector(tourSteps[0].target) : null;

        if (!elemento) {
          setTourMode(false);
          setTourSteps([{}]);
          setTourWatchingAllTutorials(false);
          setTourWatchingTopic(false);
          
          this.$tours.vuexyTour.stop();
        }

        if (this.tourMode) {
          this.startTour();
        }
      }, 500);
    },
    computed: {
      tourMode() {
        return getTourMode();
      }
    },
    watch: {
      tourMode(val) {
        if (val) {
          this.tourSteps = getTourSteps();
          this.$tours.vuexyTour.currentStep = getTourCurrentStep();
          this.$tours.vuexyTour.start();
        }else{
          this.$tours.vuexyTour.stop();
        }
      }
    },
    methods: {
      startTour() {
        this.tourSteps = getTourSteps();

        if (getTourCurrentStep() > 0) {
          this.$tours.vuexyTour.currentStep = getTourCurrentStep();
        }else{
          this.$tours.vuexyTour.start();
        }
      }
    }
  };