import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData){
           this.empresa.nome = 'Empresa Tutorial';
           this.linkItems[1].name = this.empresa.nome
           this.loadingData = false;
           this.withoutColab = false;
           this.table.items = [
            {
              "id_empresa": 1,
              "matricula": null,
              "setor": null,
              "id_categoria": 1,
              "categoria": "Trabalhador",
              "id_pessoa_fisica": 1,
              "cpf": "00000000000",
              "nome": "João da Silva Souza",
              "genero": "Masculino",
            }
           ];
           this.startPagination(
            {
              meta: {
                total: 1,
                from: 1,
                to: 1,
                current_page: 1
              }
            }
           );
        }
    }
  };