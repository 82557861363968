<template>
  <b-form-group
    label="Selecionar Empresa"
    label-for="select-empresa"
    label-class="font_size_label"
  >
    <v-select
      id="select-empresa"
      :options="computedOptions"
      v-model="selectedItems"
      :multiple="multiple"
      :label="empresaFilter"
      @input="handleSelectionChange"
      :clearable="false"
      class="company-filter"
    >
      <span slot="no-options">Nenhuma opção selecionável.</span>
      <template #option="item">
          <strong>{{item.documento_formatado}}</strong><br>
          <span v-if="item.documento">
            <span v-if="empresaFilter != 'doc_filter' && item[empresaFilter]">{{ item[empresaFilter] }}</span>
            <span v-else>{{ item.nome_empresa }}</span>
          </span>
      </template>
      <template #selected-option="item">
        <span>
          {{ empresaFilter != 'doc_filter' ? item[empresaFilter] : item.documento_formatado }}
        </span>
      </template>
    </v-select>
    <b-form-radio-group
      id="radio-group-empresa"
      v-model="empresaFilter"
      name="radio-sub-component"
      class="mt-10px radio-empresa"
      size="sm"
    >
      <span class="mr-1">Filtrar por:</span>
      <b-form-radio value="doc_filter">CNPJ/CPF</b-form-radio>
      <b-form-radio value="razao_social">Razão Social</b-form-radio>
      <b-form-radio value="nome_empresa">Nome Fantasia</b-form-radio>
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import { t } from "@/@core/libs/i18n/utils";
import { BFormGroup, BFormRadio, BFormRadioGroup } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    vSelect,
  },

  name: "MultiSelectCompanies",
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    const defaultItem = { 
      id_empresa: "all", 
      nome_empresa: "Todas as Empresas",
      documento: null,
      documento_formatado: "Todas as Empresas",
      razao_social: "Todas as Empresas",
      doc_filter: null
    };

    return {
      selectedItemsCopy: this.multiple ? [defaultItem] : defaultItem,
      selectedItems: this.multiple ? [defaultItem] : defaultItem,
      empresaFilter: "doc_filter",
      defaultItem: defaultItem
    };
  },
  computed: {
    computedOptions() {
      let allEmpresas = [this.defaultItem, ...this.options];

      return allEmpresas.map((empresa) => {
          return {
            id_empresa: empresa.id_empresa,
            documento: empresa.documento,
            documento_formatado: empresa.documento_formatado,
            nome_empresa: empresa.nome_empresa,
            razao_social: empresa.razao_social,
            doc_filter: empresa.documento_formatado + ' ' + empresa.documento
          };
        });
    },
  },
  methods: {
    handleSelectionChange(newValue) {
      if (this.multiple) {
        const addedItem = this.getAddedItem(newValue);

        if(!this.selectedItems.length){
          this.selectDefaultItem();
        }else{
          if(addedItem && addedItem.id_empresa == 'all'){
            this.selectDefaultItem();
          }else{
            this.selectedItems = newValue.filter(option => option.id_empresa !== "all");          
          }
        }

      } else {
        this.selectedItems = newValue.id_empresa === "all" ? this.defaultItem : newValue;
      }

      this.$emit("input", this.selectedItems);
      this.selectedItemsCopy = this.selectedItems;
    },
    selectDefaultItem() {
      this.selectedItems = [this.defaultItem];
    },
    getAddedItem(newSelectedItems) {
      let addItem = newSelectedItems.filter(item => 
        !this.selectedItemsCopy.some(selectedItem => selectedItem.id_empresa === item.id_empresa)
      );
      return addItem[0];
    }
  },
};
</script>

<style>
  .radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
      left: 0;
  }

  .company-filter .vs__dropdown-toggle{
    font-size: 15px;
    height: 44.36px;
    border-radius: 8px;
    margin: 0px;
  }
</style>
