<template>
  <overlay
    :show="loading"
  >
    <page-header
      screen-name="Editar Usuário"
      :link-items="linkItems"
    />

    <Form
      v-if="!loading"
      mode="update"
    />
  </overlay>
</template>

<script>

import moment from 'moment'
import { getArrayAttr } from '@core/utils/utils'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { warningMessage } from '@/libs/sweetalerts'
import Form from '@/views/custom-pages/gerenciarUsuarios/usuarioAdministrativo/Form.vue'
import { getAdminSesiId } from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import chavePerfil from '@/enum/chavePerfil'

export default {
  components: {
    Overlay,
    PageHeader,
    Form,
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          active: true,
          routeName: 'usuario-list',
        },
        {
          name: '...',
          active: true,
        },
      ],

      moment,

      loading: true,
    }
  },

  computed: {
    getItemInStore() {
      return this.$store.getters['adminUserState/getAdminUserUpdate']
    },
  },

  created() {
    if (!this.getItemInStore) {
      this.redirectToMainPage()

      return false
    }

    this.$store.commit('adminUserState/clearFormData')

    return this.handleGetAdminUserId()
  },

  methods: {
    async handleGetAdminUserId() {
      this.loading = true

      await getAdminSesiId(this.getItemInStore.id_usuario)
        .then(response => {
          const {
            id_usuario,
            nome,
            email,
            cpf,
            data_nascimento,
            sexo,
            id_departamento,
            departamento_nome,
            perfil,
            regioes,
            unidade,
            tipo_atuacao,
            id_nacionalidade,
            nome_mae,
            documento,
            documento_uf,
            id_tipo_documento,
            documento_sigla,
          } = response.data

          const unitProfile = perfil[0].chave === chavePerfil.ADMIN_UNIDADE

          const regionsProfile = [
            chavePerfil.SAUDE_REGIAO,
            chavePerfil.VACINADOR_PADRAO,
            chavePerfil.VACINADOR_ADMIN,
            chavePerfil.SAUDE_UO,
          ].includes(perfil[0].chave)

          let unidadesAux = []

          if (regioes.length > 0) {
            if (unitProfile) {
              unidadesAux = unidade[0]
            }

            if (regionsProfile) {
              unidadesAux = getArrayAttr(unidade, 'id_unidade')
            }
          }

          this.$store.commit('adminUserState/setFormData', {
            id: id_usuario,
            nome,
            email,
            cpf,
            dataNascimento: moment(data_nascimento).format('DD/MM/YYYY'),
            departamento: {
              id_departamento,
              descricao: departamento_nome,
            },
            regioes,
            sexo: { name: sexo },
            perfil: perfil.length > 0 ?  perfil[0] : null,
            unidades: unidadesAux,
            empresas: [],
            tipoAtuacao: tipo_atuacao.length > 0 ? tipo_atuacao[0] : null,
            vacinadorOption: !!id_tipo_documento,
            tipoDocumento: (id_tipo_documento == null ? null : {
              id_tipo_documento: id_tipo_documento,
              sigla: documento_sigla,
            }),
            numeroDocumento: documento,
            estadoDocumento: (documento_uf == null ? null : {
              uf: documento_uf,
            }),
            nomeMae: nome_mae,
          })

          this.linkItems[1].name = nome
        })
        .catch(error => {
          warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
        })

      this.loading = false
    },

    redirectToMainPage() {
      this.$store.commit('adminUserState/setAdminUserUpdate', null)
      this.$router.replace({ name: 'usuario-list' })

      return false
    },
  },
}
</script>
