import $store from '@/store'
import $http from '@/libs/api';
import $router from '@/router'
import $api from '@/router/rotasAPI'

export async function updateStatusTutorial(status) {
    const userData = $store.state.sessions.userData

    if(
        (['Concluiu', 'Pulou'].includes(userData.situacao_tutorial) && !(status == 'Concluiu' && userData.situacao_tutorial == 'Pulou'))
        || userData.situacao_tutorial == status
    ) {
        return;
    }

    const params = {
        situacao: status
    }
    await $http.patch($api.usuarioSituacaoTutorial(), params)
        .then(() => {
            $store.commit(
                "sessions/SET_USER_TOUR_STATUS",
                status
            );
        })
        .catch((err) => { 
            console.log(err)
        });
}