<template>
  <b-form-group
    label="Nome da Empresa"
    label-for="company_name"
    label-class="font_size_label"
  >
    <validation-provider
        #default="{errors}"
        name="Nome da Empresa"
    >
      <b-form-input
          id="company_name"
          v-model="form.company_name"
          :placeholder="placeholder"
          maxLength="255"
      >
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-input>
      <b-form-radio-group
        id="radio-group-empresa"
        v-model="form.company_name_filter"
        name="radio-sub-component"
        class="mt-10px radio-empresa"
        size="sm"
      >
        <span class="mr-1">Filtrar por:</span>
        <b-form-radio value="razao_social">Razão Social</b-form-radio>
        <b-form-radio value="nome_fantasia">Nome Fantasia</b-form-radio>
      </b-form-radio-group>
    </validation-provider>
  </b-form-group>
</template>
  
<script>
  import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio
} from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate'

  export default {
    components: {
        BFormGroup,
        BFormInput,
        BFormRadioGroup,
        BFormRadio,
        ValidationProvider
    },

    props: {
      form: {
        type: Object,
        required: true
      },
      placeholder: {
        type: String,
        required: false,
        default: 'Nome da Empresa'
      }
    }
  }
</script>
<style>
  .radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
      left: 0;
  }
</style>
