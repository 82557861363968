<template>
    <b-modal id="confirm-delete" no-close-on-backdrop no-close-on-esc hide-header hide-footer centered
        v-model="modalShow" size="md">

        <div class="d-flex flex-column align-items-center justify-center-center my-2">
            <feather-icon icon="AlertCircleIcon" class="text-danger mb-2" size="80" />
            <h3 class="text-center">Tem certeza que deseja excluir o lote selecionado?</h3>
            <span class="text-center">
                Esta ação é permanente e não pode ser desfeita.
            </span>
            <div class="d-flex mt-2">
                <b-button :disabled="loading" variant="primary-button" @click="cancelAction">
                    Cancelar
                </b-button>
                <b-button :disabled="loading" variant="outline-danger" class="ml-2" @click="confirmAction">
                    <output v-if="loading" class="spinner-border spinner-border-sm mr-1" aria-live="assertive"
                        aria-atomic="true">
                    </output>
                    Confirmar
                </b-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol } from 'bootstrap-vue';

export default {
    components: {
        BModal, BButton, BRow, BCol
    },

    props: {
        modalShow: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            loading: false
        }
    },

    methods: {
        confirmAction() {
            this.loading = true
            this.$emit('confirmDelete')
        },

        cancelAction() {
            this.$emit('cancelDelete')
        }
    },
    watch: {
        modalShow() {
            this.loading = false
        }
    }
}

</script>