import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: true
      };
    },
    mounted() {
        this.loadData = !getTourMode();
        if(!this.loadData){
            this.form.dadosPrincipais.documento = '00.000.000/0000-00';
            this.form.dadosPrincipais.nome_fantasia = 'Empresa Tutorial';
            this.form.dadosPrincipais.razao_social = 'Razão Empresa Tutorial';
            this.loadingShow = false
            this.linkItems[1].name = 'Editar empresa'
        }
    }
  };