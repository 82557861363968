import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: !getTourMode()
      };
    },
    mounted() {
        if(!this.loadData){
			this.posted = true;
            this.loadingTable = false;
            this.tabela.items = [
                {
                    'numero_pedido': '1',
                    'nome_campanha': 'Campanha Regional da Gripe 2025',
                    'realizado_por': 'SESI - Empresa Tutorial',
                    'data_processamento': '2025-01-01 00:00:00'
                }
            ];
		    this.handlePagination({
                from: 1,
                to: 1,
                total: 1,
                current_page: 1
            });
            this.tabela.semDados = false;
            this.showTable = true;
            this.tabela.tabelaOcupada = false;
        }
    }
  };