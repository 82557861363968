import { getTourMode } from "@/@core/utils/store/getStore";

export default {
    data() {
      return {
        loadData: true
      };
    },
    mounted() {
        this.loadData = !getTourMode();
        if(!this.loadData){
            this.empresa.nome = 'Empresa Tutorial';
            this.linkItems[1].name = this.empresa.nome
            this.loadingShow = false;
            this.loadingData = false;
        }
    }
  };